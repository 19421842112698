import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialProvider } from '@material-ui/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as Sentry from '@sentry/browser'

import defaultTheme, { materialTheme } from 'ui/themes/default'
import { store, persistor } from 'redux/store'
import './index.css'
import Router from './router'

if (process.env.NODE_ENV === 'production') {
  Sentry.onLoad(() => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
    })
  })
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={defaultTheme}>
        <MaterialProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ToastContainer />
            <Router />
          </MuiPickersUtilsProvider>
        </MaterialProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
