import React, { useState } from 'react'
import 'date-fns'
import { useSelector } from 'react-redux'
import reportError from 'utils/errorReporter'
import { toast } from 'react-toastify'
import { postCreateMonitoring } from 'services/monitoringApi'
import PeopleMonitoringForm from 'ui/components/PeopleMonitoringForm'
import { useHistory } from 'react-router'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import { peopleMonitoringFormTypes } from 'utils/constants/peopleMonitoring'

const AddMonitoringDialog = ({ handleCloseDialog }) => {
  const history = useHistory()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const [isApiLoading, setIsApiLoading] = useState()


  const redirectToMonitoringsPage = () => {
    const currentPath = history.location.pathname
    const newPath = `${ROUTE_CONSTANTS.PEOPLE_MONITORING}`

    if (newPath.startsWith(currentPath)) {
      const url = new URL(window.location.href)
      url.searchParams.set('consists', 'all')
      window.location.href = url.href
    }
    else {
      history.push(newPath)
    }

  }
  const callCreateMonitoringApi = async (options = {}) => {
    try {
      setIsApiLoading(true)
      await postCreateMonitoring(apiKey, userId,
        {
          description: options.description,
          searchInSocialMedia: options.searchInSocialMedia,
          inputs: options.inputs,
        })
    } catch (err) {
      reportError(err, 'Erro ao criar consulta')
      return
    } finally {
      setIsApiLoading(false)
    }

    toast.success(`Solicitação criada com sucesso!`)

    if (handleCloseDialog) handleCloseDialog()

    redirectToMonitoringsPage()
  }

  return (
    <PeopleMonitoringForm
      formType={peopleMonitoringFormTypes.create}
      onConfirmRequest={callCreateMonitoringApi}
      isApiLoading={isApiLoading}
      handleCloseDialog={handleCloseDialog}
    />
  )
}

export default AddMonitoringDialog
