import {
  formatToCPF as formatCPF,
  formatToCNPJ as formatCNPJ,
} from 'brazilian-values'
import { cnpjValid as isCnpjValid, cpfValid as isCpfValid, nameValid } from './validators'
import { extractOnlyNumbers } from './functions'

export const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const phoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const cepMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
]

export const dateMask = [
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const nameMask = Array.from({ length: 50 }, () => /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/)


export const formatToCPF = (cpf) => {
  return cpf ? formatCPF(cpf) : null
}

export const formatToCNPJ = (cnpj) => {
  return cnpj ? formatCNPJ(cnpj) : null
}

export const identifyAndFormatInput = (input) => {
  if (nameValid(input)) {
    return { type: 'nome',  value: input }
  }
  const numbers = extractOnlyNumbers(input)
  if (isCpfValid(numbers)) {
    return { type: 'cpf',  value: numbers }
  }

  if (isCnpjValid(numbers)) {
    return { type: 'cnpj',  value: numbers }
  }


  return null
}

export const formatDocument = (docNumber, type = null) => {
  switch (type) {
    case 'cpf': return formatToCPF(docNumber)
    case 'cnpj': return formatToCNPJ(docNumber)
    default: break
  }

  if (isCpfValid(docNumber)) {
    return formatToCPF(docNumber)
  }

  if (isCnpjValid(docNumber)) {
    return formatCPF(docNumber)
  }

  return null
}
