import * as React from 'react'
import { Button, Divider } from '@material-ui/core'
import { formatDocument } from 'utils/masks'
import { useSelector } from 'react-redux'
import { getNotices, getSocialMediaPosts, patchUpdatePerson } from 'services/consultApi'

import { useState, useEffect } from 'react'

import _ from 'lodash'
import { toast } from 'react-toastify'
import reportError from 'utils/errorReporter'
import PersonAvatar from 'ui/components/PersonAvatar'
import NoticesAndPostsPanel from 'ui/components/NoticesAndPostsPanel'
import PersonOtherNames from './PersonOtherNames'
import PersonSocialMedia from './PersonSocialMedia'
import styles from './styles'
import MUIIcon from '../../Icon/MUIIcon'

const PersonProfile = ({ person, updatePerson }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [activeNoticesData, setActiveNoticesData] = useState({
    totalNotices: 0,
    totalPages: 0,
    currentPage: 1,
    status: null,
    consists: null,
    items: [],
  })
  const [activeSocialMediaPostsData, setActiveSocialMediaPostsData] = useState({
    totalPosts: 0,
    totalPages: 0,
    currentPage: 1,
    status: null,
    consists: null,
    items: [],
  })

  const [noticesLoading, setNoticesLoading] = useState(false)
  const [socialMediaPostsLoading, setSocialMediaPostsLoading] = useState(false)

  const initialSocialMediaValues = {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    website: '',
  }

  const [alternateNames, setAlternateNames] = useState([])
  const [socialMediaFields, setSocialMediaFields] = useState(initialSocialMediaValues)
  const [currentSocialMedia, setCurrentSocialMedia] = useState(initialSocialMediaValues)
  const [hasChanges, setHasChanges] = useState(false)
  const [allowSubmit, setAllowSubmit] = useState(true)

  const personTypes = {
    cpf: {
      label: 'Pessoa Física',
      alternateNamesKey: 'nicknames',
      icon: <MUIIcon type="cpf" style={{ fontSize: '1.5rem' }} />,
    },
    cnpj: {
      label: 'Pessoa Jurídica',
      alternateNamesKey: 'fantasy_names',
      icon: <MUIIcon type="cnpj" style={{ fontSize: '1.5rem' }} />,
    },
    unknow: { label: 'Desconhecido', icon: <MUIIcon type="unknowPerson" style={{ fontSize: '1.5rem' }} /> },
  }

  const onUpdateNoticesData = (newNoticesData) => {
    setActiveNoticesData(newNoticesData)
  }

  const onUpdatePostsData = (newPostsData) => {
    setActiveSocialMediaPostsData(newPostsData)
  }

  const requestNoticesApi = async (options = {}) => {
    if (!person) {
      return null
    }
    setNoticesLoading(true)

    let res = null
    try {
      res = await getNotices(apiKey, userId, {
        personId: person.id,
        olderFirst: _.has(options, 'olderFirst') ? options.olderFirst : false,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      setNoticesLoading(false)
      return null
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setActiveNoticesData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalNotices: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      items: res.data.notices,
    })

    setNoticesLoading(false)
    return null
  }

  const requestSocialMediaPostsApi = async (options = {}) => {
    if (!person) {
      return null
    }

    setSocialMediaPostsLoading(true)

    let res = null

    try {
      res = await getSocialMediaPosts(apiKey, userId, {
        personId: person.id,
        olderFirst: _.has(options, 'olderFirst') ? options.olderFirst : false,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar postagens de mídias sociais')
      setSocialMediaPostsLoading(false)
      return null
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setActiveSocialMediaPostsData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalPosts: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      items: res.data.posts,
    })

    setSocialMediaPostsLoading(false)
    return null
  }

  useEffect(() => {
    requestNoticesApi()
    requestSocialMediaPostsApi()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person])

  const onNoticesFilterChange = (params) => {
    requestNoticesApi(params)
    requestSocialMediaPostsApi(params)
  }

  const changeAllowSubmit = (newValue) => {
    setAllowSubmit(newValue)
  }

  const callUpdatePerson = async () => {
    const socialMediaData = () => {
      const data = []
      _.each(socialMediaFields, (_v, k) => {
        const oldField = currentSocialMedia[k]
        const savedMedia = _.find(person.social_medias, (s) => s.type === k && s.link === oldField && s.active)

        // The typed link is the same of an active saved link?
        if (_v === savedMedia?.link) {
          return
        }
        // Disabling old link, if it exists
        if (!_.isEmpty(savedMedia)) {
          data.push({ ...savedMedia, active: false })
        }

        if (!_.isEmpty(socialMediaFields[k])) {
          data.push({ link: _v, type: k, active: true })
        }
      })
      return { socialNetworks: data }
    }

    const alternateNamesData = () => {
      let names = {}

      if (person.person_type !== 'unknow') {
        names = { [personTypes[person.person_type].alternateNamesKey]: alternateNames }
      }
      return names
    }

    let res = null
    try {
      res = await patchUpdatePerson(apiKey, userId, person.id, { ...socialMediaData(), ...alternateNamesData() })
      toast.success('Perfil atualizado com sucesso')
      updatePerson(res.data)
      setHasChanges(false)
    } catch (err) {
      reportError(err)
    }
  }

  const updateAlternateNames = (names, markHasChanged = true) => {
    setAlternateNames(names)
    setHasChanges(markHasChanged)
  }

  const personName = person?.name
  const classes = styles()
  const isKnownPersonType = person.person_type !== 'unknow'

  const updateSocialMediaFields = (text, field) => {
    setSocialMediaFields({ ...socialMediaFields, [field]: text })
    setHasChanges(true)
  }

  useEffect(() => {
    const mergeTypedSocialMediaWithCurrent = () => {
      const activeSocialMedia = {}
      let personSocialMedia = person.social_medias || []

      personSocialMedia = personSocialMedia.filter((sm) => sm.active)
      personSocialMedia.forEach((sm) => {
        activeSocialMedia[sm.type] = sm.link
      })
      return { ...currentSocialMedia, ...activeSocialMedia }
    }

    const activeSocialMedia = mergeTypedSocialMediaWithCurrent()
    setCurrentSocialMedia(activeSocialMedia)
    setSocialMediaFields(activeSocialMedia)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveButtonEnabled = hasChanges && allowSubmit

  const renderNoticesAndPostsPanel = () => {
    return (
      <div style={{ minWidth: '30vw', maxWidth: '50vw', overflow: 'hidden', marginLeft: 10 }}>
        <NoticesAndPostsPanel
          postsData={activeSocialMediaPostsData}
          noticesData={activeNoticesData}
          socialMediaPostsData={activeSocialMediaPostsData}
          isNoticesLoading={noticesLoading}
          isSocialMediaPostsLoading={socialMediaPostsLoading}
          onNoticesFilterChange={onNoticesFilterChange}
          onUpdateNoticesData={onUpdateNoticesData}
          onUpdatePostsData={onUpdatePostsData}
        />
      </div>
    )
  }

  const renderProfile = () => {
    return (
      <React.Fragment>
        <div style={{ minWidth: '30vw', maxWidth: '40vw', padding: '30px 10px' }}>
          <div style={{ display: 'flex', gap: 10 }}>
            <PersonAvatar name={personName} />

            <div className={classes.personInfoDiv}>
              <p>{person.name || '---'}</p>
              <p>{formatDocument(person.document, person.person_type) || '---'}</p>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <span>Tipo:</span>
                {personTypes[person.person_type].icon}
                <span>{personTypes[person.person_type].label}</span>
              </div>
            </div>
          </div>
          <br />
          <Divider />
          <br />
          <div className={classes.personInfoDiv}>
            {isKnownPersonType && (
              <PersonOtherNames
                person={person}
                alternateNames={alternateNames}
                updateAlternateNames={updateAlternateNames}
              />
            )}
            <PersonSocialMedia
              person={person}
              socialMediaFields={socialMediaFields}
              updateSocialMediaFields={updateSocialMediaFields}
              changeAllowSubmit={changeAllowSubmit}
            />
          </div>

          <Button
            disabled={!saveButtonEnabled}
            style={{ marginTop: 15, float: 'right' }}
            color="primary"
            onClick={callUpdatePerson}
          >
            Salvar Alterações
          </Button>
        </div>
      </React.Fragment>
    )
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {renderProfile()}
        {renderNoticesAndPostsPanel()}
      </div>
    </div>
  )
}
export default PersonProfile
