/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Chip } from "@material-ui/core"
import React from "react"
import { Autocomplete } from "@material-ui/lab"

export default function ChipInput({ label, placeholder, disabled = false, fullWidth, value = [], onChange, style = {} }) {
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      freeSolo
      value={value}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(e, values) => onChange(e, values)}
      renderTags={(
        values,
        getTagProps,
      ) =>
        values.map((option, index) => {
          return (
            <Chip
              variant="outlined"
              {...getTagProps({ index })}
              label={option}
            />
          )
        })}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="medium"
            disabled={disabled}
            style={style}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
          />
)
      }}
    />
  )
}
