import { Tooltip } from '@material-ui/core'
import * as React from 'react'
import MUIIcon from '../Icon/MUIIcon'

const PeopleMonitoringTooltip = () => (
  <Tooltip
    arrow
    title={(
      <h3>
        Buscar postagens desabonadoras nas mídias sociais das pessoas que estão cadastradas no Banco de Pessoas.
        <br />
        <br />
        Cada pessoa terá um perfil no Banco de Pessoas e lá você poderá informar os links das mídias sociais a serem buscadas.
      </h3>
  )}
  >
    <span>
      <MUIIcon type="help" />
    </span>
  </Tooltip>
)

export default PeopleMonitoringTooltip