import _ from 'lodash'
import { isCPF, isCNPJ } from 'brazilian-values'
import { DOCUMENT_TYPE } from './constants/consultConstants'

export const nameValid = (string) => {
  const reg = /^[a-záàâãéèêíïóôõöúçñ ]+$/i
  return _.isString(string) && string.trim().length > 0 && string.length >= 3 && reg.test(string)
}

export const cpfValid = (string) => {
  return _.isString(string) && isCPF(string)
}

export const cnpjValid = (string) => {
  return _.isString(string) && isCNPJ(string)
}

export const isDocNumberValid = (docNumber, type) => {
  switch (type?.toLowerCase()) {
    case DOCUMENT_TYPE.CPF:
      return cpfValid(docNumber)
    case DOCUMENT_TYPE.CNPJ:
      return cnpjValid(docNumber)
    default:
      return cpfValid(docNumber) || cnpjValid(docNumber)
  }
}

export const isUrlValid = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\/[-a-z\\d%_.~+]*)*' + // path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  )
  return pattern.test(url)
}
