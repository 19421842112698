import { Chip, Tooltip } from '@material-ui/core'
import * as React from 'react'
import { BR_DATE_TIME_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import MUIIcon from '../Icon/MUIIcon'
import { groupModes } from '../NoticesAndPostsPanel/itemsGrouper'

const OccurrenceTimestampChip = ({ occurenceType, occurrenceDate, postType }) => {
  const iconStyle =  {  fontSize:'1.6rem', marginLeft: '5px', color: 'black', backgroundColor: '#fff' }
  const occurenceTypes = {
    createdAt: {
      toottipTitle: `Momento em que a ${postType} foi identificada`,
      chipLabel: `Identificado em ${dateToStr(occurrenceDate, BR_DATE_TIME_FORMAT)}`,
      avatar: <MUIIcon type={groupModes.createdAt} style={iconStyle} />,
    },
    publishedDate: {
      toottipTitle: `Data de publicação`,
      chipLabel: `Publicado em ${occurrenceDate}`,
      avatar: <MUIIcon type={groupModes.publishedDate} style={iconStyle} />,
    },
  }
  const occurence = occurenceTypes[occurenceType]

  if (!occurrenceDate || !occurence) {
    return null
  }

  return (
    <Tooltip title={<h3>{occurence.toottipTitle}</h3>}>
      <span>
        <Chip
          variant='outlined'
          color="primary"
          style={{ margin: '0 5px' }}
          size="small"
          avatar={occurence.avatar}
          label={occurence.chipLabel}
        />
      </span>
    </Tooltip>
  )
}

export default OccurrenceTimestampChip
