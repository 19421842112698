import * as React from 'react'
import { InputAdornment, TextField, Typography } from '@material-ui/core'
import _ from 'lodash'
import { Facebook, Instagram, LinkedIn, Public, Twitter } from '@material-ui/icons'
import { useState, useEffect } from 'react'

import { isUrlValid } from 'utils/validators'

const PersonSocialMedia = ({ socialMediaFields, updateSocialMediaFields, changeAllowSubmit }) => {
  const inputsData = [
    { label: 'Facebook', placeholder: 'facebook.com/exemplo', fieldName: 'facebook', icon: <Facebook /> },
    { label: 'Linkedin', placeholder: 'linkedin.com/exemplo', fieldName: 'linkedin', icon: <LinkedIn /> },
    { label: 'Twitter (X)', placeholder: 'twitter.com/exemplo', fieldName: 'twitter', icon: <Twitter /> },
    { label: 'Instagram', placeholder: 'instagram.com/exemplo', fieldName: 'instagram', icon: <Instagram /> },
    { label: 'Website', placeholder: 'exemplo.com', fieldName: 'website', icon: <Public /> },
  ]

  const validDomains = {
    facebook: ['facebook.com/'],
    linkedin: ['linkedin.com/'],
    twitter: ['x.com/', 'twitter.com/'],
    instagram: ['instagram.com/'],
  }

  const [socialMediaErrors, setSocialMediaErrors] = useState({
    facebook: false,
    instagram: false,
    linkedin: false,
    twitter: false,
    website: false,
  })

  useEffect(() => {
    const errorValues = _.values(socialMediaErrors)
    changeAllowSubmit(!_.some(errorValues))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaErrors])

  const validateSpecificSocialMedia = (text, type) => {
    if (_.isEmpty(text) || type === 'website') {
      return true
    }
    return _.some(validDomains[type], (domain) => {
      return (
        text.startsWith(domain) ||
        text.startsWith(`www.${domain}`) ||
        text.startsWith(`https://${domain}`) ||
        text.startsWith(`https://www.${domain}`)
      )
    })
  }

  const handleChangeTextField = (text, type) => {
    updateSocialMediaFields(text, type)
    let hasError = false

    if (!_.isEmpty(text) && (!isUrlValid(text) || !validateSpecificSocialMedia(text, type))) {
      hasError = true
    }
    setSocialMediaErrors({ ...socialMediaErrors, [type]: hasError })
  }

  return (
    <div style={{ marginTop: 30 }}>
      <Typography variant="subtitle1">Mídias Sociais</Typography>
      {inputsData.map((input) => (
        <TextField
          key={input.fieldName}
          label={input.label}
          error={socialMediaErrors[input.fieldName]}
          helperText={socialMediaErrors[input.fieldName] && 'Link inválido.'}
          style={{ marginTop: 5 }}
          placeholder={input.placeholder}
          onChange={(event) => handleChangeTextField(event.target.value, input.fieldName)}
          value={socialMediaFields[input.fieldName] || ''}
          fullWidth={true}
          InputProps={{
            startAdornment: <InputAdornment position="start">{input.icon}</InputAdornment>,
          }}
        />
      ))}
    </div>
  )
}

export default PersonSocialMedia
