import * as React from 'react'
import { dateToStr } from 'utils/constants/dateConstants'

const { TextField } = require("@material-ui/core")

const TextFieldDatePicker = ({ value, label, maxDate, minDate, onChange, style, disabled, className, InputProps }) => (
  <TextField
    type='date'
    className={className}
    value={value}
    label={label}
    disabled={disabled}
    style={style}
    inputProps={{ max: maxDate && dateToStr(maxDate), min: minDate && dateToStr(minDate) }}
    InputLabelProps={{ shrink: true }}
    onChange={onChange}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={InputProps}
  />
)

export default TextFieldDatePicker
