import * as React from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'
import _ from 'lodash'
import { postReviewNotice } from 'services/consultApi'
import reportError from 'utils/errorReporter'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { personAvatarForChip } from 'ui/components/PersonAvatar'
import OccurrenceTimestampChip from 'ui/components/OccurrenceTimestampChip'
import styles from './styles'
import { groupModes } from '../itemsGrouper'

const NoticesReviewButton = ({ notice, reviewButtonsVisibility, onUpdateNotice }) => {
  const [comment, setComment] = useState(notice.snippet.review?.comment)
  const [analysisDialogOpen, setAnalysisDialogOpen] = useState(false)
  const [editMode, setEditMode] = useState(_.isEmpty(notice.snippet.review))
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const classes = styles()

  const decisions = {
    approved: {
      label: 'Aprovado',
      color: 'green',
      icon: <ThumbUpIcon style={{ color: 'green' }} className={classes.doneButtonIconMarked} />,
    },
    reproved: {
      label: 'Reprovado',
      color: 'red',
      icon: <ThumbDownIcon style={{ color: 'red' }} className={classes.doneButtonIconMarked} />,
    },
  }

  const performReview = async (decision) => {
    const { snippet } = notice
    let res = null
    try {
      res = await postReviewNotice(apiKey, userId, notice.request_id, snippet.request_id, decision, comment)
    } catch (err) {
      reportError(err, 'Erro ao revisar notícia')
      return null
    }
    toast.success(`Análise ${snippet.review ? 'atualizada' : 'enviada'} com sucesso!`)

    setAnalysisDialogOpen(false)
    setEditMode(false)

    onUpdateNotice(notice, res.data)

    return null
  }

  const handleChangeDescription = (event) => {
    const text = event.target.value
    setComment(text)
  }

  const showReviewField = () => {
    const buttonsDisabled = _.isEmpty(comment)

    return (
      <React.Fragment>
        <div>
          <TextField
            fullWidth
            multiline
            rows={7}
            value={comment}
            onChange={handleChangeDescription}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Motivo da decisão"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.buttonsDiv}>
            <Button
              disabled={buttonsDisabled}
              variant="contained"
              onClick={() => performReview('approved')}
              startIcon={<ThumbUpIcon />}
            >
              Aprovar
            </Button>

            <Button
              disabled={buttonsDisabled}
              variant="contained"
              onClick={() => performReview('reproved')}
              startIcon={<ThumbDownIcon />}
            >
              Reprovar
            </Button>
          </div>
          {notice.snippet?.review && (
            <div className={classes.buttonsDiv}>
              <Button disabled={buttonsDisabled} variant="contained" onClick={() => setEditMode(false)}>
                Cancelar
              </Button>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  const showReviewData = (reviewData) => {
    const decision = decisions[reviewData?.decision]

    if (!decision) {
      return null
    }

    return (
      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <div>
          <strong>Situação:</strong>
          {' '}
          <strong style={{ color: decision.color }}>{decision.label}</strong>
        </div>

        <div>
          <strong>Analisado por:</strong>
          {' '}
          <span>{reviewData.reviewer_name}</span>
        </div>

        <div>
          <strong>Analisado em:</strong>
          {' '}
          <span>{reviewData.created_at}</span>
        </div>

        {reviewData.created_at !== reviewData.updated_at && (
        <div>
          <strong>Última alteração:</strong>
          {' '}
          <span>{reviewData.updated_at}</span>
        </div>
)}

        <br />

        <div>
          <strong>Motivo da decisão:</strong>
          <p>{reviewData.comment}</p>
        </div>

        <div className={classes.buttonsDiv}>
          <Button variant="contained" startIcon={<EditIcon />} onClick={() => setEditMode(true)}>
            Editar
          </Button>
        </div>
      </div>
    )
  }

  const showButtonIcon = (review, hasReview) => {
    if (!hasReview) {
      return <ThumbsUpDownIcon className={hasReview ? classes.doneButtonIconMarked : classes.doneButtonIconUnMarked} />
    }

    return decisions[review.decision]?.icon
  }


  const extractOtherPeopleInvolved = () =>
  notice.persons.filter((p) => p.name != null && notice.snippet.person_name !== p.name)
  const personName = notice.snippet.person_name
  const involvedPeople = extractOtherPeopleInvolved()
  const { review } = notice.snippet
  const hasReview = !_.isEmpty(review)
  const createdAt = notice.snippet.created_at
  const noticeDate = notice.notice_date
  const postType = 'notícia'

  return (
    <React.Fragment>
      <Tooltip
        arrow
        placement="right"
        title={notice.snippet && <h3>{hasReview ? 'Exibir análise' : 'Analisar notícia'}</h3>}
      >
        <span>
          <IconButton
            style={{
              display: reviewButtonsVisibility[notice.link] || hasReview || 'none',
            }}
            size="small"
            onClick={() => setAnalysisDialogOpen(true)}
          >
            {showButtonIcon(review, hasReview)}
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={analysisDialogOpen} onClose={() => setAnalysisDialogOpen(false)}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>Analisar envolvimento na notícia</div>
            <IconButton
              aria-label="close"
              onClick={() => setAnalysisDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.textField}>
            <div>
              {personName && (
                <div>
                  <h4>Envolvido(a):</h4>
                  <Chip
                    style={{ marginTop: 5 }}
                    avatar={personAvatarForChip(personName)}
                    label={personName}
                  />
                </div>
              )}

              <br />
              <div>
                <h4>Título:</h4>
                <p>{notice.title}</p>
              </div>

              <br />

              <div>
                <h4>Trecho:</h4>
                <p>{notice.snippet.description}</p>
              </div>

              <br />

              <div>
                <h4>Fonte:</h4>
                <a href={notice.link}>{notice.link}</a>
              </div>

              <br />

              <OccurrenceTimestampChip occurenceType={groupModes.createdAt} occurrenceDate={createdAt} postType={postType} />
              <OccurrenceTimestampChip occurenceType={groupModes.publishedDate} occurrenceDate={noticeDate} postType={postType} />


              <br />
              {!_.isEmpty(involvedPeople) &&
                involvedPeople.map((p) => (
                  <div key={p}>
                    <h4>Outros envolvidos já detectados:</h4>
                    <div style={{ marginTop: 5 }}>
                      <Chip
                        avatar={personAvatarForChip(p.name)}
                        label={p.name}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <br />
            <hr />
            <br />

            <h3>Revisão</h3>
            {editMode ? showReviewField() : showReviewData(notice.snippet.review)}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default NoticesReviewButton
