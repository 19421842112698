import { Button, CircularProgress, DialogActions, DialogContent, FormControl, FormHelperText, MenuItem, Select, TextField, Tooltip } from "@material-ui/core"
import React, { useState } from 'react'
import 'date-fns'
import _ from 'lodash'
import { Table, Button as TextButton } from 'ui/components'
import MUIIcon from 'ui/components/Icon/MUIIcon'
import { Pagination } from '@material-ui/lab'
import { documentFilterLabel, documentFilterType, peopleMonitoringFormTypes, validFilterLabel, validFilterType } from 'utils/constants/peopleMonitoring'
import { makeStyles } from "@material-ui/styles"

const DocumentMonitoringConfirmation = ({
  inputs,
  formType,
  validInputsCount,
  toggleForm,
  renderDocumentCount,
  updateDocumentsText,
  loadingRequest,
  onConfirmRequest }) => {

  const CONFIRMATION_TABLE_PAGE_SIZE = 10

  const [currentPage, setCurrentPage] = useState(1)
  const [termFilter, setTermFilter] = useState('')
  const [validFilter, setValidFilter] = useState(validFilterType.all)
  const [inputTypeFilter, setInputTypeFilter] = useState(documentFilterType.all)

  const useStyles = makeStyles(() => ({
    textField: {
      fontSize: '1.3rem',
    },
  }))

  const classes = useStyles()


  const handleChangePage = (_event, pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const renderConfirmationTableControls = () => {

    const showValidFilterMenuItem = ({ filter, iconName }) => (
      <MenuItem value={filter}>
        {iconName && (
          <span style={{ paddingTop: 5, paddingRight: 2 }}>
            <MUIIcon type={iconName} />
          </span>
        )}
        {validFilterLabel[filter]}
      </MenuItem>
    )

    const showInputTypeFilterMenuItem = ({ filter }) => (
      <MenuItem value={filter}>
        {documentFilterLabel[filter]}
      </MenuItem>
    )

    const showTermSearchControl = () => (
      <FormControl>
        <FormHelperText>Filtrar registros:</FormHelperText>
        <TextField
          style={{ width: '7vw' }}
          value={termFilter}
          onChange={(e) => setTermFilter(e.target.value)}
          placeholder='Documento'
          InputProps={{
            className: classes.textField,
          }}
        />
      </FormControl>
    )

    const showValidFilteringControl = () => (
      <FormControl>
        <FormHelperText>Validade:</FormHelperText>
        <Select
          id="demo-simple-select-filled"
          value={validFilter}
          onChange={(e) => setValidFilter(e.target.value)}
          renderValue={(value) => validFilterLabel[value]}
          className={classes.textField}
        >
          {showValidFilterMenuItem({ filter: validFilterType.all })}
          {showValidFilterMenuItem({ filter: validFilterType.valid, iconName: 'valid' })}
          {showValidFilterMenuItem({ filter: validFilterType.invalid, iconName: 'invalid' })}
        </Select>
      </FormControl>
    )

    const showInputTypeFilteringControl = () => (
      <FormControl>
        <FormHelperText>Tipo:</FormHelperText>
        <Select
          value={inputTypeFilter}
          onChange={(e) => setInputTypeFilter(e.target.value)}
          renderValue={(value) => documentFilterLabel[value]}
          className={classes.textField}
        >
          {showInputTypeFilterMenuItem({ filter: documentFilterType.all })}
          {showInputTypeFilterMenuItem({ filter: documentFilterType.cpf })}
          {showInputTypeFilterMenuItem({ filter: documentFilterType.cnpj })}
          {showInputTypeFilterMenuItem({ filter: documentFilterType.nome })}
        </Select>
      </FormControl>
    )

    const showClearFiltersButton = () => {
      const clearMonitoringFilters = () => {
        setInputTypeFilter(documentFilterType.all)
        setValidFilter(validFilterType.all)
        setTermFilter('')
      }

      const disabled = validFilter === validFilterType.all && inputTypeFilter === documentFilterType.all && termFilter === ''
      return (
        <Tooltip arrow placement='left' title={(<h3>Limpar filtragem de documentos</h3>)}>
          <div>
            <TextButton disabled={disabled} onClick={clearMonitoringFilters} variant='text'>Limpar</TextButton>
          </div>
        </Tooltip>
      )
    }

    return (
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        {showTermSearchControl()}
        {showValidFilteringControl()}
        {showInputTypeFilteringControl()}
        {showClearFiltersButton()}
      </div>
    )
  }

  const renderConfirmationTable = () => {

    const removeDocument = (index) => {
      inputs.splice(index, 1)
      const newText = inputs.map((d) => d.raw).join('\n')
      updateDocumentsText(newText)
    }

    const applyFilters = () => {
      if (_.isEmpty(termFilter)
        && inputTypeFilter === documentFilterType.all
        && validFilter === validFilterType.all) {
        return inputs
      }

      return inputs.filter((d) => {
        if (inputTypeFilter !== documentFilterType.all) {
          if (d.inputType !== inputTypeFilter) {
            return false
          }
        }

        if (validFilter !== validFilterType.all) {
          if (d.isValid !== (validFilter === validFilterType.valid)) {
            return false
          }
        }

        if (termFilter !== ''
          && (!d.raw.includes(termFilter)
            && !d.input.includes(termFilter))) {
          return false
        }

        return true
      })

    }
    const applyFilterAndPagination = () => {
      const filteredDocuments = applyFilters()
      return filteredDocuments.slice((currentPage - 1) * CONFIRMATION_TABLE_PAGE_SIZE, currentPage * CONFIRMATION_TABLE_PAGE_SIZE)
    }
    return (
      <div>
        {renderConfirmationTableControls()}

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '35vw' }}>
          <div style={{ height: '55vh', overflow: 'auto' }}>
            <Table className='table'>
              <thead>
                <tr>
                  <th style={{ width: '8vw' }}>Número</th>
                  <th>Tipo</th>
                  <th>Válido?</th>
                  <th>Remover</th>
                </tr>
              </thead>

              <tbody>
                {applyFilterAndPagination().map((d, index) => (
                  <tr key={d.key}>
                    <td>
                      {d.raw}
                    </td>
                    <td>
                      {d.isValid ? d.inputType.toUpperCase() : <MUIIcon type='empty' />}
                    </td>
                    <td>
                      <Tooltip title={<h3>{d.isValid && !d.isRepeated ? 'Válido' : 'Inválido ou repetido'}</h3>}>
                        <span><MUIIcon type={d.isValid && !d.isRepeated ? 'valid' : 'invalid'} /></span>
                      </Tooltip>
                    </td>
                    <td>
                      <Button onClick={() => removeDocument(index)}>
                        <MUIIcon type='delete' />
                      </Button>
                    </td>
                  </tr>
                ),
                )}
              </tbody>
            </Table>
          </div>
          <div>
            <Pagination
              count={Math.ceil(inputs.length / CONFIRMATION_TABLE_PAGE_SIZE)}
              page={currentPage}
              onChange={handleChangePage}
            />
          </div>
        </div>
      </div>
    )

  }

  return (
    <React.Fragment>
      <DialogContent>
        {renderConfirmationTable()}

        {renderDocumentCount()}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} color="primary">
          Voltar à Inserção
        </Button>
        {loadingRequest ? <CircularProgress size={20} /> : (
          <Button
            disabled={validInputsCount === 0}
            onClick={onConfirmRequest}
            color="primary"
          >
            {formType === peopleMonitoringFormTypes.create ? 'Monitorar' : 'Adicionar'}
            {' '}
            {validInputsCount}
            {' '}
            pessoa
            {validInputsCount > 1 && 's'}
          </Button>
)}
      </DialogActions>
    </React.Fragment>
  )
}

export default DocumentMonitoringConfirmation

