export const planCategoryNames = {
  crimes_against_property: 'Crimes contra a propriedade',
  crimes_against_person: 'Crimes contra pessoa',
  crimes_against_public_adm: 'Crimes contra administração pública',
  crimes_against_sexual_dignity: 'Crimes contra a dignidade sexual',
  crimes_against_public_safety: 'Crimes contra a segurança pública',
  crimes_against_justice_adm: 'Crimes contra a justiça administrativa',
  economic_crimes: 'Crimes econômicos',
}

export const defaultPlanKeywords = {
  crimes_against_property: [
    'Furto', 'Roubo', 'Latrocínio', 'Receptação', 'Dano', 'Extorsão', 'Usurpação',
    'Estelionato',
  ],

  crimes_against_person: [
    'Homicídio', 'Infanticídio', 'Lesão Corporal', 'Abandono de Incapaz',
    'Tráfico', 'Sequestro', 'Ocultação',
  ],

  crimes_against_public_adm: [
    'Compra e Venda de Sentenças', 'Improbidade Administrativa', 'Prevaricação', 'Peculato',
    'Apropriação Indébita', 'Colarinho Branco', 'Inserção de Dados Falsos',
    'Modificação Não Autorizada de Sistema de Informações',
    'Alteração Não Autorizada de Sistema de Informações',
    'Delação', 'Zelotes', 'LavaJato',
  ],

  crimes_against_sexual_dignity: [
    'Exploração da Prostituição',
    'Coerção', 'Venda de Crianças',
  ],

  crimes_against_public_safety: [
    'Foragido', 'Inquérito', 'Autuação', 'Evasão de Divisas',
    'Organização Criminosa', 'Formação de Quadrilha',
  ],

  crimes_against_justice_adm: [
    'Falso Testemunho', 'Denúncia Caluniosa', 'Habeas Corpus', 'Processo Criminal', 'Investigação', 'Réu', 'Criminal',
  ],

  economic_crimes: [
    'Lavagem de Dinheiro', 'Fraude', 'Assalto', 'Violação Do Direito Autoral', 'Contrabando',
    'Offshore', 'Dinheiro Desviado', 'Cartel do Câmbio', 'Laranja', 'Doleiro',
    'Verba Pública', 'Remessa Ilegal', 'Chantagem', 'Paraíso Fiscal',
  ],
}

export const defaultSocialMediaPlanWords = [
  'Política', 'Direita', 'Esquerda', 'PT', 'Lula', 'Bolsonaro', 'Pirataria', 'Crackeado', 'Download', 'Torrent'
]
