import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  consultsControlsBar: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  controlsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  doneButtonIconUnMarked: {
    fontSize: '1.7rem',
    marginLeft: '0.5rem',
    paddingButton: '0.5rem',
  },
  personInfoDiv: {
    gap: 8,
    '& p': {
      fontSize: '1.5rem',
    },
    '& span': {
      fontSize: '1.5rem',
    },
  },
  listItem: {
    fontSize: '1.5rem',
  },
}))

export default styles
