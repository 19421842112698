import * as React from 'react'
import { SERVICE_STATUS } from 'utils/constants/consultConstants'
import {
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { BR_DATE_TIME_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import SearchIcon from '@material-ui/icons/Search'
import { useState, useEffect } from 'react'
import { parseSearchTerm } from 'utils/functions'
import { Pagination, Skeleton } from '@material-ui/lab'
import { formatDocument } from 'utils/masks'
import _ from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { DISABLED_COLOR } from 'utils/constants/colors'
import { Table } from '..'
import MUIIcon from '../Icon/MUIIcon'
import TextFieldDatePicker from '../TextFieldDatePicker'

export default function ConsultsList({ consultData, isLoading, handleClickConsult, onConsultFilterChange }) {
  const statusFilterType = {
    all: 'all',
    processing: 'processing',
    approved: 'approved',
    reproved: 'reproved',
    error: 'error',
  }

  const statusFilteLabel = {
    all: 'Mostrar todos',
    processing: 'Processando',
    approved: 'Sem ocorrências',
    reproved: 'Com ocorrências',
    error: 'Com erro',
  }

  const [statusFilter, setStatusFilter] = useState(statusFilterType.all)
  const [initialDateFilter, setInitialDateFilter] = useState('')
  const [finalDateFilter, setFinalDateFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const [consultFilters, setConsultFilters] = useState({
    page: 1,
    excludeMonitorings: true,
    document: null,
    name: null,
    consists: null,
    status: null,
    initialDate: null,
    finalDate: null,
  })

  /** When consultFiter object is rebuilt, fires onConsultFilterChange that will trigger a new API request. */
  useEffect(() => {
    onConsultFilterChange(consultFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultFilters])

  /** When click to search by a term (document or name), rebuilds the consultFiter object. */
  const handleClickSearchTerm = () => {
    const termObject = parseSearchTerm(searchTerm)
    setConsultFilters({ ...consultFilters, ...termObject, page: 1 })
  }

  const handleClickExcludeMonitorings = () => {
    setConsultFilters({ ...consultFilters, excludeMonitorings: !consultFilters.excludeMonitorings })
  }

  /** When status filter is changed, rebuilds the consultFiter object. */
  useEffect(() => {
    const parseStatusFilter = () => {
      switch (statusFilter) {
        case statusFilterType.all:
          return { status: null, consists: null }
        case statusFilterType.processing:
          return { status: 'PROCESSING', consists: null }
        case statusFilterType.approved:
          return { status: 'PROCESSED', consists: false }
        case statusFilterType.reproved:
          return { status: 'PROCESSED', consists: true }
        case statusFilterType.error:
          return { status: 'ERROR', consists: null }
        default:
          return {}
      }
    }
    const termObject = parseSearchTerm()
    const statusObject = parseStatusFilter()
    setConsultFilters({ ...consultFilters, ...statusObject, ...termObject })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter])

  /** When date filters are changed, rebuilds the consultFiter object. */
  useEffect(() => {
    const termObject = parseSearchTerm()
    setConsultFilters({
      ...consultFilters,
      ...{ initialDate: initialDateFilter, finalDate: finalDateFilter },
      ...termObject,
      page: 1,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateFilter, finalDateFilter])

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleChangeStatusFilter = (event) => {
    setStatusFilter(event.target.value)
  }

  const handleInitialDateFilterChange = (event) => {
    setInitialDateFilter(event.target.value)
  }

  const handleFinalDateFilterChange = (event) => {
    setFinalDateFilter(event.target.value)
  }

  const renderStatus = (c) => {
    if (!c) {
      return <MUIIcon type="empty" />
    }

    switch (c.status) {
      case SERVICE_STATUS.processing:
        return <MUIIcon type="waiting" />
      case SERVICE_STATUS.error:
        return <MUIIcon type="warning" />
      default:
        break
    }

    switch (c.consists) {
      case true:
        return <MUIIcon type="reproved" />
      case false:
        return <MUIIcon type="approved" />
      default:
        return <MUIIcon type="empty" />
    }
  }

  const renderConsults = () =>
    consultData.items.map((c) => (
      <tr key={c.request_id} onClick={() => handleClickConsult(c)} style={{ cursor: 'pointer' }}>
        <td>{dateToStr(c.created_at, BR_DATE_TIME_FORMAT)}</td>
        <td>{c.name?.toUpperCase() || <MUIIcon type="empty" />}</td>
        <td>{formatDocument(c.document) || <MUIIcon type="empty" />}</td>
        <td>{c.initial_date || <MUIIcon type="empty" />}</td>
        <td>{c.final_date || <MUIIcon type="empty" />}</td>
        <td>{renderStatus(c)}</td>
        <td>{renderStatus(c.social_media_consult)}</td>
      </tr>
    ))

  const renderNoConsults = () => (
    <tr>
      <td colSpan={6}>Nenhuma consulta encontrada</td>
    </tr>
  )

  const renderLoading = () =>
    [...Array(consultData?.items?.length)].map(() => (
      <tr key={`skeleton${Math.random()}`}>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
      </tr>
    ))

  const showStatusFilterMenuItem = ({ filter, iconName }) => (
    <MenuItem value={filter}>
      {iconName && (
        <span style={{ paddingTop: 5, paddingRight: 2 }}>
          <MUIIcon type={iconName} />
        </span>
      )}
      {statusFilteLabel[filter]}
    </MenuItem>
  )

  const useStyles = makeStyles(() => ({
    textField: {
      fontSize: '1.3rem',
    },
    consultsControlsBar: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
  }))

  const classes = useStyles()

  const showControls = () => {
    const showDateFilteringControls = () => (
      <span>
        <TextFieldDatePicker
          value={initialDateFilter}
          style={{ marginRight: 10 }}
          label="De:"
          maxDate={finalDateFilter || new Date()}
          onChange={handleInitialDateFilterChange}
          InputProps={{
            className: classes.textField,
          }}
        />

        <TextFieldDatePicker
          value={finalDateFilter}
          label="Até:"
          maxDate={new Date()}
          minDate={initialDateFilter}
          onChange={handleFinalDateFilterChange}
          InputProps={{
            className: classes.textField,
          }}
        />
      </span>
    )

    const showTermSearchAndMonitoringViewControl = () => (
      <span style={{ display: 'flex', alignItems: 'end' }}>
        <FormControl>
          <FormHelperText>Filtrar consultas por:</FormHelperText>
          <TextField
            style={{ width: '9.1vw' }}
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            placeholder="CPF, CNPJ ou Nome"
            InputProps={{
              className: classes.textField,
            }}
          />
        </FormControl>

        <IconButton style={{ padding: '0px 10px' }} onClick={handleClickSearchTerm}>
          <SearchIcon style={{ verticalAlign: 'center' }} />
        </IconButton>

        <Tooltip
          arrow
          title={(
            <h3>
              {consultFilters.excludeMonitorings ? 'Mostrar ' : 'Esconder '}
              consultas diárias de
              <br />
              monitoramentos
            </h3>
          )}
        >
          <span>
            <IconButton style={{ padding: '0px 10px' }} onClick={handleClickExcludeMonitorings}>
              <MUIIcon
                type="eye"
                color={consultFilters.excludeMonitorings ? DISABLED_COLOR : null}
                style={{ verticalAlign: 'center' }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </span>
    )

    const showStatusFilteringControl = () => (
      <FormControl>
        <FormHelperText id="filter-select">Resultado:</FormHelperText>
        <Select
          labelId="filter-select"
          id="demo-simple-select-filled"
          value={statusFilter}
          onChange={handleChangeStatusFilter}
          renderValue={(value) => statusFilteLabel[value]}
          className={classes.textField}
        >
          {showStatusFilterMenuItem({ filter: statusFilterType.all })}
          {showStatusFilterMenuItem({ filter: statusFilterType.processing, iconName: 'waiting' })}
          {showStatusFilterMenuItem({ filter: statusFilterType.approved, iconName: 'approved' })}
          {showStatusFilterMenuItem({ filter: statusFilterType.reproved, iconName: 'reproved' })}
          {showStatusFilterMenuItem({ filter: statusFilterType.error, iconName: 'warning' })}
        </Select>
      </FormControl>
    )
    return (
      <div className={classes.consultsControlsBar}>
        {showTermSearchAndMonitoringViewControl()}

        {showDateFilteringControls()}

        {showStatusFilteringControl()}
      </div>
    )
  }

  const handleChangePage = async (event, page) => setConsultFilters({ ...consultFilters, page })

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {showControls()}
      <div style={{ height: '70vh', maxWidth: '45vw', overflow: 'auto' }}>
        <Table className="table">
          <thead>
            <tr>
              <th style={{ width: '11vw' }}>Data</th>
              <th style={{ width: '27vw' }}>Nome</th>
              <th style={{ width: '14vw' }}>Documento</th>
              <th style={{ width: '10vw' }}>Data Inicial</th>
              <th style={{ width: '10vw' }}>Data Final</th>
              <th>Notícias</th>
              <th>Mídias Sociais</th>
            </tr>
          </thead>

          <tbody>
            {isLoading ? renderLoading() : !_.isEmpty(consultData.items) ? renderConsults() : renderNoConsults()}
          </tbody>
        </Table>
      </div>

      <Pagination count={consultData.totalPages} page={consultData.currentPage} onChange={handleChangePage} />
    </Container>
  )
}
