import React, { useState } from 'react'
import { Button, Container, DialogActions, TextField } from '@material-ui/core'
import 'date-fns'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import reportError from 'utils/errorReporter'
import { createUser as postCreateUser } from 'services/users'
import { stringIsValidPersonName, stringIsValidEmail } from 'utils/functions'

const CreateUser = ({ handleGoBack }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const accessToken = useSelector(state => state.auth.access_token)


  const [hasNameError, setHasNameError] = useState(false)
  const [hasEmailError, setHasEmailError] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const addUser = async () => {
    try {
      await postCreateUser(apiKey, userId, accessToken, name, email)
    } catch (err) {
      reportError(err, 'Occorreu erro ao criar o usuário')
      return
    }
    toast.success('Usuário criado com sucesso!')
    handleGoBack()
  }

  const handleChangeName = (event) => {
    const typedName = event.target.value
    const valid = typedName?.length >= 5 && stringIsValidPersonName(typedName)
    setName(typedName)
    setHasNameError(!valid)
  }

  const handleChangeEmail = (event) => {
    const typedEmail = event.target.value
    const valid = stringIsValidEmail(typedEmail)
    setEmail(typedEmail)
    setHasEmailError(!valid)
  }

  return (
    <React.Fragment>
      <Container>
        <div>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label='Nome e sobrenome'
            value={name}
            onChange={handleChangeName}
            error={!_.isEmpty(name) && hasNameError}
            helperText={!_.isEmpty(name) && hasNameError ? 'Nome inválido' : null}
            style={{ marginTop: 10 }}
          />
        </div>

        <div>
          <TextField
            fullWidth
            margin="dense"
            value={email}
            onChange={handleChangeEmail}
            label="E-mail"
            error={!_.isEmpty(email) && hasEmailError}
            helperText={!_.isEmpty(email) && hasEmailError ? 'E-mail inválido' : null}
            style={{ marginTop: 10 }}
          />
        </div>

        <br />
      </Container>
      <DialogActions>
        <Button onClick={handleGoBack}>Voltar</Button>

        <Button disabled={_.isEmpty(name) || _.isEmpty(email) || hasEmailError || hasNameError} onClick={addUser}>
          Adicionar
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default CreateUser
