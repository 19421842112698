import * as React from 'react'
import { Avatar, Typography } from '@material-ui/core'

const TipBox = ({ icon, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '1px',
        backgroundColor: '#eee',
        alignItems: 'center',
        gap: 10,
        padding: 15,
        marginTop: 5,
      }}
    >
      <Avatar>{icon}</Avatar>
      <Typography>{children}</Typography>
    </div>
  )
}

export default TipBox
