/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppBar as MuiAppBar, IconButton, Tooltip } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'

import { Button } from 'ui/components'
import { accessAppBarMenu, logout } from 'redux/auth/actions'

import MUIIcon from 'ui/components/Icon/MUIIcon'

import MenuIcon from '@material-ui/icons/Menu'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import { roleNames } from 'utils/constants/user'
import styles from './styles'

const AppBar = () => {
  const classes = styles()
  const dispatch = useDispatch()

  const userName = useSelector((state) => state.auth.name)
  const role = useSelector((state) => state.auth.role)
  const appBarMenuOpen = useSelector((state) => state.auth.appBarMenuOpen)


  const handleLogout = () => {
    dispatch(logout())
  }

  const handleClikcOpenBarMenu = () => {
    dispatch(accessAppBarMenu())
  }

  return (
    <div className={classes.root}>
      <MuiAppBar position="static">
        <Toolbar>
          <IconButton onClick={handleClikcOpenBarMenu}>
            { appBarMenuOpen ? <MenuOpenIcon style={{ color: 'white' }} /> : <MenuIcon style={{  color: 'white' }} />  }
          </IconButton>
          <img
            className={classes.logo}
            src="/assets/images/logos/logo.svg"
            alt="logo"
          />
          <div className={classes.titleContainer}>
            <h2>Monitoramento de Mídias</h2>

            <p className={classes.versionText}>
              {`version: ${process.env.REACT_APP_PROJECT_VERSION}`}
            </p>
          </div>

          {
            process.env.REACT_APP_LOGIN_DISABLED === 'true'
            ? null
            : (
              <div className={classes.userContainer}>
                <div>
                  <h3>{userName}</h3>
                  { role !== 'default' && <h4>{roleNames[role]}</h4>}
                </div>
                <Tooltip title={<h3>Fazer logout</h3>}>
                  <span>
                    <Button onClick={handleLogout}>
                      <MUIIcon fontSize='2.5rem' type='exit' />
                    </Button>
                  </span>
                </Tooltip>
              </div>
            )
          }
        </Toolbar>
      </MuiAppBar>
    </div>
  )


}

export default AppBar
