import { BR_DATE_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import { objToQueryString } from 'utils/functions'
import axios from './axiosBase'

const HOST = process.env.REACT_APP_API

export async function postCreateMonitoring(apiKey, userId, params = {}) {
  const data = {
    user_id: userId,
    name: params.name,
    search_in_social_media: params.searchInSocialMedia,
    inputs: params.inputs,
    description: params.description,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/monitoring/person`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}


export async function patchPeopleMonitoring(apiKey, userId, requestId, params = {}) {
  const data = {
    user_id: userId,
    active: params.active,
    request_id: requestId,
    new_inputs: params.newInputs,
    disabled_inputs: params.disabledInputs,
    search_in_social_media: params.searchInSocialMedia,
    active_inputs: params.activeInputs,
  }

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/monitoring/person`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function getPeopleMonitoringNotices(apiKey, userId, requestId, personId, type, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    request_id: requestId,
    person_id: personId,
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    older_first: params.olderFirst,
    page: params.page,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/monitoring/person/notices?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getPeopleMonitoringPosts(apiKey, userId, requestId, personId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    request_id: requestId,
    person_id: personId,
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    older_first: params.olderFirst,
    page: params.page,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/monitoring/person/posts?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getPeopleMonitorings(apiKey, userId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    request_id: params.request_id,
    description: params.description,
    active: params.active,
    consists: params.consists,
    page: params.page,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/monitoring/person?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}
