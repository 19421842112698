import * as React from 'react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Chip, Fade, Link, List, ListItem, ListItemText } from '@material-ui/core'
import { THEMES_TRANSLATION } from 'utils/constants/consultConstants'
import OccurrenceTimestampChip from 'ui/components/OccurrenceTimestampChip'
import styles from '../styles'
import { groupItems, groupModes, itemTypes } from '../itemsGrouper'
import showStatusMessage from '../messages'
import NoticesReviewButton from '../NoticesReviewButton'
import GroupTitle from '../GroupTitle'

const NoticesList = ({
  noticesData,
  searchTerm,
  isLoading,
  hideReviewed,
  groupMode,
  reviewButtonsVisibility,
  onUpdateNotice,
  toggleReviewButtonVisible,
  sortOrder,
}) => {
  const [noticeGroups, setNoticeGroups] = useState([])

  const classes = styles()

  const showNoticeThemeChip = (n) =>
    n.snippet?.theme && (
      <Chip style={{ marginTop: 3, fontSize: '1.1rem' }} size="small" label={THEMES_TRANSLATION[n.snippet.theme]} />
    )

  /**  */
  useEffect(() => {
    const markReviewedGroups = (groups) => {
      Object.keys(groups).forEach((key, _index) => {
        const group = groups[key]
        group.allViewed = group.items.every((notice) => !_.isEmpty(notice.snippet?.review))
      })
    }

    const filterNotices = () => {
      if (_.isEmpty(searchTerm) || _.isEmpty(noticesData.items)) {
        return noticesData.items
      }

      const searchTermLowered = searchTerm.toLowerCase()

      return noticesData.items.flatMap((n) => {
        if (
          n.title?.toLowerCase().includes(searchTermLowered) ||
          n.snippet?.description?.toLowerCase().includes(searchTermLowered) ||
          THEMES_TRANSLATION[n.snippet?.theme]?.toLowerCase().includes(searchTermLowered)
        ) {
          return n
        }
        return []
      })
    }

    const groups = groupItems(groupMode, filterNotices(), itemTypes.notice, sortOrder)
    markReviewedGroups(groups)
    setNoticeGroups(groups)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticesData, searchTerm, groupMode])

  const statusMessage = showStatusMessage({
    consultType: 'negativeMedia',
    data: noticesData,
    isLoading: isLoading,
    allItemsReviewed: hideReviewed && !_.isEmpty(noticeGroups) && _.every(noticeGroups, (g) => g.allViewed),
  })

  const showNotices = () => {
    return Object.entries(noticeGroups).map(([noticeDate, noticeGroup]) => {
      const allGroupViewed = hideReviewed && noticeGroup.allViewed
      return (
        !allGroupViewed && (
          <List key={`${noticeDate}`}>
            <div>
              <ListItem>
                <ListItemText primary={<GroupTitle itemDate={noticeDate} groupMode={groupMode} />} />
              </ListItem>
              <br />
            </div>

            {_.map(noticeGroup.items, (n, index) => {
              const reviewed = !_.isEmpty(n.snippet?.review)
              if (hideReviewed && reviewed) {
                return null
              }
              return (
                <Fade key={n.link} in={!isLoading} style={{ transitionDelay: !isLoading ? '150ms' : '0ms' }}>
                  <ListItem key={n.link}>
                    <div
                      style={{
                        marginBottom: index === noticeGroup.items.length - 1 ? 15 : 0,
                      }}
                    >
                      <ListItemText
                        primary={(
                          <div
                            onMouseEnter={() => toggleReviewButtonVisible(n.link, true)}
                            onMouseLeave={() => toggleReviewButtonVisible(n.link, false)}
                          >
                            <Link
                              underline="always"
                              className={reviewed ? classes.noticeLinkMarked : classes.noticeLink}
                              target="blank"
                              href={n.link}
                            >
                              <span>{n.title}</span>
                            </Link>
                            <NoticesReviewButton
                              notice={n}
                              reviewButtonsVisibility={reviewButtonsVisibility}
                              onUpdateNotice={onUpdateNotice}
                            />
                          </div>
                        )}
                        secondary={(
                          <span
                            onMouseEnter={() => toggleReviewButtonVisible(n.link, true)}
                            onMouseLeave={() => toggleReviewButtonVisible(n.link, false)}
                          >
                            {n?.snippet?.description}
                          </span>
                        )}
                      />

                      {showNoticeThemeChip(n)}

                      {groupMode === groupModes.publishedDate && n?.snippet?.created_at && (
                        <OccurrenceTimestampChip
                          occurenceType={groupModes.createdAt}
                          occurrenceDate={n.snippet.created_at}
                          postType="notícia"
                        />
                      )}
                      {groupMode === groupModes.createdAt && n?.notice_date && (
                        <OccurrenceTimestampChip
                          occurenceType={groupModes.publishedDate}
                          occurrenceDate={n.notice_date}
                          postType="notícia"
                        />
                      )}
                    </div>
                  </ListItem>
                </Fade>
              )
            })}
          </List>
        )
      )
    })
  }
  return (
    statusMessage ||
    (!_.isEmpty(noticeGroups) && (
      <React.Fragment>
        {/* {itemsGroupDescription('Notícias', groupMode)} */}
        {showNotices()}
      </React.Fragment>
    ))
  )
}

export default NoticesList
