import { IconButton, Tooltip } from '@material-ui/core'
import { TableChartOutlined, TocOutlined } from '@material-ui/icons'
import React, {  } from 'react'

const KeyswordDisplayModeButton = ({ tableMode, onClick }) => {

  return (
    <div style={{ marginTop: 10, float: 'right' }}>
      <Tooltip arrow title={<h3>{`Ver em ${!tableMode ? 'forma de tabela' : 'formato textual'}`}</h3>}>
        <IconButton style={{ padding: 0 }} color="primary" onClick={onClick}>
          {tableMode ? (
            <TocOutlined style={{ fontSize: '3.1rem' }} />
        ) : (
          <TableChartOutlined style={{ fontSize: '2.8rem' }} />
        )}
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default KeyswordDisplayModeButton
