import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { SERVICE_STATUS } from 'utils/constants/consultConstants'
import _ from 'lodash'
import MUIIcon from '../Icon/MUIIcon'

const showLoading = () => (
  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress />
  </div>
)

const showMessage = ({ icon, message }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    }}
  >
    <span style={{ verticalAlign: 'middle' }}>{icon}</span>
    <p style={{ fontSize: '1.35rem' }}>{message}</p>
  </div>
)
const showNotConsists = (type) =>
  showMessage({
    icon: <MUIIcon type="approved" color="#ddd" />,
    message: `Nenhuma ${type === 'socialMedia' ? 'postagem' : 'notícia'} encontrada`,
  })

const showAllReviewed = (type) =>
  showMessage({
    icon: <MUIIcon type="approved" color="#ddd" />,
    message: (
      <span>
        Tudo certo!
        <br />
        {`Todas as ${type === 'socialMedia' ? 'postagens' : 'notícias'} analisadas`}
      </span>
    ),
  })

const showProcessing = () =>
  showMessage({
    icon: <MUIIcon type="waiting" />,
    message: 'Consulta em andamento...',
  })

const showError = () =>
  showMessage({
    icon: <MUIIcon type="warning" color="#ddd" />,
    message: 'Ocorreu um erro ao realizar a consulta',
  })

const showNoExecutions = () =>
  showMessage({
    icon: <MUIIcon type="waiting" />,
    message: 'Nenhuma execução realizada ainda...',
  })

const showNotRequested = () =>
  showMessage({
    icon: <MUIIcon type="empty" />,
    message: 'Nada a ser exibido',
  })


const showStatusMessage = ({ consultType, data, isLoading, allItemsReviewed }) => {
  if (isLoading) {
    return showLoading()
  }

  if (_.isEmpty(data)) {
    return showNotRequested(consultType)
  }

  if (data.status === SERVICE_STATUS.processing) {
    return showProcessing()
  }
  if (data.status === SERVICE_STATUS.error) {
    return showError()
  }
  if (data.monitoringNeverExecuted) {
    return showNoExecutions()
  }
  if (data.consists === false) {
    return showNotConsists(consultType)
  }
  if (allItemsReviewed === true) {
    return showAllReviewed(consultType)
  }

  return null
}

export default showStatusMessage
