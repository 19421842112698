import { Badge, Tooltip } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import MUIIcon from '../Icon/MUIIcon'



const OccurrenceCountBadge = ({ count }) => {
  const styles = makeStyles(() => ({
    customBadge: {
      backgroundColor: 'red',
      color: 'white',
      fontWeight: 'bold',
    },
  }))

  const classesa = styles()
  return (
    <Tooltip arrow placement='top' title={<h4>{count > 0 ? 'Apontamentos não analisados' : 'Nenhum apontamento a ser analisado' }</h4>}>
      {count > 0 ? <Badge classes={{ badge: classesa.customBadge }} badgeContent={count} /> : (
        <span>
          <MUIIcon type="approved" color='#bbb' />
        </span>
)}
    </Tooltip>
)
}

export default OccurrenceCountBadge
