import { combineReducers } from 'redux'

import { authReducer } from './auth/reducer'

const appReducer = combineReducers({
  auth: authReducer,
})

export const Reducers = (state, action) => {
  let newState = state
  // reset store in logout
  if (action.type === 'LOGOUT') {
    newState = undefined
  }

  return appReducer(newState, action)
}
