import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import styles from './styles'

export default ({
  children,
  className,
  color = 'primary',
  disabled,
  fullWidth = false,
  loading,
  onClick = () => {},
  type,
  variant = 'contained',
}) => {
  const classes = styles()

  const onClickDebounce = _.debounce(onClick, 1000, {
    'leading': true,
    'trailing': false,
  })

  const renderChildren = () => {
    if (_.isString(children)) return <strong>{children}</strong>

    return children
  }

  return (
    <Button
      className={classnames(classes.button, className)}
      color={color}
      disabled={disabled || loading}
      disableElevation={true}
      fullWidth={fullWidth}
      onClick={!disabled && !loading && _.isFunction(onClick) ? onClickDebounce : null}
      type={type}
      variant={variant}
    >
      {
        loading
        ? <CircularProgress />
        : renderChildren()
      }
    </Button>
  )
}
