import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { MaskInput } from 'ui/components/MaskedInput'
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { useState } from 'react'
import { isDocNumberValid } from 'utils/validators'
import _ from 'lodash'
import { postCreatePerson } from 'services/consultApi'
import reportError from 'utils/errorReporter'
import { useSelector } from 'react-redux'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import { extractOnlyNumbers } from 'utils/functions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import TipBox from 'ui/components/TipBox'

const AddPersonDialog = ({ open, handleCloseDialog }) => {
  const CONSULT_TYPE_LABEL = { cpf: 'CPF', cnpj: 'CNPJ', unknow: 'unknow' }
  const [personType, setPersonType] = useState(CONSULT_TYPE_LABEL.cpf)
  const [document, setDocument] = useState('')
  const [name, setName] = useState('')
  const [createByName, setCreateByName] = useState(false)
  const [hasError, setHasError] = useState(true)

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const history = useHistory()

  const handleChangePersonType = (event) => {
    setDocument('')
    setHasError(true)
    setPersonType(event.target.value)
  }

  const handleChangeDocument = (event) => {
    const doc = event.target.value
    setDocument(doc)
    setHasError(!isDocNumberValid(doc, personType))
  }

  const handleChangeFormType = () => {
    setCreateByName(!createByName)
    setPersonType(!createByName ? CONSULT_TYPE_LABEL.unknow : CONSULT_TYPE_LABEL.cpf)
    setDocument('')
    setName('')
    setHasError(true)
  }

  const handleChangeName = (event) => {
    const typedName = event.target.value
    const valid = typedName?.length >= 2
    setName(typedName)
    setHasError(!valid)
  }

  const handleCreatePerson = async () => {
    let res = null
    try {
      res = await postCreatePerson(apiKey, userId, {
        name,
        document: extractOnlyNumbers(document),
        personType: personType?.toLowerCase(),
      })
    } catch (err) {
      reportError(err, 'Erro ao cadastrar pessoa')
      return null
    }

    handleCloseDialog()
    history.push(`${ROUTE_CONSTANTS.PEOPLE_DATABASE}/${res.data.id}`)
    return null
  }

  const nameForm = () => (
    <React.Fragment>
      <DialogContentText>Informe um nome</DialogContentText>

      <TextField
        autoFocus
        fullWidth
        margin="dense"
        value={name}
        onChange={handleChangeName}
        label="Nome"
        error={!_.isEmpty(name) && hasError}
        helperText={!_.isEmpty(name) && hasError ? 'Nome inválido' : null}
        style={{ marginTop: 10 }}
      />

      <Button color="primary" onClick={handleChangeFormType}>
        Cadastrar usando um documento
      </Button>

      <TipBox icon={<ReportProblemOutlinedIcon />}>
        Atenção: Com o cadastro por nome, o sistema não poderá validar o tipo da pessoa (física ou jurídica), nem
        deduzir outras informações como nomes fantasia ou apelidos.
      </TipBox>
    </React.Fragment>
  )

  const documentForm = () => (
    <React.Fragment>
      <DialogContentText>Informe o documento</DialogContentText>

      <MaskInput
        autoFocus
        fullWidth
        name={personType}
        margin="dense"
        guide={true}
        value={document}
        onChange={handleChangeDocument}
        label={personType}
        errorMsg={!_.isEmpty(document) && hasError ? 'Documento inválido' : null}
        style={{ marginTop: 10 }}
      />

      <FormControl style={{ marginTop: 5 }} component="fieldset">
        <RadioGroup row value={personType} onChange={handleChangePersonType}>
          <FormControlLabel value={CONSULT_TYPE_LABEL.cpf} control={<Radio />} label={CONSULT_TYPE_LABEL.cpf} />
          <FormControlLabel value={CONSULT_TYPE_LABEL.cnpj} control={<Radio />} label={CONSULT_TYPE_LABEL.cnpj} />
        </RadioGroup>
      </FormControl>

      <br />

      <Button color="primary" onClick={handleChangeFormType}>
        Cadastrar usando um nome
      </Button>

      <TipBox icon={<WbIncandescentOutlinedIcon />}>
        Dica: Informando o documento, o sistema buscará na base de dados da receita federal o nome completo ou a razão
        social da pessoa quando uma consulta for criada.
      </TipBox>
    </React.Fragment>
  )

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Cadastrar Pessoa</DialogTitle>
      <DialogContent>
        {!createByName && documentForm()}
        {createByName && nameForm()}

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCreatePerson} disabled={hasError} color="primary">
            Cadastrar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default AddPersonDialog
