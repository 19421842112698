import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import reportError from 'utils/errorReporter'
import { Container } from '@material-ui/core'
import PeopleMonitoringsList from 'ui/components/PeopleMonitoringsList'
import NoticesAndPostsPanel from 'ui/components/NoticesAndPostsPanel'
import AddNewButton from 'ui/components/AddNewDialog/AddNewButton'
import {
  getPeopleMonitoringNotices,
  getPeopleMonitoringPosts,
  getPeopleMonitorings,
  patchPeopleMonitoring,
} from 'services/monitoringApi'
import MonitoredInputsPanel from 'ui/components/MonitoredInputsPanel'
import SidebarMenu, { MENU_PAGES } from 'ui/components/SidebarMenu'
import { useParams } from 'react-router-dom'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import { hasMsgInfo } from 'utils/functions'

const PeopleMonitoringPage = (props) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const savedListMonitoringsCallback = useRef()

  const [loading, setLoading] = useState(false)
  const [monitoringsData, setMonitoringsData] = useState({
    totalMonitorings: 0,
    totalPages: 0,
    currentPage: 1,
    items: [],
  })
  const [noticesLoading, setNoticesLoading] = useState(false)
  const [socialMediaPostsLoading, setSocialMediaPostsLoading] = useState(false)
  const [activeNoticesData, setActiveNoticesData] = useState({
    totalNotices: 0,
    totalPages: 0,
    currentPage: 1,
    lastExecutionStatus: null,
    consists: null,
    items: [],
  })
  const [activeSocialMediaPostsData, setActiveSocialMediaPostsData] = useState({
    totalNotices: 0,
    totalPages: 0,
    currentPage: 1,
    status: null,
    consists: null,
    items: [],
  })
  const [activeMonitoring, setActiveMonitoring] = useState(null)
  const [activeInput, setActiveInput] = useState(null)
  const pageParams = useParams()

  const onUpdateNoticesData = (newNoticesData) => {
    setActiveNoticesData(newNoticesData)
  }
  const onUpdatePostsData = (newPostsData) => {
    setActiveSocialMediaPostsData(newPostsData)
  }

  useEffect(() => {
    const fetchMonitoringFromUrl = async () => {
      setLoading(true)

      let res = null
      try {
        res = await getPeopleMonitorings(apiKey, userId, {
          request_id: pageParams.request_id,
        })
        setActiveMonitoring(res.data[0])
        setLoading(false)
      } catch (err) {
        reportError(
          err,
          `Erro ao acessar monitoramento ${pageParams.request_id}`,
        )
        setLoading(false)
      }

      return null
    }

    if (_.isEmpty(pageParams.request_id)) {
      setActiveMonitoring(null)
      return
    }

    if (!_.isEmpty(activeMonitoring)) {
      return
    }

    fetchMonitoringFromUrl()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams.request_id, activeMonitoring])

  const listMonitorings = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getPeopleMonitorings(apiKey, userId, {
        page: _.has(options, 'page')
          ? options.page
          : monitoringsData.currentPage,
        description: options.description,
        active: options.active,
        initialDate: options.initialDate,
        finalDate: options.finalDate,
        consists: options.consists,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar monitoramentos')
      setLoading(false)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setMonitoringsData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalMonitorings: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      items: res.data,
    })
    setLoading(false)
  }

  const requestNoticesApi = async (options = {}) => {
    if (_.isEmpty(activeMonitoring) || _.isEmpty(activeInput)) {
      return null
    }
    setNoticesLoading(true)
    let res = null
    try {
      res = await getPeopleMonitoringNotices(
        apiKey,
        userId,
        activeMonitoring.request_id,
        activeInput.person_id,
        activeInput.type,
        {
          olderFirst: _.has(options, 'olderFirst') ? options.olderFirst : false,
          initialDate: options.initialDate,
          finalDate: options.finalDate,
        },
      )
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      setNoticesLoading(false)
      return null
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setActiveNoticesData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalNotices: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      lastExecutionStatus: monitoringsData.last_execution_status,
      consists: !_.isEmpty(res.data.notices),
      items: res.data.notices,
      monitoringNeverExecuted: hasMsgInfo(res.data, 'NO_DATA_FOUND_INFO'),
    })

    setNoticesLoading(false)
    return null
  }

  const requestSocialMediaPostsApi = async (options = {}) => {
    if (_.isEmpty(activeMonitoring) || _.isEmpty(activeInput)) {
      return null
    }

    setSocialMediaPostsLoading(true)

    let res = null

    try {
      res = await getPeopleMonitoringPosts(apiKey, userId,  activeMonitoring.request_id, activeInput.person_id ,{
        olderFirst: _.has(options, 'olderFirst') ? options.olderFirst : false,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar postagens de mídias sociais')
      setSocialMediaPostsLoading(false)
      return null
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setActiveSocialMediaPostsData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalPosts: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      lastExecutionStatus: monitoringsData.last_execution_status,
      consists: !_.isEmpty(res.data.posts),
      items: res.data.posts,
      monitoringNeverExecuted: hasMsgInfo(res.data, 'NO_DATA_FOUND_INFO'),
    })

    setSocialMediaPostsLoading(false)
    return null
  }

  const updateMonitoringApi = async (options) => {
    let res = null
    try {
      res = await patchPeopleMonitoring(
        apiKey,
        userId,
        activeMonitoring?.request_id || options.requestId,
        options,
      )
    } catch (err) {
      reportError(err, 'Erro atualizar dados do monitoramento')
      return null
    }

    if (!options.skipSetActiveMonitoring) {
      setActiveMonitoring(res.data)
    }

    return null
  }

  const listMonitoringsCallback = () => listMonitorings()

  useEffect(() => {
    savedListMonitoringsCallback.current = listMonitoringsCallback
  })

  useEffect(() => {
    requestNoticesApi()
    requestSocialMediaPostsApi()

    const callRequest = () => savedListMonitoringsCallback.current()
    const intervalId = setInterval(callRequest, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInput])

  const updateNoticesPage = ({ person_id, input, type, request_id }) => {
    setActiveInput({ person_id, input, type })

    if (activeMonitoring?.request_id !== request_id) {
      setActiveMonitoring({
        ...activeMonitoring,
        ...{ request_id: request_id },
      })
    }
  }

  const onConsultFilterChange = (params) => {
    listMonitorings(params)
  }

  const onNoticesFilterChange = (params) => {
    requestSocialMediaPostsApi(params)
    requestNoticesApi(params)
  }

  const handleClickMonitoring = (monitoring) => {
    setActiveMonitoring(monitoring)
    props.history.push(
      `${ROUTE_CONSTANTS.PEOPLE_MONITORING}/${monitoring.request_id}`,
    )
  }

  const onReturnFromMonitoringInfo = () => {
    setActiveMonitoring(null)
    props.history.push(ROUTE_CONSTANTS.PEOPLE_MONITORING)
  }

  const onDisableSingleInput = ({ input, active }) => {
    const data = {}

    if (active) {
      data.activeInputs = [input]
    } else {
      data.disabledInputs = [input]
    }
    updateMonitoringApi(data)
  }

  const onUpdateMonitoringData = (data) => {
    updateMonitoringApi(data)
  }

  const renderLeftPanel = () =>
    activeMonitoring ? (
      <div className="pagination-container">
        <MonitoredInputsPanel
          monitoringData={activeMonitoring}
          onReturnFromMonitoringInfo={onReturnFromMonitoringInfo}
          onDisableSingleInput={onDisableSingleInput}
          onUpdateMonitoringData={onUpdateMonitoringData}
          updateNoticesPage={updateNoticesPage}
        />
      </div>
    ) : (
      <div className="pagination-container">
        <PeopleMonitoringsList
          monitoringsData={monitoringsData}
          isLoading={loading}
          handleClickMonitoring={handleClickMonitoring}
          onConsultFilterChange={onConsultFilterChange}
          onUpdateMonitoringData={onUpdateMonitoringData}
        />
      </div>
    )

  const renderNoticePage = () => {
    return (
      <div style={{ marginLeft: 10 }} className="pagination-container">
        <NoticesAndPostsPanel
          noticesData={activeNoticesData}
          socialMediaPostsData={activeSocialMediaPostsData}
          isNoticesLoading={noticesLoading}
          isSocialMediaPostsLoading={socialMediaPostsLoading}
          onNoticesFilterChange={onNoticesFilterChange}
          onUpdateNoticesData={onUpdateNoticesData}
          onUpdatePostsData={onUpdatePostsData}
          disabled={_.isEmpty(activeInput)}
        />
      </div>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <SidebarMenu currentPage={MENU_PAGES.peopleMonitorings} />
      <Container sx={{ maxWidth: 200 }} maxWidth={false}>
        <div style={{ marginTop: 20 }} className="main">
          <div className="requests-container">
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {renderLeftPanel()}
                {renderNoticePage()}
              </div>
            </div>
          </div>
        </div>

        <AddNewButton />
      </Container>
    </div>
  )
}

export default PeopleMonitoringPage
