import { IconButton, Typography } from '@material-ui/core'
import { DeleteOutline, ImportExport, InfoOutlined } from '@material-ui/icons'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Table } from 'ui/components'

const KeywordsTable = ({ disabled, terms, filter, categoryFilter, onRemove }) => {
  const [filteredTerms, setFilteredTerms] = useState([])
  const [baseTerms, setBaseTerms] = useState([])
  const [termsAscendingOrder, setTermsAscendingOrder] = useState({ value: true })

  useEffect(() => {
    setBaseTerms(_.sortBy(terms, (t) => t.value))
  }, [terms])

  useEffect(() => {
    let filtered = baseTerms
    if (!_.isEmpty(filter)) {
      filtered = _.filter(filtered, (t) => t.value.toLowerCase().includes(filter?.toLowerCase()))
    }
    if (!_.isEmpty(categoryFilter)) {
      filtered = _.filter(filtered, (t) => t.category?.toLowerCase()?.includes(categoryFilter?.toLowerCase()))
    }
    setFilteredTerms(filtered)

  }, [baseTerms, filter, categoryFilter])

  const sortableHeaderButton = (title, prop) => {
    const isAscOrder = termsAscendingOrder[prop] || false
    const sortData = () => {
      setBaseTerms(_.orderBy(baseTerms, (t) => t[prop], (isAscOrder ? 'desc' : 'asc')))
      setTermsAscendingOrder({ ...termsAscendingOrder, [prop]: !isAscOrder })
    }


    return (
      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p>{title}</p>
        <IconButton onClick={() => sortData()} style={{ margin: 1, padding: 1, transform: 'scale(0.7)' }}>
          <ImportExport fontSize="small" />
        </IconButton>
      </span>
    )
  }

  const hasCategory = _.some(terms, (t) => !_.isUndefined(t.category))
  return (
    <Table disabled={disabled} style={{ width: '100%', maxHeight: 500 }}>
      <thead>
        <tr>
          <th style={{ width: '18vw' }}>{sortableHeaderButton('Termo', 'value')}</th>
          {hasCategory ? <th style={{ width: '10vw' }}>{sortableHeaderButton('Categoria', 'category')}</th> : null}
          <th style={{ width: '1vw' }}>Remover</th>
        </tr>
      </thead>
      <tbody>
        {filteredTerms.map((tf) => (
          <tr key={tf.key}>
            <td style={{ color: disabled ? '#ccc' : null, maxHeight: 10 }}>{tf.value}</td>
            {hasCategory ? <td style={{ color: disabled ? '#ccc' : null, maxHeight: 10 }}>{tf.category}</td> : null}
            <td style={{ color: disabled ? '#ccc' : null, maxHeight: 10 }}>
              <IconButton disabled={disabled} onClick={() => onRemove(tf)}>
                <DeleteOutline fontSize="small" />
              </IconButton>
            </td>
          </tr>
        ))}
        {filter ? (
          <tr>
            <td style={{ backgroundColor: '#eee' }} colSpan={hasCategory ? 3 : 2}>
              <span style={{ display: 'flex', padding: 8, justifyContent: 'space-around' }}>
                <InfoOutlined />
                <Typography>
                  Caso o termo digitado não esteja presente, clique em &quot;Adicionar&quot; para criar um novo termo desabonador.
                </Typography>
              </span>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  )
}

export default KeywordsTable
