import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { getConsult, getConsults, getSocialMediaConsult } from 'services/consultApi'
import reportError from 'utils/errorReporter'
import { Container } from '@material-ui/core'
import ConsultsList from 'ui/components/ConsultList'
import NoticesAndPostsPanel from 'ui/components/NoticesAndPostsPanel'
import AddNewDialogButton from 'ui/components/AddNewDialog/AddNewButton'
import SidebarMenu, { MENU_PAGES } from 'ui/components/SidebarMenu'

export default function () {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const savedCallbackListConsults = useRef()

  const [loading, setLoading] = useState(false)
  const [consultParams, setConsultParams] = useState({})
  const [consultData, setConsultData] = useState({ totalConsults: 0, totalPages: 0, currentPage: 1, items: [] })
  const [noticesLoading, setNoticesLoading] = useState(false)
  const [socialMediaPostsLoading, setSocialMediaPostsLoading] = useState(false)

  const [activeNoticesData, setActiveNoticesData] = useState({
    totalNotices: 0,
    totalPages: 0,
    currentPage: 1,
    status: null,
    consists: null,
    items: [],
  })
  const [activeSocialMediaPostsData, setActiveSocialMediaPostsData] = useState({
    totalNotices: 0,
    totalPages: 0,
    currentPage: 1,
    status: null,
    consists: null,
    items: [],
  })
  const [activeConsult, setActiveConsult] = useState({})

  const onUpdateNoticesData = (newNoticesData) => {
    setActiveNoticesData(newNoticesData)
  }

  const onUpdatePostsData = (newPostsData) => {
    setActiveSocialMediaPostsData(newPostsData)
  }

  const listConsults = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getConsults(apiKey, userId, {
        page: _.has(options, 'page') ? options.page : consultData.currentPage,
        document: options.document,
        name: options.name,
        status: options.status,
        initialDate: options.initialDate,
        finalDate: options.finalDate,
        consists: options.consists,
        excludeMonitorings: options.excludeMonitorings,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      setLoading(false)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setConsultData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalConsults: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      items: res.data.consults,
    })
    setLoading(false)
  }

  const requestNoticesApi = async (options = {}) => {
    if (_.isEmpty(activeConsult)) {
      return null
    }
    setNoticesLoading(true)

    let res = null
    try {
      res = await getConsult(apiKey, userId, activeConsult.request_id, {
        olderFirst: _.has(options, 'olderFirst') ? options.olderFirst : false,
        initialDate: options.initialDate,
        finalDate: options.finalDate,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar consultas')
      setNoticesLoading(false)
      return null
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setActiveNoticesData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalNotices: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      status: activeConsult.status,
      consists: activeConsult.consists,
      items: res.data.notices,
    })

    setNoticesLoading(false)
    return null
  }

  const requestSocialMediaPostsApi = async (options = {}) => {
    if (_.isEmpty(activeConsult)) {
      return null
    }
    setSocialMediaPostsLoading(true)

    let res = null
    try {
      res = await getSocialMediaConsult(apiKey, userId, activeConsult.request_id, {
        olderFirst: _.has(options, 'olderFirst') ? options.olderFirst : false,
        initialDate: options.initialDate,
        finalDate: options.finalDate,
      })
    } catch (err) {
      reportError(err, 'Erro ao listar consultas de mídias sociais')
      setSocialMediaPostsLoading(false)
      return null
    }

    const xTotal = parseInt(res.headers['x-total'], 10)

    setActiveSocialMediaPostsData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      totalPosts: xTotal,
      currentPage: parseInt(res.headers['x-page'], 10),
      status: activeConsult.social_media_consult?.status,
      consists: activeConsult.social_media_consult?.consists,
      items: res.data.posts,
    })

    setSocialMediaPostsLoading(false)
    return null
  }

  const listConsultsCallback = () => listConsults(consultParams)

  useEffect(() => {
    savedCallbackListConsults.current = listConsultsCallback
  })

  useEffect(() => {
    requestNoticesApi()
    requestSocialMediaPostsApi()

    const callRequest = () => savedCallbackListConsults.current()
    const intervalId = setInterval(callRequest, 120 * 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConsult])

  const onConsultFilterChange = (params) => {
    setConsultParams(params)
    listConsults(params)
  }

  const onNoticesFilterChange = (params) => {
    requestNoticesApi(params)
    requestSocialMediaPostsApi(params)
  }

  const handleClickConsult = (consult) => {
    setActiveConsult(consult)
  }

  const renderConsultList = () => {
    return (
      <div className="pagination-container">
        <ConsultsList
          consultData={consultData}
          isLoading={loading}
          handleClickConsult={handleClickConsult}
          onConsultFilterChange={onConsultFilterChange}
        />
      </div>
    )
  }

  const renderNoticesAndPostsPanel = () => {
    return (
      <div style={{ marginLeft: 10 }} className="pagination-container">
        <NoticesAndPostsPanel
          noticesData={activeNoticesData}
          socialMediaPostsData={activeSocialMediaPostsData}
          isNoticesLoading={noticesLoading}
          isSocialMediaPostsLoading={socialMediaPostsLoading}
          onNoticesFilterChange={onNoticesFilterChange}
          onUpdateNoticesData={onUpdateNoticesData}
          onUpdatePostsData={onUpdatePostsData}
          disabled={_.isEmpty(activeConsult)}
        />
      </div>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <SidebarMenu currentPage={MENU_PAGES.consults} />
      <Container maxWidth={false}>
        <div style={{ marginTop: 20 }} className="main">
          <div className="requests-container">
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {renderConsultList()}
                {renderNoticesAndPostsPanel()}
              </div>
            </div>
          </div>
        </div>

        <AddNewDialogButton />
      </Container>
    </div>
  )
}
