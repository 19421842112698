import axios from "axios"
import { objToQueryString } from "utils/functions"

const HOST = process.env.REACT_APP_API

export async function getUser(apiKey, userId) {
  const queryString = objToQueryString({
    user_id: userId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/user?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
  })

  return res
}

export async function getUsers(apiKey, userId, name, email) {
  const queryString = objToQueryString({
    user_id: userId,
    name: name,
    email: email,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/user/list?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
  })

  return res
}


export async function patchUser(apiKey, userId, updateUserEmail, params) {
  const data = {
    user_id: userId,
    update_user_email: updateUserEmail,
    name: params.name,
    active: params.active,
    role: params.role,
  }

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/user`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function createUser(apiKey, userId, accessToken, name, email) {
  const data = {
    user_id: userId,
    name: name,
    email: email,
    auth_access_token: accessToken,
    role: 'default',
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/user`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}
