export const SERVICE_STATUS = {
  error: 'ERROR',
  processed: 'PROCESSED',
  processing: 'PROCESSING',
}

export const MONITORING_LAST_EXECUTION_STATUS = {
  error: 'ERROR',
  none: 'NONE',
  processed: 'PROCESSED',
  processing: 'PROCESSING',
}

export const MONITORING_STATUS = {
  active: 'ACTIVE',
  disabled: 'DISABLED',
}

export const DOCUMENT_TYPE = {
  CPF: 'cpf',
  CNPJ: 'cnpj',
}


export const THEMES_TRANSLATION = {
  economic_crimes: 'Crimes econômicos',
  crimes_against_public_safety: 'Crimes contra a segurança pública',
  crimes_against_public_adm: 'Crimes contra a administração pública',
  crimes_against_sexual_dignity: 'Crimes contra a dignidade sexual',
  crimes_against_person: 'Crimes contra pessoa',
  crimes_against_property: 'Crimes contra a propriedade',
  crimes_against_justice_adm: 'Crimes contra a administração da justiça',
}
