import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(_theme => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: 30,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 5,
  },
  titleContainer: {
    flexGrow: 1,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  versionText: {
    color: '#888',
    bottom: 2,
    position: 'absolute',
  },
}))

export default styles
