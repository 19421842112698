import jwtDecode from 'jwt-decode'
import _ from 'lodash'

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}

export const stringIsNumeric = (value) => {
  return /^-?\d+$/.test(value)
}

export const stringIsValidPersonName = (value) => {
  return /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/.test(value)
}

export const stringIsValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const removeDocumentSymbols = (value) => {
  return value?.replace(/[.-]/g, '')
}

export const extractOnlyNumbers = (document) => {
  return document?.replace(/[^\d]/g, '')
}

export const sortObject = (obj, compareFunction) => {
  return Object.keys(obj).sort(compareFunction).reduce(
    (val, key) => {
      return { [key]: val }
    },
    {},
  )
}

export const parseSearchTerm = (searchTerm) => {
  const termWithoutSymbols = removeDocumentSymbols(searchTerm)

  if (!termWithoutSymbols) {
    return { document: null, name: null }
  }

  if (stringIsNumeric(termWithoutSymbols)) {
    return { document: termWithoutSymbols, name: null }
  }

  return { document: null, name: termWithoutSymbols }
}

export const isUserLogged = token => {
  if (_.isEmpty(token)) return false

  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const sleep = m => new Promise(r => setTimeout(r, m))

export const isPasswordValid = (value) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(value)
}

export const getExtensionFileName = (fileName) => {
  const ext = fileName.split('.').pop()
  return ext.split(/[^0-9a-z-_]/i)[0]
}

export const objToQueryString = (objQueryString) => {
  return _
    .chain(objQueryString)
    .pickBy((query) => !(_.isNil(query) || query === ''))
    .map((v, k) => `${k}=${v}`)
    .join('&')
    .value()
}

export const translateBoolean = (bool) => {
  switch (bool) {
    case true: return 'SIM'
    case false: return 'NAO'
    default: return bool
  }
}

export const hasMsgInfo = (data, status = null) => {
  if (_.isEmpty(data.msg_infos)) {
    return false
  }
  if (status == null) {
    return true
  }

  return _.some(data.msg_infos, (msg) => msg.status === status)
}

export const inputCountAsString = (inputCount) => {
  const count = inputCount || {}

  const cpf = count.cpf || 0
  const cnpj = count.cnpj || 0
  const name = count.name || 0

  const items = []

  if (cpf === 1) {
    items.push('1 CPF')
  }
  else if (cpf > 1) {
    items.push(`${cpf} CPFs`)
  }

  if (cnpj === 1) {
    items.push('1 CNPJ')
  }
  else if (cnpj > 1) {
    items.push(`${cnpj} CNPJs`)
  }
  if (name === 1) {
    items.push(`1 nome`)
  }
  else if (name > 1) {
    items.push(`${name} nomes`)
  }

  return items.join(', ').replace(/, ([^,]*)$/, ' e $1')
}

export const capitalizeFirstLetter = (text) => {
  if (_.isEmpty(text)) {
    return text
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}
