import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { formatDocument } from 'utils/masks'
import { DISABLED_COLOR } from 'utils/constants/colors'
import { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { BR_DATE_TIME_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import {
  lastExecutionStatusLabel,
  resultFilterLabel,
  resultFilterType,
  statusFilterLabel,
  statusFilterType,
} from 'utils/constants/peopleMonitoring'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { inputCountAsString } from 'utils/functions'
import { Pagination } from '@material-ui/lab'
import _ from 'lodash'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import MUIIcon from '../Icon/MUIIcon'
import { Button as TextButton, Table } from '..'
import UpdateMonitoringDialog from '../UpdateMonitoringDialog'

export default function MonitoredInputsPanel({
  monitoringData,
  onReturnFromMonitoringInfo,
  onDisableSingleInput,
  updateNoticesPage,
  onUpdateMonitoringData,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState(statusFilterType.all)
  const [resultFilter, setResultFilter] = useState(resultFilterType.all)
  const [addInputsDialogOpen, setAddInputsDialogOpen] = useState(false)
  const [page, setPage] = useState(1)

  const TABLE_PAGE_SIZE = 10

  const handleClickInput = (item) => {
    updateNoticesPage({ person_id: item.person_id, request_id: monitoringData.request_id })
  }
  const handleChangeStatusFilter = (event) => {
    setStatusFilter(event.target.value)
  }
  const handleChangeResultFilter = (event) => {
    setResultFilter(event.target.value)
  }

  const useStyles = makeStyles(() => ({
    textField: {
      fontSize: '1.3rem',
    },
    consultsControlsBar: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    monitroringControlsBar: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'end',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
  }))

  const classes = useStyles()

  const renderInputs = () => {
    const filterInputsByStatus = (docs) => {
      if (statusFilter === statusFilterType.all) return docs

      const activeFilter = statusFilter === statusFilterType.active
      return docs.filter((d) => d.active === activeFilter)
    }

    const filterInputsByResult = (docs) => {
      if (resultFilter === resultFilterType.all) return docs

      const reprovedFilter = resultFilter === resultFilterType.reproved
      return docs.filter((d) => d.consists === reprovedFilter)
    }

    const filterInputs = () => {
      const casedSearchTerm = searchTerm.toUpperCase()
      let docs = monitoringData.inputs.filter(
        (d) => d.input?.toUpperCase()?.includes(casedSearchTerm) || d.person_name?.toUpperCase().includes(casedSearchTerm),
      )
      docs = filterInputsByStatus(docs)
      return filterInputsByResult(docs)
    }

    const paginateInputs = (filteredInputs) =>
      filteredInputs.slice((page - 1) * TABLE_PAGE_SIZE, page * TABLE_PAGE_SIZE)

    const filteredInputs = filterInputs()

    const renderResult = (d) => {
      const monitoringHasExecuted = !monitoringData.monitoringNeverExecuted
      const documentHasExecuted = d.last_execution_at
      let iconName = 'waiting'
      if (monitoringHasExecuted) {
        if (!documentHasExecuted || d.consists === null) {
          iconName = 'empty'
        }
        else {
          iconName =  d.consists ? 'reproved' : 'approved'
        }
      }
      return <MUIIcon color={!monitoringData.active ? DISABLED_COLOR : null} type={iconName} />
    }

    const renderSocialMediaResult = (d) => {
      const monitoringHasExecuted = !monitoringData.monitoringNeverExecuted
      const documentHasExecuted = d.last_execution_at

      let iconName = 'waiting'
      if (monitoringHasExecuted) {
        if (!documentHasExecuted || d.social_media_consists === null) {
          iconName = 'empty'
        }
        else {
          iconName =  d.social_media_consists ? 'reproved' : 'approved'
        }
      }
      return <MUIIcon color={!monitoringData.active ? DISABLED_COLOR : null} type={iconName} />
    }

    const renderPersonName = (d) => {
      if (d.type === 'name') {
        return d.input?.toUpperCase()
      }
      return d.person_name || <MUIIcon type="empty" />
    }

    const renderType = (d) => {
      if (d.type === 'name') {
        return 'NOME'
      }
      return d.type?.toUpperCase() || <MUIIcon type="empty" />
    }

    const renderProfileLink = (d) => {
      const personExists = !_.isEmpty(d.person_id)
      return (
        <Tooltip
          title={(
            <h3>
              {personExists
                ? 'Abrir perfil no banco de pessoas'
                : 'O perfil ainda não foi registrado no banco de pessoas.'}
            </h3>
          )}
        >
          <span>
            {personExists ? (
              <Link
                style={{ color: 'inherit' }}
                target="_blank"
                rel="noopener noreferrer"
                to={`${ROUTE_CONSTANTS.PEOPLE_DATABASE}/${d.person_id}`}
              >
                <MUIIcon type="person" />
              </Link>
            ) : (
              <MUIIcon type="empty" />
            )}
          </span>
        </Tooltip>
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '45vw' }}>
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <Table className="table">
            <thead>
              <tr>
                <th style={{ width: '10vw' }}>Adicionado em</th>
                <th style={{ width: '1vw' }}>Perfil</th>
                <th style={{ width: '30vw' }}>Nome</th>
                <th style={{ width: '25vw' }}>Documento</th>
                <th style={{ width: '3vw' }}>Tipo</th>
                <th style={{ width: '10vw' }}>Última execução em</th>
                <th style={{ width: '1vw' }}>Notícias</th>
                <th style={{ width: '1vw' }}>Mídias Sociais</th>
                <th style={{ width: '1vw' }}>Ativo?</th>
              </tr>
            </thead>

            <tbody>
              {paginateInputs(filteredInputs).map((d) => (
                <tr style={{ cursor: 'pointer' }} onClick={() => handleClickInput(d)} key={d.input}>
                  <td style={{ color: !monitoringData.active || !d.active ? DISABLED_COLOR : null }}>
                    {d.inserted_at}
                  </td>
                  <td style={{ color: !monitoringData.active || !d.active ? DISABLED_COLOR : null }}>
                    {renderProfileLink(d)}
                  </td>
                  <td style={{ color: !monitoringData.active || !d.active ? DISABLED_COLOR : null }}>
                    {renderPersonName(d)}
                  </td>
                  <td style={{ color: !monitoringData.active ? DISABLED_COLOR : null }}>
                    {formatDocument(d.input, d.type) || <MUIIcon type="empty" />}
                  </td>
                  <td style={{ color: !monitoringData.active || !d.active ? DISABLED_COLOR : null }}>
                    {renderType(d)}
                  </td>
                  <td style={{ color: !monitoringData.active || !d.active ? DISABLED_COLOR : null }}>
                    {d.last_execution_at || <MUIIcon type="empty" />}
                  </td>
                  <td>{renderResult(d)}</td>
                  <td>{renderSocialMediaResult(d)}</td>
                  <td>
                    <Tooltip
                      title={
                        !monitoringData.active ? (
                          ''
                        ) : (
                          <h3>{d.active ? 'Pausar monitoramento da pessoa' : 'Voltar a monitorar a pessoa'}</h3>
                        )
                      }
                    >
                      <span>
                        <Button
                          disabled={!monitoringData.active}
                          onClick={() => onDisableSingleInput({ input: d.input, active: !d.active })}
                        >
                          <MUIIcon
                            type={d.active ? 'eye' : 'eyeOff'}
                            color={!monitoringData.active ? DISABLED_COLOR : null}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          count={Math.ceil(filteredInputs.length / TABLE_PAGE_SIZE)}
          page={page}
          onChange={(_event, number) => setPage(number)}
        />
      </div>
    )
  }

  const handleDisableMonitoring = () => {
    onUpdateMonitoringData({ active: !monitoringData.active })
  }

  const handleClickAddInputs = () => {
    setAddInputsDialogOpen(true)
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const showControls = () => {
    const showStatusFilterMenuItem = ({ filter, iconName }) => (
      <MenuItem value={filter}>
        {iconName && (
          <span style={{ paddingTop: 5, paddingRight: 2 }}>
            <MUIIcon type={iconName} />
          </span>
        )}
        {statusFilterLabel[filter]}
      </MenuItem>
    )

    const showResultFilterMenuItem = ({ filter, iconName }) => (
      <MenuItem value={filter}>
        {iconName && (
          <span style={{ paddingTop: 5, paddingRight: 2 }}>
            <MUIIcon type={iconName} />
          </span>
        )}
        {resultFilterLabel[filter]}
      </MenuItem>
    )

    const showStatusFilteringControl = () => (
      <FormControl>
        <FormHelperText id="filter-select">Status:</FormHelperText>
        <Select
          labelId="filter-select"
          id="demo-simple-select-filled"
          value={statusFilter}
          onChange={handleChangeStatusFilter}
          renderValue={(value) => statusFilterLabel[value]}
          className={classes.textField}
        >
          {showStatusFilterMenuItem({ filter: statusFilterType.all })}
          {showStatusFilterMenuItem({ filter: statusFilterType.active, iconName: 'eye' })}
          {showStatusFilterMenuItem({ filter: statusFilterType.disabled, iconName: 'eyeOff' })}
        </Select>
      </FormControl>
    )

    const showResultFilteringControl = () => (
      <FormControl>
        <FormHelperText id="filter-select">Resultado:</FormHelperText>
        <Select
          labelId="filter-select"
          id="demo-simple-select-filled"
          value={resultFilter}
          onChange={handleChangeResultFilter}
          renderValue={(value) => resultFilterLabel[value]}
          className={classes.textField}
        >
          {showResultFilterMenuItem({ filter: resultFilterType.all })}
          {showResultFilterMenuItem({ filter: resultFilterType.approved, iconName: 'approved' })}
          {showResultFilterMenuItem({ filter: resultFilterType.reproved, iconName: 'reproved' })}
        </Select>
      </FormControl>
    )

    const showInputFilteringControl = () => (
      <FormControl>
        <FormHelperText>Filtrar pessoas:</FormHelperText>
        <TextField
          style={{ width: '10.5vw', paddingRight: 10 }}
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          placeholder="Número ou nome"
          InputProps={{
            className: classes.textField,
          }}
        />
      </FormControl>
    )

    const showClearFiltersButton = (disableButton) => {
      const clearMonitoringFilters = () => {
        setStatusFilter(statusFilterType.all)
        setResultFilter(resultFilterType.all)
        setSearchTerm('')
      }

      const disabled =
        disableButton ||
        (statusFilter === statusFilterType.all && resultFilter === resultFilterType.all && searchTerm === '')
      return (
        <Tooltip arrow placement="left" title={<h3>Limpar filtragem de documentos</h3>}>
          <div>
            <TextButton disabled={disabled} onClick={clearMonitoringFilters} variant="text">
              Limpar
            </TextButton>
          </div>
        </Tooltip>
      )
    }

    const showMonitoringInfo = () => {
      const lastStatus = monitoringData.last_execution_status?.toLowerCase()
      return (
        <React.Fragment>
          <Grid container style={{ flexDirection: 'column', width: '100%' }}>
            <Grid item xs={6} style={{ borderTop: '1px solid #ddd' }}>
              <FormControl>
                <FormHelperText>Ultima Execução:</FormHelperText>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MUIIcon
                    fontSize="2.2rem"
                    color={lastExecutionStatusLabel[lastStatus].iconColor}
                    type={lastExecutionStatusLabel[lastStatus].iconName}
                  />
                  {lastStatus !== 'none' && (
                    <span style={{ marginLeft: 5, fontSize: '1.3rem' }}>
                      {lastExecutionStatusLabel[lastStatus]?.description.toUpperCase()}
                      <br />
                      Em:
                      {' '}
                      {dateToStr(monitoringData.last_execution_at, BR_DATE_TIME_FORMAT)}
                    </span>
                  )}
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={6} style={{ borderTop: '1px solid #ddd' }}>
              <FormControl>
                <FormHelperText>Inicio do Monitoramento:</FormHelperText>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginLeft: 5, fontSize: '1.3rem' }}>
                    {dateToStr(monitoringData.created_at, BR_DATE_TIME_FORMAT)}
                  </span>
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={6} style={{ borderTop: '1px solid #ddd' }}>
              <FormControl>
                <FormHelperText>Itens:</FormHelperText>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginLeft: 5, fontSize: '1.3rem' }}>
                    {inputCountAsString(monitoringData.input_count)}
                  </span>
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={6} style={{ borderTop: '1px solid #ddd' }}>
              <FormControl>
                <FormHelperText>Ativos:</FormHelperText>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginLeft: 5, fontSize: '1.3rem' }}>
                    {monitoringData.active_quantity}
                    {' '}
                    de
                    {' '}
                    {monitoringData.quantity}
                  </span>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div className={classes.consultsControlsBar}>
          <Button onClick={onReturnFromMonitoringInfo}>
            <ArrowBackIcon />
          </Button>

          <h3 style={{ paddingLeft: 20 }}>{monitoringData.description}</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={!monitoringData.active}
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleClickAddInputs}
              >
                <MUIIcon color={!monitoringData.active && DISABLED_COLOR} type="settings" />
                <span style={{ marginLeft: 5 }}>Configurar</span>
              </Button>
            </span>

            <span style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
              <Button size="small" variant="outlined" color="primary" onClick={handleDisableMonitoring}>
                <MUIIcon type={monitoringData.active ? 'eye' : 'eyeOff'} />
                <span style={{ marginLeft: 5 }}>{monitoringData.active ? 'Ativo' : 'Pausado'}</span>
              </Button>
            </span>
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <Accordion elevation={0}>
            <AccordionSummary
              style={{ paddingLeft: 1, borderTop: 'none' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <strong>Informações do Monitoramento</strong>
            </AccordionSummary>

            <AccordionDetails style={{ padding: 0 }}>{showMonitoringInfo()}</AccordionDetails>
          </Accordion>
        </div>

        <div style={{ marginTop: 10, marginBottom: 15 }} className={classes.monitroringControlsBar}>
          {showInputFilteringControl()}

          {showResultFilteringControl()}

          {showStatusFilteringControl(monitoringData.active)}

          {showClearFiltersButton()}
        </div>

        <Divider />
      </React.Fragment>
    )
  }

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {showControls()}
      <div style={{ height: '70vh', minWidth: '35vw', overflow: 'auto' }}>{renderInputs()}</div>
      <UpdateMonitoringDialog
        dialogOpen={addInputsDialogOpen}
        handleCloseDialog={() => setAddInputsDialogOpen(false)}
        monitoringData={monitoringData}
        onUpdateMonitoringData={onUpdateMonitoringData}
      />
    </Container>
  )
}
