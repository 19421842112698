import { Tooltip } from "@material-ui/core"
import React from "react"
import MUIIcon from "ui/components/Icon/MUIIcon"
import { groupModeNames } from "../itemsGrouper"


const GroupTitle = ({ itemDate, groupMode }) => {
  return (
    <span style={{ gap: 5, marginLeft: '0.5rem', display: 'flex', alignItems: 'start' }}>
      <MUIIcon fontSize='2.3rem' type={groupMode} />

      <Tooltip arrow title={<h4>{`Data de ${groupModeNames[groupMode]}`}</h4>}>
        <span>{itemDate}</span>
      </Tooltip>
    </span>
)
}

export default GroupTitle
