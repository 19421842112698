import * as React from 'react'
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from '@material-ui/core'
import _ from 'lodash'
import { useState, useEffect } from 'react'
import { Delete } from '@material-ui/icons'


const PersonOtherNames = ({ person, alternateNames, updateAlternateNames }) => {

  const [nameInput, setNameInput] = useState('')

  const initializeAlternateNames = (names) => {
    const dd = names.map((v) => {
      return { value: v, active: true }
    })
    return dd
  }


  const handleClickDeleteName = (name) => {
    updateAlternateNames([...alternateNames.filter((n) => n.value !== name.value), { value: name.value, active: false }])
  }

  const handleClickAddAlternateName = () => {
    if (_.isEmpty(nameInput)) {
      return
    }
    if (_.findIndex(alternateNames, (n) => n.value === nameInput && n.active) !== -1) {
      return
    }
    updateAlternateNames([ ...alternateNames.filter((n) => n.value !== nameInput), { value: nameInput, active: true }])
    setNameInput('')
  }




  useEffect (() => {
    const otherNames = _.isEmpty(person.nicknames) ? person.fantasy_names : person.nicknames
    updateAlternateNames(initializeAlternateNames(otherNames), false)
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const activeAlternateNames = alternateNames.filter((n) => n.active === true)
  return (
    <div style={{ marginTop: 20, marginBottom: 25 }}>
      <Typography variant="subtitle1">{person.person_type === 'cpf' ? 'Apelidos' : 'Nomes Fantasia'}</Typography>
      {_.isEmpty(activeAlternateNames) && (
        <Typography variant="subtitle2">
          {`Nenhum ${person.person_type === 'cpf' ? 'apelido' : 'nome fantasia'} registrado.`}
        </Typography>
      )}

      <div>
        <List>
          {_.map(activeAlternateNames, (name) => (
            <React.Fragment key={name.value}>
              <ListItem>
                <Typography>{name.value}</Typography>
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleClickDeleteName(name)} edge="end" aria-label="delete">
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
            ))}
        </List>
      </div>

      <TextField
        fullWidth={true}
        value={nameInput}
        placeholder='Digite um novo termo'
        onChange={(event) => setNameInput(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Button onClick={handleClickAddAlternateName} color="primary">
                Adicionar
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

export default PersonOtherNames
