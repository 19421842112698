import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  consultsControlsBar: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  controlsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  doneButtonIconUnMarked: {
    fontSize: '1.7rem',
    marginLeft: '0.5rem',
    paddingButton: '0.5rem',
  },
  personInfoDiv: {
    gap: 8,
    '& p': {
      fontSize: '1.3rem',
    },
    '& span': {
      fontSize: '1.3rem',
    },
  },
  listItem: {
    fontSize: '1.2rem',
  },
  addPersonButton: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(15), 
    transform: 'scale(1.2)',
  },
}))

export default styles
