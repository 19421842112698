import * as React from 'react'
import moment from 'moment'
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { BR_DATE_TIME_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import SearchIcon from '@material-ui/icons/Search'
import { useState, useEffect } from 'react'
import { Pagination, Skeleton } from '@material-ui/lab'
import _ from 'lodash'
import { DISABLED_COLOR, ENABLED_COLOR } from 'utils/constants/colors'
import { makeStyles } from '@material-ui/styles'
import { occurrencesFilterLabel, occurrencesFilterType } from 'utils/constants/peopleMonitoring'
import { Table } from '..'
import MUIIcon from '../Icon/MUIIcon'
import TextFieldDatePicker from '../TextFieldDatePicker'
import OccurrenceCountBadge from '../OccurrenceCountBadge'

export default function DocumentMonitoringsList({
  monitoringsData,
  isLoading,
  handleClickMonitoring,
  onConsultFilterChange,
  onUpdateMonitoringData,
}) {
  const [occurrencesFilter, setOccurrencesFilter] = useState(occurrencesFilterType.consists)
  const [initialDateFilter, setInitialDateFilter] = useState('')
  const [finalDateFilter, setFinalDateFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [consultFilters, setConsultFilters] = useState({
    page: 1,
    document: null,
    name: null,
    consists: true,
    active: null,
    initialDate: null,
    finalDate: null,
  })


  useEffect(() => {
    const parsePageParams = () => {
      const query = new URLSearchParams(window.location.search)
      const consists = query.get('consists')
      if (!consists) {
        return
      }
      const filter = {
        all: occurrencesFilterType.all,
        true: occurrencesFilterType.consists,
        false: occurrencesFilterType.notConsists,

      }
      if (!filter[consists]) {
        return
      }
      setOccurrencesFilter(filter[consists])

    }
    parsePageParams()

  }, [])
  /** When consultFiter object is rebuilt, fires onConsultFilterChange that will trigger a new API request. */
  useEffect(() => {
    onConsultFilterChange(consultFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultFilters])

  /** When click to search by a term (document or name), rebuilds the consultFiter object. */
  const handleClickSearchTerm = () => {
    setConsultFilters({ ...consultFilters, ...{ description: searchTerm, page: 1 } })
  }

  /** When consists filter is changed, rebuilds the consultFiter object. */
  useEffect(() => {
    const parseOccurrencesFilter = () => {
      switch (occurrencesFilter) {
        case occurrencesFilterType.all:
          return { consists: null }
        case occurrencesFilterType.consists:
          return { consists: true }
        case occurrencesFilterType.notConsists:
          return { consists: false }
        default:
          return {}
      }
    }
    const consistsObject = parseOccurrencesFilter()
    const filters = { ...consultFilters, ...consistsObject, ...{ description: searchTerm, page: 1 } }
    setConsultFilters(filters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrencesFilter])

  /** When date filters are changed, rebuilds the consultFiter object. */
  useEffect(() => {
    setConsultFilters({
      ...consultFilters,
      ...{ initialDate: initialDateFilter, finalDate: finalDateFilter, description: searchTerm, page: 1 },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateFilter, finalDateFilter])

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleChangeOccurrencesFilter = (event) => {
    setOccurrencesFilter(event.target.value)
  }

  const handleInitialDateFilterChange = (event) => {
    setInitialDateFilter(event.target.value)
  }

  const handleFinalDateFilterChange = (event) => {
    setFinalDateFilter(event.target.value)
  }

  const renderStatus = (m) => {
    switch (m) {
      case true:
        return <MUIIcon type="eye" />
      case false:
        return <MUIIcon type="eyeOff" />
      default:
        return null
    }
  }

  const renderMonitorings = () =>
    monitoringsData.items.map((m) => {
      const disableMonitoring = (e) => {
        // Avoiding parent <tr> onClick event
        e.stopPropagation()

        onUpdateMonitoringData({ active: !m.active, requestId: m.request_id, skipSetActiveMonitoring: true })

        // Forcing monitorings list to refresh rebuilding filters state
        setConsultFilters({ ...consultFilters })
      }

      const fontColor = m.active ? ENABLED_COLOR : DISABLED_COLOR
      return (
        <tr key={m.request_id} onClick={() => handleClickMonitoring(m)} style={{ cursor: 'pointer', color: fontColor }}>
          <td style={{ cursor: 'pointer', color: fontColor }}>{moment(m.created_at).format(BR_DATE_TIME_FORMAT)}</td>
          <td style={{ cursor: 'pointer', color: fontColor }}>
            {m.description?.toUpperCase() || <MUIIcon type="empty" />}
          </td>
          <td>
            <OccurrenceCountBadge count={m.open_notice_snippets} />
          </td>
          <td>
            <OccurrenceCountBadge count={m.open_social_media_snippets} />
          </td>
          <td style={{ cursor: 'pointer', color: fontColor }}>{m.quantity || <MUIIcon type="empty" />}</td>
          <td style={{ cursor: 'pointer', color: fontColor }}>
            {dateToStr(m?.last_execution_at, BR_DATE_TIME_FORMAT) || <MUIIcon type="empty" />}
          </td>
          <td style={{ cursor: 'pointer', color: fontColor }}>
            <Tooltip title={<h3>{m.active ? 'Pausar monitoramento' : 'Voltar a monitorar'}</h3>}>
              <Button onClick={disableMonitoring}>{renderStatus(m.active)}</Button>
            </Tooltip>
          </td>
        </tr>
      )
    })

  const renderNoMonitorings = () => (
    <tr>
      <td colSpan={6}>
        Nenhum monitoramento encontrado.
        <br />
        Ajustes os filtros de busca ou crie um novo monitoramento agora mesmo!
      </td>
    </tr>
  )

  const renderLoading = () =>
    [...Array(monitoringsData?.items?.length)].map(() => (
      <tr key={`skeleton${Math.random()}`}>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
        <td>
          <Skeleton height={27} />
        </td>
      </tr>
    ))

  const showOccurrencesFilterMenuItem = ({ filter, iconName }) => (
    <MenuItem value={filter}>
      {iconName && (
        <span style={{ paddingTop: 5, paddingRight: 2 }}>
          <MUIIcon type={iconName} />
        </span>
      )}
      {occurrencesFilterLabel[filter]}
    </MenuItem>
  )

  const useStyles = makeStyles(() => ({
    textField: {
      fontSize: '1.3rem',
    },
    consultsControlsBar: {
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
  }))

  const classes = useStyles()

  const showControls = () => {
    const showDateFilteringControls = () => (
      <span>
        <TextFieldDatePicker
          value={initialDateFilter}
          style={{ marginRight: 10 }}
          label="De:"
          maxDate={finalDateFilter || new Date()}
          onChange={handleInitialDateFilterChange}
          InputProps={{
            className: classes.textField,
          }}
        />

        <TextFieldDatePicker
          value={finalDateFilter}
          label="Até:"
          maxDate={new Date()}
          minDate={initialDateFilter}
          onChange={handleFinalDateFilterChange}
          InputProps={{
            className: classes.textField,
          }}
        />
      </span>
    )

    const showTermSearchControl = () => (
      <span style={{ display: 'flex', alignItems: 'end' }}>
        <FormControl>
          <FormHelperText>Filtrar monitoramentos por:</FormHelperText>
          <TextField
            style={{ width: '10.5vw' }}
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            placeholder="Descrição"
            InputProps={{
              className: classes.textField,
            }}
          />
        </FormControl>

        <IconButton style={{ padding: '0px 10px' }} onClick={handleClickSearchTerm}>
          <SearchIcon style={{ verticalAlign: 'center' }} />
        </IconButton>
      </span>
    )
    const showOccurrencesFilteringControl = () => (
      <FormControl>
        <FormHelperText id="filter-select">Ocorrências:</FormHelperText>
        <Select
          labelId="filter-select"
          id="demo-simple-select-filled"
          value={occurrencesFilter}
          onChange={handleChangeOccurrencesFilter}
          renderValue={(value) => occurrencesFilterLabel[value]}
          className={classes.textField}
        >
          {showOccurrencesFilterMenuItem({ filter: occurrencesFilterType.all })}
          {showOccurrencesFilterMenuItem({ filter: occurrencesFilterType.consists, iconName: 'reproved' })}
          {showOccurrencesFilterMenuItem({
            filter: occurrencesFilterType.notConsists,
            iconName: 'monitoringDoesNotConsists',
          })}
        </Select>
      </FormControl>
    )

    return (
      <div className={classes.consultsControlsBar}>
        {showTermSearchControl()}

        {showDateFilteringControls()}

        {showOccurrencesFilteringControl()}
      </div>
    )
  }

  const handleChangePage = async (event, page) => setConsultFilters({ ...consultFilters, page })

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {showControls()}
      <div style={{ height: '70vh', maxWidth: '40vw', overflow: 'auto' }}>
        <Table style={{ fontSize: '1rem' }} className="table">
          <thead>
            <tr>
              <th style={{ width: '10vw' }}>Inicio do Monitoramento</th>
              <th style={{ width: '23vw' }}>Descrição</th>
              <th>Ocorrências (Notícias)</th>
              <th>Ocorrências (M. Sociais)</th>
              <th>Pessoas Monitoradas</th>
              <th style={{ width: '11vw' }}>Última Execução</th>
              <th>Ativo?</th>
            </tr>
          </thead>

          <tbody>
            {isLoading
              ? renderLoading()
              : !_.isEmpty(monitoringsData.items)
              ? renderMonitorings()
              : renderNoMonitorings()}
          </tbody>
        </Table>
      </div>

      <Pagination count={monitoringsData.totalPages} page={monitoringsData.currentPage} onChange={handleChangePage} />
    </Container>
  )
}
