import React from 'react'
import { useTheme } from '@material-ui/styles'

import RemoveIcon from '@material-ui/icons/Remove'
import { AccessTime, ArrowDownward, ArrowUpward, AssignmentInd, BusinessCenterOutlined, Clear, Help, HelpOutlineOutlined, Language, LibraryBooks, LibraryBooksOutlined, PeopleAlt, Person, PersonOutline, Settings, SettingsBackupRestore, SupervisedUserCircleOutlined, Timer, Web } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import CancelIcon from '@material-ui/icons/Cancel'
import EventIcon from '@material-ui/icons/Event'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

export default ({ type, color, fontSize = null, style = {}, className = null }) => {
  const theme = useTheme()

  switch (type) {
    case 'approved':
      return <CheckCircleIcon className={className} style={{ fontSize: fontSize, color: color || 'green', ...style }} />
    case 'reproved':
      return <CancelIcon className={className} style={{ fontSize: fontSize, color: color || 'red', ...style }} />
    case 'monitoringDoesNotConsists':
      return <CheckCircleIcon className={className} style={{ fontSize: fontSize, color: color || '#bbb', ...style }} />
    case 'warning':
      return <ErrorIcon className={className} style={{ fontSize: fontSize, color: color || '#f4c109', ...style }} />
    case 'empty':
      return <RemoveIcon className={className} style={{ fontSize: fontSize, color: color || '#dddddd', ...style }} />
    case 'waiting':
      return <AccessTime className={className} style={{ fontSize: fontSize, color: color || '#dddddd', ...style }} />
    case 'eye':
      return <VisibilityIcon className={className} style={{ fontSize: fontSize, color: color || theme.palette.primary.main, ...style }} />
    case 'eyeOff':
      return <VisibilityOffOutlinedIcon className={className} style={{ fontSize: fontSize, color: color || '#f299b2', ...style }} />
    case 'addPeople':
      return <GroupAddIcon className={className} style={{ fontSize: fontSize, color: color || theme.palette.primary.main, ...style }} />
    case 'date-picker':
      return <EventIcon className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'invalid':
      return <ErrorOutlineIcon className={className} style={{ fontSize: fontSize, color: color || '#f4c109', ...style }} />
    case 'valid':
      return <CheckOutlinedIcon className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'delete':
      return <DeleteIcon className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'copy':
        return <FileCopyIcon className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'exit':
      return <ExitToAppIcon className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'unknowPerson':
      return <HelpOutlineOutlined className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'cpf':
      return <PersonOutline className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'cnpj':
      return <BusinessCenterOutlined className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'negativeMedia':
        return <Web className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'socialMedia':
      return <Language className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'help':
      return <Help className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'settings':
        return <Settings className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'person':
      return <Person className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'publishedDate':
        return <LibraryBooksOutlined className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'createdAt':
      return <Timer className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'peopleDatabase':
      return <AssignmentInd className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'keywordsMonitoring':
        return <LibraryBooks className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'peopleMonitoring':
        return <PeopleAlt className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'userManagement':
          return <SupervisedUserCircleOutlined className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'disableUser':
      return <Clear className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'restoreUser':
      return <SettingsBackupRestore className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'promoteUser':
        return <ArrowUpward className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    case 'reduceUser':
      return <ArrowDownward className={className} style={{ fontSize: fontSize, color: color, ...style }} />
    default: return null
  }
}
