import { Avatar } from '@material-ui/core'
import * as React from 'react'

/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
const getProfileColor = (name) => {
  /** Generates a hash code with the public domain cyrb53 algorithm. */
  const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed
    let h2 = 0x41c6ce57 ^ seed
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      h1 = Math.imul(h1 ^ ch, 2654435761)
      h2 = Math.imul(h2 ^ ch, 1597334677)
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

    return 4294967296 * (2097151 & h2) + (h1 >>> 0)
  }

  const colors = [
    '#B03A2E',
    '#639633',
    '#FF9999',
    '#bd00b0',
    '#5211b1',
    '#cc2ebb',
    '#117864',
    '#4fdca0',
    '#af6ea0',
    '#1A5276',
    '#7B241C',
    '#bb05a5',
    '#663396',
    '#054ff1',
    '#2eafe0',
    '#bfbe2e',
    '#aF2eE2',
    '#f75a36',
    '#1E8449',
    '#AF601A',
    '#7E5109',
  ]
  if (!name) {
    return null
  }
  const index = cyrb53(name) % colors.length
  return colors[index]
}

const personAvatar = (name, style) => {
  return (
    <Avatar style={{ color: '#FFF', backgroundColor: getProfileColor(name), ...style }}>{name ? name[0] : '?'}</Avatar>
  )
}

export const personAvatarForChip = (name) => {
  return personAvatar(name)
}

const PersonAvatar = ({ name, style = {} }) => personAvatar(name, style)

export default PersonAvatar
