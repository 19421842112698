const ROUTE_CONSTANTS = {
  HOME: '/consults',
  PEOPLE_MONITORING: '/monitoring/people',
  PEOPLE_MONITORING_REQUEST: '/monitoring/people/:request_id',
  SETTINGS: '/settings',
  PEOPLE_DATABASE: '/people',
  PEOPLE_DATABASE_PERSON: '/people/:id',
}
export {
  ROUTE_CONSTANTS,
}
