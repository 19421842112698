import * as React from 'react'
import { Badge, Paper, Tab, Tabs } from '@material-ui/core'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import styles from './styles'
import NoticesList from './NoticesList'
import SocialMediaPostsList from './SocialMediaPostsList'
import FilteringControls from './FilteringControls'
import { groupModes } from './itemsGrouper'

const NoticesAndPostsPanel = ({
  noticesData,
  socialMediaPostsData,
  isNoticesLoading,
  isSocialMediaPostsLoading,
  onNoticesFilterChange,
  onUpdateNoticesData,
  onUpdatePostsData,
  disabled = false,
}) => {
  const [noticesFilters, setNoticesFilters] = useState({
    olderFirst: false,
    initialDate: null,
    finalDate: null,
  })
  const [initialDateFilter, setInitialDateFilter] = useState('')
  const [finalDateFilter, setFinalDateFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [hideReviewed, setHideReviewed] = useState(true)
  const [olderFirst, setOlderFirst] = useState(false)
  const [groupMode, setGroupMode] = useState(groupModes.createdAt)
  const [reviewButtonsVisibility, setReviewButtonsVisibility] = useState({})

  /** When consultFilter object is rebuilt, fires onNoticesFilterChange that will trigger a new API request. */
  useEffect(() => {
    onNoticesFilterChange(noticesFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticesFilters])

  /** When date filters are changed, rebuilds the noticesFilters object. */
  useEffect(() => {
    setNoticesFilters({
      ...noticesFilters,
      ...{ initialDate: initialDateFilter, finalDate: finalDateFilter },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateFilter, finalDateFilter])

  /** When olderFirst are changed, rebuilds the noticesFilters object. */
  useEffect(() => {
    setNoticesFilters({ ...noticesFilters, ...{ olderFirst: olderFirst } })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [olderFirst])

  const handleInitialDateFilterChange = (event) => {
    setInitialDateFilter(event.target.value)
  }

  const handleFinalDateFilterChange = (event) => {
    setFinalDateFilter(event.target.value)
  }

  const handleChangeHideReviewed = () => {
    setHideReviewed(!hideReviewed)
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleChangeGroupMode = () => {
    setGroupMode(groupMode === groupModes.createdAt ? groupModes.publishedDate : groupModes.createdAt)
  }

  const onUpdateNotice = async (notice, review) => {
    const noticeIndex = noticesData.items.findIndex((n) => n.link === notice.link)
    if (noticeIndex === -1) {
      return null
    }
    const noticesDataCopy = { ...noticesData }
    const noticeCopy = { ...noticesData.items[noticeIndex] }

    noticeCopy.snippet.review = { ...review }

    noticesDataCopy.items[noticeIndex] = noticeCopy

    onUpdateNoticesData(noticesDataCopy)

    return null
  }

  const onUpdatePost = async (post, review) => {
    const postIndex = socialMediaPostsData.items.findIndex((n) => n.link === post.link)
    if (postIndex === -1) {
      return null
    }
    const postsDataCopy = { ...socialMediaPostsData }
    const postCopy = { ...socialMediaPostsData.items[postIndex] }

    postCopy.snippet.review = { ...review }

    postsDataCopy.items[postIndex] = postCopy

    onUpdatePostsData(postsDataCopy)

    return null
  }

  const clearNoticeFilters = () => {
    setInitialDateFilter('')
    setFinalDateFilter('')
    setSearchTerm('')
  }

  const toggleReviewButtonVisible = (index, visibility) => {
    setReviewButtonsVisibility({ ...reviewButtonsVisibility, [index]: visibility })
  }
  const toggleOlderFirst = () => setOlderFirst(!olderFirst)

  const tabValues = {
    negativeMedia: 'negativeMedia',
    socialMedia: 'socialMedia',
  }
  const [tabValue, setTabValue] = useState(tabValues.negativeMedia)

  const classes = styles()
  return (
    <span
      syle={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <FilteringControls
        consultType={tabValue}
        disabled={disabled}
        olderFirst={olderFirst}
        searchTerm={searchTerm}
        initialDateFilter={initialDateFilter}
        finalDateFilter={finalDateFilter}
        hideReviewed={hideReviewed}
        groupMode={groupMode}
        handleInitialDateFilterChange={handleInitialDateFilterChange}
        handleFinalDateFilterChange={handleFinalDateFilterChange}
        handleChangeSearchTerm={handleChangeSearchTerm}
        handleChangeOlderFirst={toggleOlderFirst}
        handleChangeHideReviewed={handleChangeHideReviewed}
        handleChangeGroupMode={handleChangeGroupMode}
        clearNoticeFilters={clearNoticeFilters}
      />

      <Paper style={{ width: '40vw', height: '75vh', overflowY: disabled ? null : 'auto', overflowX: 'clip' }}>
        <Tabs
          classes={{ root: classes.tabRoot }}
          onChange={(__, value) => setTabValue(value)}
          value={tabValue}
          style={{ backgroundColor: '#fff' }}
        >
          <Tab
            classes={{ root: classes.tabRoot }}
            disabled={disabled}
            label={(
              <Badge
                className={classes.tabBadge}
                badgeContent={_.filter(noticesData.items, (notice) => _.isEmpty(notice.snippet?.review)).length}
                color="primary"
              >
                Notícias
              </Badge>
              )}
            value={tabValues.negativeMedia}
          />
          <Tab
            classes={{ root: classes.tabRoot }}
            disabled={disabled}
            label={(
              <Badge
                className={classes.tabBadge}
                badgeContent={_.filter(socialMediaPostsData?.items, (post) => _.isEmpty(post?.snippet?.review)).length}
                color="primary"
              >
                Mídias Sociais
              </Badge>
              )}
            value={tabValues.socialMedia}
          />
        </Tabs>
        <div style={{ height: '90%' }}>
          {tabValue === tabValues.negativeMedia && (
          <NoticesList
            noticesData={noticesData}
            searchTerm={searchTerm}
            isLoading={isNoticesLoading}
            hideReviewed={hideReviewed}
            groupMode={groupMode}
            reviewButtonsVisibility={reviewButtonsVisibility}
            onUpdateNotice={onUpdateNotice}
            toggleReviewButtonVisible={toggleReviewButtonVisible}
            sortOrder={olderFirst ? 'asc' : 'desc'}
          />
            )}
          {tabValue === tabValues.socialMedia && (
          <SocialMediaPostsList
            postsData={socialMediaPostsData}
            searchTerm={searchTerm}
            isLoading={isSocialMediaPostsLoading}
            hideReviewed={hideReviewed}
            groupMode={groupMode}
            reviewButtonsVisibility={reviewButtonsVisibility}
            onUpdatePost={onUpdatePost}
            toggleReviewButtonVisible={toggleReviewButtonVisible}
            sortOrder={olderFirst ? 'asc' : 'desc'}
          />
            )}
        </div>
      </Paper>
    </span>
  )
}
export default NoticesAndPostsPanel
