import { BR_DATE_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import { extractOnlyNumbers, objToQueryString } from 'utils/functions'
import axios from './axiosBase'

const HOST = process.env.REACT_APP_API


export async function postCreateConsult(apiKey, userId, params = {}) {
  const data = {
    user_id: userId,
    name: params.name,
    cpf: extractOnlyNumbers(params.cpf),
    cnpj: extractOnlyNumbers(params.cnpj),
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    social_networks: params.socialNetworkInputs,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/consult`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function getConsult(apiKey, userId, requestId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    request_id: requestId,
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    older_first: params.olderFirst,
    page: params.page,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/consult?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getSocialMediaConsult(apiKey, userId, requestId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    request_id: requestId,
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    older_first: params.olderFirst,
    page: params.page,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/social-media/consult?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getSocialMediaPosts(apiKey, userId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    person_id: params.personId,
    older_first: params.olderFirst,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/social-media/posts?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getNotices(apiKey, userId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    person_id: params.personId,
    older_first: params.olderFirst,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/notices?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getConsults(apiKey, userId, params = {}) {

  const queryString = objToQueryString({
    user_id: userId,
    initial_date: dateToStr(params.initialDate, BR_DATE_FORMAT),
    final_date: dateToStr(params.finalDate, BR_DATE_FORMAT),
    name: params.name,
    people_batch_request_id: params.peopleBatchRequestId,
    document: params.document,
    status: params.status,
    consists: params.consists,
    exclude_monitorings: params.excludeMonitorings,
    page: params.page,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/consult/list?${queryString}`,
    headers: {
      'x-api-key': apiKey,
    },
  })

  return res
}

export async function getPlan(apiKey, userId) {
  const queryString = objToQueryString({
    user_id: userId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/plan?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
  })

  return res
}

export async function getSocialMediaPlan(apiKey, userId) {
  const queryString = objToQueryString({
    user_id: userId,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/social-media-plan?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
  })

  return res
}

export async function getPeople(apiKey, userId, params) {
  const { personId, document, name, personType, page } = params
  const queryString = objToQueryString({
    user_id: userId,
    person_id: personId,
    document: document,
    name: name,
    person_type: personType,
    page: page,
    per_page: 12,
  })

  const res = await axios({
    method: 'GET',
    url: `${HOST}/person?${queryString}`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
  })

  return res
}


export async function postConfigurePlan(apiKey, userId, keywords, useDefaultPlan) {
  const data = {
    user_id: userId,
    keywords: keywords,
    use_default_plan: useDefaultPlan,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/plan/configure`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function postSocialMediaConfigurePlan(apiKey, userId, keywords, useDefaultPlan) {
  const data = {
    user_id: userId,
    words: keywords,
    use_default_plan: useDefaultPlan,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/social-media-plan/configure`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function postReviewNotice(apiKey, userId, noticeId, snippetId, decision, comment) {
  const data = {
    user_id: userId,
    notice_id: noticeId,
    snippet_id: snippetId,
    decision: decision,
    comment: comment,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/review-notice`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function postReviewSocialMediaPost(apiKey, userId, postId, snippetId, decision, comment) {
  const data = {
    user_id: userId,
    post_id: postId,
    snippet_id: snippetId,
    decision: decision,
    comment: comment,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/social-media/review-post`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}

export async function postCreatePerson(apiKey, userId, params) {
  const data = {
    user_id: userId,
    name: params.name,
    person_type: params.personType,
    document: params.document,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/person`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}


export async function patchUpdatePerson(apiKey, userId, personId, params) {
  const data = {
    user_id: userId,
    person_id: personId,
    fantasy_names: params.fantasy_names,
    nicknames: params.nicknames,
    social_networks: params.socialNetworks,
  }

  const res = await axios({
    method: 'PATCH',
    url: `${HOST}/person`,
    headers: {
      'x-api-key': apiKey,
      'Content-type': 'application/json',
    },
    data: data,
  })

  return res
}
