import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import PublishIcon from '@material-ui/icons/Publish'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import 'date-fns'
import _ from 'lodash'
import { peopleMonitoringFormTypes } from 'utils/constants/peopleMonitoring'
import { identifyAndFormatInput } from 'utils/masks'
import DocumentMonitoringConfirmation from './PeopleMonitoringConfirmation'
import PeopleMonitoringTooltip from '../PeopleMonitoringTooltip'

const DocumentMonitoringForm = ({
  formType,
  onConfirmRequest,
  handleCloseDialog,
  isApiLoading = null,
  existingDocuments = null,
  hideSocialMediaSearchCheckBox = false,
}) => {
  const DESCRIPTION_MIN_LEGTH = 3

  const [description, setDescription] = useState('')
  const [inputsTextField, setDocumentsTextField] = useState('')
  const [inputs, setDocuments] = useState([])
  const [validCpfs, setValidCpfs] = useState(new Set([]))
  const [validCnpjs, setValidCnpjs] = useState(new Set([]))
  const [validNames, setValidNames] = useState(new Set([]))
  const [searchInSocialMedia, setSearchInSocialMedia] = useState(false)
  const [showingForm, setShowingForm] = useState(true)
  const [inputByFile, setInputByFile] = useState(false)
  const [file, setFile] = useState(null)
  const [fileContent, setFileContent] = useState(null)
  const [loadingFile, setLoadingFile] = useState(false)

  const inputFileRef = useRef(null)

  const isInputAlreadyBeingMonitored = (input) => {
    if (_.isEmpty(existingDocuments)) {
      return false
    }
    return existingDocuments.some((d) => d.input === input)
  }

  const parseInputs = (inputsList) => {
    const splittedInputs = !_.isEmpty(inputsList) ? inputsList.split('\n') : []
    let inputsTreated = _.map(splittedInputs, (x) => x.trim())
    inputsTreated = _.reject(inputsTreated, (x) => _.isEmpty(x))

    const cpfs = []
    const cnpjs = []
    const names = []
    const documentObjects = []
    const inputsProcessed = {}

    inputsTreated.forEach((input) => {
      const inputData = identifyAndFormatInput(input)
      const formattedInput = inputData?.value
      const inputType = inputData?.type
      const isValid = !_.isEmpty(inputData)
      const isRepeated = inputsProcessed[formattedInput] === true || isInputAlreadyBeingMonitored(formattedInput)

      documentObjects.push({
        key: `${input}-${documentObjects.length}`,
        raw: input,
        input: formattedInput,
        inputType: inputType,
        isRepeated: isRepeated,
        isValid: isValid,
      })
      inputsProcessed[formattedInput] = true

      if (!isValid || isRepeated) {
        return
      }

      switch (inputType) {
        case 'cpf':
          cpfs.push(formattedInput)
          break
        case 'cnpj':
          cnpjs.push(formattedInput)
          break
        case 'nome':
          names.push(formattedInput)
          break
        default:
          break
      }
    })

    setDocuments(documentObjects)
    setValidCpfs(new Set([...cpfs]))
    setValidCnpjs(new Set([...cnpjs]))
    setValidNames(new Set([...names]))
    setLoadingFile(false)
  }

  useEffect(() => {
    if (!fileContent) {
      return
    }

    parseInputs(fileContent)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileContent])

  useEffect(() => {
    const readFile = () => {
      if (!file) {
        return
      }

      const reader = new FileReader()
      reader.onload = (event) => {
        setFileContent(event.target.result)
      }

      reader.readAsText(file)
    }
    readFile()
  }, [file])

  const handleChangeDescription = (event) => {
    setDescription(event.target.value)
  }

  const handleChangeDocuments = (event) => {
    const text = event.target.value
    setDocumentsTextField(text)
    parseInputs(text)
  }

  const handleChangeSearchInSocialMedia = (event) => {
    setSearchInSocialMedia(event.target.checked)
  }

  const renderDescriptionTextField = () =>
    formType === peopleMonitoringFormTypes.create && (
      <React.Fragment>
        <TextField
          autoFocus
          fullWidth
          name="name"
          margin="dense"
          value={description}
          onChange={handleChangeDescription}
          label="Descrição do Monitoramento"
          style={{ marginTop: 10 }}
        />
        <br />
      </React.Fragment>
    )

  const renderDocumentsTextField = () => (
    <div style={{ marginTop: 15 }}>
      <TextField
        fullWidth
        multiline
        rows={10}
        label="Documentos ou Nomes"
        value={inputsTextField}
        onChange={handleChangeDocuments}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="123.456.789-00
98.765.432/0001-00
11122233344
99888777000100
João da Silva"
      />
    </div>
  )

  const toggleForm = () => {
    setShowingForm(!showingForm)
  }

  const updateDocumentsText = (newText) => {
    setDocumentsTextField(newText)
    parseInputs(newText)
  }

  const renderDocumentCount = () => {
    const totalCount = inputs.length
    const validsCount = validCpfs.size + validCnpjs.size + validNames.size
    return (
      <Grid container style={{ marginTop: 15, width: '100%' }}>
        <Grid item xs={6}>
          <h3>
            Registros inseridos:
            {' '}
            {totalCount}
          </h3>
          <h3>
            Registros inválidos:
            {' '}
            {Math.max(0, totalCount - validsCount)}
          </h3>
          <h3>
            Registros repetidos:
            {' '}
            {inputs.filter((d) => d.isRepeated).length}
          </h3>
        </Grid>

        <Grid item xs={6}>
          <h3>
            Registros válidos:
            {' '}
            {validsCount}
          </h3>
          <h3>
            CPFs válidos:
            {' '}
            {validCpfs.size}
          </h3>
          <h3>
            CNPJs válidos:
            {' '}
            {validCnpjs.size}
          </h3>
          <h3>
            Nomes válidos:
            {' '}
            {validNames.size}
          </h3>
        </Grid>

        <Grid item xs={12}>
          <br />
          <h3>Nota: Valores inválidos ou repetidos serão ignorados.</h3>
        </Grid>
      </Grid>
    )
  }

  const validateFormParams = () => {
    if (formType === peopleMonitoringFormTypes.create) {
      return description.length < DESCRIPTION_MIN_LEGTH || _.isEmpty(inputs)
    }
    return _.isEmpty(inputs)
  }

  const onSelectFile = async (e) => {
    if (e.target.files) {
      setLoadingFile(true)
      setFile(e.target.files[0])
    }
  }

  const changeInputType = () => {
    setDocuments([])
    setValidCpfs(new Set([]))
    setValidCnpjs(new Set([]))
    setValidNames(new Set([]))
    setInputByFile(!inputByFile)
    setDocumentsTextField('')
    setFileContent('')
    setFile(null)
  }

  const renderFileInsert = () => (
    <React.Fragment>
      <div style={{ marginTop: 15, display: 'inline' }}>
        <input
          type="file"
          data-testid="file-input"
          id="file-input"
          accept=".csv,.txt"
          onChange={onSelectFile}
          ref={inputFileRef}
        />

        {loadingFile && <span style={{ fontSize: '1.4rem' }}>Processando arquivo... </span>}
      </div>

      {renderDocumentCount()}

      <Tooltip title={<h3>Digitar os CPFs, CNPJs ou nomes, uma entrada por linha.</h3>}>
        <Button color="primary" onClick={changeInputType} startIcon={<KeyboardIcon />}>
          Digitar entradas
        </Button>
      </Tooltip>
    </React.Fragment>
  )

  const renederManualInsert = () => (
    <React.Fragment>
      {renderDocumentsTextField()}

      {renderDocumentCount()}

      <Tooltip title={<h3>Fazer upload de um arquivo com os CPFs, CNPJs ou nomes, contendo uma entrada por linha.</h3>}>
        <Button color="primary" onClick={changeInputType} startIcon={<PublishIcon />}>
          Enviar arquivo
        </Button>
      </Tooltip>
    </React.Fragment>
  )

  const searchInSocialMediaCheckBox = () => (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <FormControl style={{ marginTop: 10 }} component="fieldset">
        <FormControlLabel
          value="end"
          control={<Checkbox checked={searchInSocialMedia} color="primary" onClick={handleChangeSearchInSocialMedia} />}
          label="Pesquisar também em redes sociais e websites das pessoas"
        />
      </FormControl>
      <PeopleMonitoringTooltip />
    </div>
  )

  const renderFormDialog = () => (
    <React.Fragment>
      <DialogContent>
        {renderDescriptionTextField()}

        {inputByFile ? renderFileInsert() : renederManualInsert()}
        {!hideSocialMediaSearchCheckBox && searchInSocialMediaCheckBox()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancelar
        </Button>
        <Button disabled={validateFormParams()} onClick={() => setShowingForm(false)} color="primary">
          Próximo
        </Button>
      </DialogActions>
    </React.Fragment>
  )

  const onConfirmRequestEvent = () => {
    onConfirmRequest({ description, searchInSocialMedia, inputs: [...validCpfs, ...validCnpjs, ...validNames] })
  }

  const renderConfirmationDialog = () => {
    return (
      <DocumentMonitoringConfirmation
        formType={formType}
        inputs={inputs}
        loadingRequest={isApiLoading}
        onConfirmRequest={onConfirmRequestEvent}
        validInputsCount={validCpfs.size + validCnpjs.size + validNames.size}
        toggleForm={toggleForm}
        renderDocumentCount={renderDocumentCount}
        updateDocumentsText={updateDocumentsText}
      />
    )
  }

  return <React.Fragment>{showingForm ? renderFormDialog() : renderConfirmationDialog()}</React.Fragment>
}

export default DocumentMonitoringForm
