import * as React from 'react'
import { FormControl, FormHelperText, IconButton, MenuItem, Select, TextField } from '@material-ui/core'
import { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { parseSearchTerm } from 'utils/functions'
import MUIIcon from 'ui/components/Icon/MUIIcon'
import styles from '../styles'

const PeopleFilterControls = ({ consultFilters, updateConsultFilters }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [personTypeFilter, setPersonTypeFilter] = useState('all')
  const personTypeFilters = {
    all: 'all',
    cpf: 'cpf',
    cnpj: 'cnpj',
    unknow: 'unknow',
  }

  const personTypeFilterLabel = {
    all: 'Mostrar todos',
    cpf: 'Pessoa Física',
    cnpj: 'Pessoa Jurídica',
    unknow: 'Desconhecido',
  }
  const classes = styles()

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handlePersonTypeFilterChange = (event) => {
    const { value } = event.target
    setPersonTypeFilter(value)
    updateConsultFilters({ ...consultFilters, personType: value !== 'all' ? personTypeFilters[value] : null })
  }

  /** When click to search by a term (document or name), rebuilds the consultFiter object. */
  const handleClickSearchTerm = () => {
    const termObject = parseSearchTerm(searchTerm)
    updateConsultFilters({ ...consultFilters, ...termObject })
  }

  const showPersonTypeFilterMenuItem = ({ filter, iconName }) => (
    <MenuItem value={filter}>
      {iconName && (
        <span style={{ paddingTop: 5, paddingRight: 2 }}>
          <MUIIcon type={iconName} />
        </span>
      )}
      {personTypeFilterLabel[filter]}
    </MenuItem>
  )

  const showTermSearchAndMonitoringViewControl = () => (
    <span style={{ display: 'flex', alignItems: 'end' }}>
      <FormControl>
        <FormHelperText>Filtrar pessoas por:</FormHelperText>
        <TextField
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          placeholder="CPF, CNPJ ou Nome"
          InputProps={{
            className: classes.textField,
          }}
        />
      </FormControl>

      <IconButton style={{ padding: '0px 10px' }} onClick={handleClickSearchTerm}>
        <SearchIcon style={{ verticalAlign: 'center' }} />
      </IconButton>
    </span>
  )

  const showPersonTypeFilterControl = () => (
    <FormControl>
      <FormHelperText id="filter-select">Tipo:</FormHelperText>
      <Select
        value={personTypeFilter}
        onChange={handlePersonTypeFilterChange}
        renderValue={(value) => personTypeFilterLabel[value]}
        className={classes.textField}
      >
        {showPersonTypeFilterMenuItem({ filter: personTypeFilters.all })}
        {showPersonTypeFilterMenuItem({ filter: personTypeFilters.cpf, iconName: 'cpf' })}
        {showPersonTypeFilterMenuItem({ filter: personTypeFilters.cnpj, iconName: 'cnpj' })}
        {showPersonTypeFilterMenuItem({ filter: personTypeFilters.unknow, iconName: 'unknowPerson' })}
      </Select>
    </FormControl>
  )
  return (
    <div className={classes.consultsControlsBar}>
      {showTermSearchAndMonitoringViewControl()}

      {showPersonTypeFilterControl()}
    </div>
  )
}

export default PeopleFilterControls
