import React from 'react'
import _ from 'lodash'
import { getDate, MONTHS, parseDate, WEEKDAYS, BR_DATE_FORMAT, dateToStr } from 'utils/constants/dateConstants'
import { Container, Typography } from '@material-ui/core'
import MUIIcon from '../Icon/MUIIcon'


export const itemTypes = {
  notice: 'notice',
  socialMedia: 'socialMedia',
}

export const groupModes = {
  publishedDate: 'publishedDate',
  createdAt: 'createdAt',
}

export const groupModeNames = {
  publishedDate: 'publicação',
  createdAt: 'identificação',
}

const createItemDateLabel = (date, dateFormat = null) => {
  if (!date) {
    return 'Data desconhecida'
  }

  const today = getDate()


  const parsedDate = parseDate(date, dateFormat)
  if (parsedDate === today) {
    return 'Hoje'
  }

  // Por exemplo: Terça, 2 de novembro
  const dateStr = `${WEEKDAYS[parsedDate.getDay()]}, ${parsedDate.getDate()} de ${MONTHS[parsedDate.getMonth()]}`

  if (parsedDate.getFullYear() === today.getFullYear()) {
    return dateStr
  }

  // Por exemplo: Terça, 2 de novembro de 2018
  return `${dateStr} de ${parsedDate.getFullYear()}`
}


/** Takes an array of notices or posts  and creates a dictionary, grouping the items by date.
 * The keys are the parsed date labels. */
const groupItemsByPublishedDate = (items, itemType, sortOrder) => {

  const groups = {}
  let currentDate = null
  let currentParsedDate = null

  if (_.isEmpty(items)) {
    return {}
  }

  const sortedItems = _.orderBy(items, (i) => {
    const itemDate = itemType === 'notice' ? i.notice_date : i.post_date
     const date = dateToStr(parseDate(itemDate, BR_DATE_FORMAT)) || ''
     return date
    }, [sortOrder])

  sortedItems.forEach((n) => {
    const itemDate = itemType === 'notice' ? n.notice_date : n.post_date
    if (itemDate && itemDate === currentDate) {
      groups[currentParsedDate].items.push(n)
      return
    }

    currentParsedDate = createItemDateLabel(itemDate, BR_DATE_FORMAT)
    currentDate = itemDate
    if (groups[currentParsedDate]) {
      groups[currentParsedDate].items.push(n)
    }
    else {
      groups[currentParsedDate] = { items: [n] }
    }
  })

  return groups
}



/** Takes an array of notices or posts  and creates a dictionary, grouping the items by date.
 * The keys are the parsed date labels. */
const groupItemsByCreatedAt = (items, itemType, sortOrder) => {

  const groups = {}
  let currentDate = null
  let currentParsedDate = null

  if (_.isEmpty(items)) {
    return {}
  }

  const sortedItems = _.orderBy(items, (i) => i.snippet?.created_at, [sortOrder])

  sortedItems.forEach((n) => {
    const itemDate = itemType === 'notice' ? n?.snippet?.created_at : n?.snippet?.created_at
    if (itemDate && itemDate === currentDate) {
      groups[currentParsedDate].items.push(n)
      return
    }

    currentParsedDate = createItemDateLabel(itemDate)
    currentDate = itemDate
    if (groups[currentParsedDate]) {
      groups[currentParsedDate].items.push(n)
    }
    else {
      groups[currentParsedDate] = { items: [n] }
    }
  })

  return groups
}

export const groupItems = (groupMode, items, itemType, sortOrder) => {
  switch (groupMode) {
    case groupModes.publishedDate:
      return groupItemsByPublishedDate(items, itemType, sortOrder)
    case groupModes.createdAt:
      return groupItemsByCreatedAt(items, itemType, sortOrder)
    default:
      return null
  }
}

export const itemsGroupDescription = (itemType, groupMode) => {
  return (
    <Container>
      <Typography variant='subtitle2' style={{ marginTop: 10, display: 'flex', alignItems: 'start' }}>
        <span><MUIIcon fontSize='2.1rem' type={groupMode} /></span>
        <span>{`${itemType} agrupadas por data de ${groupModeNames[groupMode]}`}</span>
      </Typography>
    </Container>
  )
}
