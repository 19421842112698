import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import 'date-fns'
import _ from 'lodash'
import { getSocialMediaPlan, postSocialMediaConfigurePlan } from 'services/consultApi'
import { toast } from 'react-toastify'
import reportError from 'utils/errorReporter'
import { useSelector } from 'react-redux'
import { defaultSocialMediaPlanWords } from 'utils/constants/plan'
import ChipInput from 'ui/components/ChipInput'
import KeywordsTable from '../KeywordsTable'
import KeyswordDisplayModeButton from '../KeywordsDisplayModeButton'

const SocialMediaWordsPlan = ({ handleGoBack }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [useDefaultPlan, setUseDefaultPlan] = useState(true)

  const [words, setWords] = useState(defaultSocialMediaPlanWords)
  const [newTerm, setNewTerm] = useState('')
  const [tableMode, setTableMode] = useState(true)


  const handleCheckDefaultPlan = () => {
    setUseDefaultPlan(!useDefaultPlan)
  }

  useEffect(() => {
    const requestSocialMediaPlan = async () => {
      let res = null
      try {
        res = await getSocialMediaPlan(apiKey, userId)
      } catch (err) {
        reportError(err)
        return null
      }

      if (_.isEmpty(res.data?.words)) {
        setUseDefaultPlan(true)
        return null
      }

      setUseDefaultPlan(res.data.use_default_plan)
      setWords(res.data.words)
      return null
    }
    requestSocialMediaPlan()
  }, [apiKey, userId])

  const postCreatePlan = async () => {
    try {
      await postSocialMediaConfigurePlan(apiKey, userId, words, useDefaultPlan)
    } catch (err) {
      reportError(err, 'Erro ao configurar plano de mídias sociais')
      return false
    }

    toast.success('Configurações salvas')
    return true
  }

  const handleClickSave = async () => {
    if (!useDefaultPlan && _.isEmpty(words)) {
      toast.error('Adicione pelo menos um termo desabonador')
      return
    }
    if (await postCreatePlan()) {
      handleGoBack()
    }
  }

  const handleChangeTextField = (values) => {
    setWords(values)
  }

  const formatKeywordTerms = () => _.map(words, (tf) => ({ value: tf, key: tf }))

  const removeTerm = (item) => {
    setWords(_.filter(words, tf => tf.toLowerCase() !== item?.value?.toLowerCase()))
  }

  const addNewTerm = () => {
    if (!newTerm || _.some(words, tf => tf.toLowerCase() === newTerm.toLowerCase())) {
      return
    }
    words.unshift(newTerm.toUpperCase())
    setNewTerm('')
  }
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>Termos para busca em mídias sociais</DialogContentText>
        <FormControl style={{ marginTop: 5 }} component="fieldset">
          <FormControlLabel
            value="end"
            control={(
              <Checkbox
                value={useDefaultPlan || false}
                checked={useDefaultPlan || false}
                color="primary"
                onClick={handleCheckDefaultPlan}
              />
            )}
            label="Usar a configuração de palavras-chave padrão"
          />
        </FormControl>

        <KeyswordDisplayModeButton tableMode={tableMode} onClick={() => setTableMode(!tableMode)} />

        { !tableMode && (
        <div style={{ marginTop: 15 }}>
          <ChipInput
            fullWidth
            disabled={useDefaultPlan}
            label="Filtro de palavras desabonadoras"
            value={words}
            onChange={(e, values) => handleChangeTextField(values)}
          />
        </div>
)}

        { tableMode && (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField disabled={useDefaultPlan} fullWidth label='Digite um termo desabonador' value={newTerm} onChange={(e) => setNewTerm(e.target.value)} />

            <Button disabled={useDefaultPlan} color='primary' onClick={addNewTerm}>Adicionar</Button>
          </div>

          <KeywordsTable disabled={useDefaultPlan} filter={newTerm} terms={formatKeywordTerms(words)} onRemove={removeTerm} />
        </div>
)}
        <br />
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between' }}>
        <div style={{ marginLeft: 10 }}>
          <Button disabled={useDefaultPlan} color="primary" onClick={() => setWords(defaultSocialMediaPlanWords)}>
            Redefinir Termos
          </Button>
        </div>

        <div style={{ marginRight: 10 }}>
          <Button onClick={handleClickSave} color="primary">
            Salvar
          </Button>
        </div>
      </DialogActions>
    </React.Fragment>
  )
}

export default SocialMediaWordsPlan
