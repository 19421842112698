export const statusFilterType = {
  all: 'all',
  active: 'active',
  disabled: 'disabled',
}

export const statusFilterLabel = {
  all: 'Mostrar todos',
  active: 'Ativos',
  disabled: 'Pausados',
}

export const occurrencesFilterType = {
  all: 'all',
  consists: 'consists',
  notConsists: 'notConsists',
}

export const occurrencesFilterLabel = {
  all: 'Mostrar todos',
  consists: 'Com Ocorrências',
  notConsists: 'Sem Ocorrências',
}

export const resultFilterType = {
  all: 'all',
  approved: 'approved',
  reproved: 'reproved',
}

export const resultFilterLabel = {
  all: 'Mostrar todos',
  approved: 'Sem ocorrências',
  reproved: 'Com ocorrências',
}

export const documentFilterType = {
  all: 'all',
  cpf: 'cpf',
  cnpj: 'cnpj',
  nome: 'nome',
}

export const documentFilterLabel = {
  all: 'Mostrar todos',
  cpf: 'CPF',
  cnpj: 'CNPJ',
  nome: 'Nome',
}

export const validFilterType = {
  all: 'all',
  valid: 'valid',
  invalid: 'invalid',
}

export const validFilterLabel = {
  all: 'Mostrar todos',
  valid: 'Válidos',
  invalid: 'Inválidos ou repetidos',
}

export const peopleMonitoringFormTypes = {
  create: 'create',
  update: 'update',
}

export const lastExecutionStatusLabel = {
  processing: { description: 'Processando', iconName: 'waiting', iconColor: null },
  processed: { description: 'Processado', iconName: 'approved', iconColor: '#bbb' },
  none: { description: '', iconName: 'empty', iconColor: null },

}

