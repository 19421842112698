import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import 'date-fns'
import { postCreateConsult } from 'services/consultApi'
import { useSelector } from 'react-redux'
import reportError from 'utils/errorReporter'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { MaskInput } from 'ui/components/MaskedInput'
import { extractOnlyNumbers } from 'utils/functions'
import TextFieldDatePicker from 'ui/components/TextFieldDatePicker'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import { isDocNumberValid } from 'utils/validators'
import ConsultSocialNetworkDialogContent from 'ui/components/AddNewDialog/ConsultSocialNetworkDialogContent'

const AddConsultDialog = ({ handleCloseDialog }) => {
  const CONSULT_TYPE = {
    cpf: 'cpf',
    cnpj: 'cnpj',
    name: 'name',
  }

  const CONSULT_TYPE_LABEL = {
    cpf: 'CPF',
    cnpj: 'CNPJ',
    name: 'Nome',
  }

  const CONSULT_TERM_MIN_LEGTH = {
    cpf: 11,
    cnpj: 14,
    name: 2,
  }

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [consultType, setConsultType] = useState(CONSULT_TYPE.cpf)
  const [consultTerm, setConsultTerm] = useState('')
  const [isConsultTermValid, setIsConsultTermValid] = useState(false)
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [initialDateLimit, setInitialDateLimit] = useState(false)
  const [finalDateLimit, setFinalDateLimit] = useState(false)
  const [checkSocialNetworks, setCheckSocialNetworks] = useState(false)

  const [openSocialNetworksDialog, setOpenSocialNetworksDialog] = useState(false)
  const [socialNetworkInputs, setSocialNetworkInputs] = useState([])

  const history = useHistory()

  const isInputValid = (input) => {
    let valid = true
    const lengthValid = input.length >= CONSULT_TERM_MIN_LEGTH[consultType]

    if (consultType !== CONSULT_TYPE.name) {
      valid = isDocNumberValid(input, consultType)
    }
    return valid && lengthValid
  }

  const checkConsultTerm = (termStr) => {
    let term = termStr?.trim()

    if (!term) {
      return false
    }

    if (consultType === CONSULT_TYPE.cpf || consultType === CONSULT_TYPE.cnpj) {
      term = extractOnlyNumbers(term)
    }

    return isInputValid(term)
  }

  const handleInitialDateChange = (event) => {
    setInitialDate(event.target.value)
  }

  const handleFinalDateChange = (event) => {
    setFinalDate(event.target.value)
  }

  const handleChangeSearchSocialNetworks = (event) => {
    setCheckSocialNetworks(event.target.checked)
  }

  const handleChangeConsultType = (event) => {
    setConsultTerm('')
    setConsultType(event.target.value)
  }

  const handleChangeInitialDateLimit = (event) => {
    setInitialDateLimit(event.target.checked)
  }

  const handleChangeFinalDateLimit = (event) => {
    setFinalDateLimit(event.target.checked)
  }

  const handleChangeConsultTerm = (event) => {
    setConsultTerm(event.target.value)
    setIsConsultTermValid(checkConsultTerm(event.target.value))
  }

  const handleChangeSocialNetworkInputs = (newSocialNetworkInputs) => {
    setSocialNetworkInputs(newSocialNetworkInputs)
  }

  const handleConfirmDialog = async () => {
    try {
      await postCreateConsult(apiKey, userId, {
        name: consultType === CONSULT_TYPE.name ? consultTerm?.trim() : null,
        cpf: consultType === CONSULT_TYPE.cpf ? consultTerm : null,
        cnpj: consultType === CONSULT_TYPE.cnpj ? consultTerm : null,
        initialDate: initialDateLimit ? initialDate : null,
        finalDate: finalDateLimit ? finalDate : null,
        socialNetworkInputs: socialNetworkInputs,
      })
    } catch (err) {
      reportError(err, 'Erro ao criar consulta')
      return
    }

    toast.success(`Solicitação criada com sucesso!`)

    const currentPath = history.location.pathname
    const newPath = ROUTE_CONSTANTS.HOME

    if (currentPath === newPath) {
      // window.location.reload()
    }
    history.push(ROUTE_CONSTANTS.HOME)

    if (handleCloseDialog) handleCloseDialog()
  }

  const handleGoBackFromSocialNetworkDialog = () => {
    setSocialNetworkInputs([])
    setOpenSocialNetworksDialog(false)
  }

  const renderDatePickingLimiter = () => (
    <React.Fragment>
      <div>
        <FormControl style={{ marginTop: 10 }} component="fieldset">
          <FormControlLabel
            value="end"
            control={<Checkbox checked={initialDateLimit} color="primary" onClick={handleChangeInitialDateLimit} />}
            label="Incluir apenas notícias depois de:"
          />
        </FormControl>
      </div>

      <TextFieldDatePicker
        label="Data Inicial"
        maxDate={(finalDateLimit && finalDate) || new Date()}
        value={initialDate}
        onChange={handleInitialDateChange}
        disabled={!initialDateLimit}
      />

      <div>
        <FormControl style={{ marginTop: 10 }} component="fieldset">
          <FormControlLabel
            value="end"
            control={<Checkbox checked={finalDateLimit} color="primary" onClick={handleChangeFinalDateLimit} />}
            label="Incluir apenas notícias antes de:"
          />
        </FormControl>
      </div>

      <TextFieldDatePicker
        label="Data Final"
        minDate={initialDateLimit ? initialDate : null}
        maxDate={new Date()}
        value={finalDate}
        onChange={handleFinalDateChange}
        disabled={!finalDateLimit}
      />
    </React.Fragment>
  )

  const consultDialogContent = () => {
    return (
      <React.Fragment>
        <DialogContent>
          <MaskInput
            autoFocus
            fullWidth
            name={consultType}
            margin="dense"
            value={consultTerm}
            onChange={handleChangeConsultTerm}
            guide={consultType !== CONSULT_TYPE.name}
            label={CONSULT_TYPE_LABEL[consultType]}
            errorMsg={isConsultTermValid ? null : `${CONSULT_TYPE_LABEL[consultType]} inválido`}
            style={{ marginTop: 10 }}
          />

          <br />

          <FormControl style={{ marginTop: 5 }} component="fieldset">
            <RadioGroup
              row
              aria-label="consult type"
              name="consultType"
              value={consultType}
              onChange={handleChangeConsultType}
            >
              <FormControlLabel value={CONSULT_TYPE.cpf} control={<Radio />} label={CONSULT_TYPE_LABEL.cpf} />
              <FormControlLabel value={CONSULT_TYPE.cnpj} control={<Radio />} label={CONSULT_TYPE_LABEL.cnpj} />
              <FormControlLabel value={CONSULT_TYPE.name} control={<Radio />} label={CONSULT_TYPE_LABEL.name} />
            </RadioGroup>
          </FormControl>

          <br />

          <FormControl style={{ marginTop: 8 }} component="fieldset">
            <FormControlLabel
              control={<Checkbox checked={checkSocialNetworks} color="primary" onClick={handleChangeSearchSocialNetworks} />}
              label="Pesquisar também em redes sociais e websites da pessoa"
            />
          </FormControl>

          <Divider style={{ marginTop: 15, marginBottom: 10 }} />

          {renderDatePickingLimiter()}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>

          {!checkSocialNetworks && (
            <Button onClick={handleConfirmDialog} disabled={!consultTerm || !isConsultTermValid} color="primary">
              Consultar
            </Button>
          )}

          {checkSocialNetworks && (
            <Button
              onClick={() => setOpenSocialNetworksDialog(true)}
              disabled={!consultTerm || !isConsultTermValid}
              color="primary"
            >
              Próximo
            </Button>
          )}
        </DialogActions>
      </React.Fragment>
    )
  }

  const consultSocialNetworkContent = () => (
    <ConsultSocialNetworkDialogContent
      handleGoBack={handleGoBackFromSocialNetworkDialog}
      handleConfirmDialog={handleConfirmDialog}
      handleChangeSocialNetworkInputs={handleChangeSocialNetworkInputs}
      consultType={consultType}
      consultTerm={consultTerm}
    />
  )

  return (
    <React.Fragment>
      {!openSocialNetworksDialog && consultDialogContent()}
      {openSocialNetworksDialog && consultSocialNetworkContent()}
    </React.Fragment>
  )
}

export default AddConsultDialog
