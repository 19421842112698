import React, { useState } from 'react'
import { Button, DialogContent, DialogTitle, IconButton, DialogContentText } from '@material-ui/core'
import 'date-fns'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowBack } from '@material-ui/icons'
import NegativeMediaKeywordsPlan from './NegativeMediaKeywordsPlan'
import SocialMediaWordsPlan from './SocialMediaWordsPlan'
import styles from './styles'
import MUIIcon from '../Icon/MUIIcon'
import UserManagement from './UserManagement'

const SettingsDialog = ({ handleCloseDialog, changeDialogSize  }) => {
  const [negativeMediaKeywordsScreen, setNegativeMediaKeywordsScreen] = useState(false)
  const [socialMediaWordsScreen, setSocialMediaWordsScreen] = useState(false)
  const [userManagementScreen, setUserManagementScreen] = useState(false)

  const [mainScreen, setMainScreen] = useState(true)

  const classes = styles()

  const handleGoBack = () => {
    changeDialogSize('lg')
    setUserManagementScreen(false)
    setNegativeMediaKeywordsScreen(false)
    setSocialMediaWordsScreen(false)
    setMainScreen(true)
  }

  const openSocialMediaScreen = () => {
    setSocialMediaWordsScreen(true)
    setUserManagementScreen(false)
    setMainScreen(false)
  }

  const openNegativeMediaKeywordsScreen = () => {
    setNegativeMediaKeywordsScreen(true)
    setUserManagementScreen(false)
    setMainScreen(false)
  }

  const openUserManagementScreen = () => {
    setUserManagementScreen(true)
    setNegativeMediaKeywordsScreen(false)
    setMainScreen(false)
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            {!mainScreen && (
              <IconButton
                aria-label="close"
                onClick={handleGoBack}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <ArrowBack />
              </IconButton>
            )}

            <div>Configurações</div>
          </div>

          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      {mainScreen && (
        <React.Fragment>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                gap: 10,
                justifyContent: 'center',
                flexDirection: 'column',
                paddingTop: 20,
                paddingBottom: 30,
              }}
            >
              <DialogContentText>Consultas e Monitoramentos</DialogContentText>
              <div style={{ display: 'flex', gap: 10 }}>
                <Button
                  className={classes.configButton}
                  startIcon={<MUIIcon type="negativeMedia" fontSize={28} />}
                  onClick={openNegativeMediaKeywordsScreen}
                >
                  Termos de busca para mídias negativas
                </Button>
                <Button
                  className={classes.configButton}
                  startIcon={<MUIIcon type="socialMedia" fontSize={28} />}
                  onClick={openSocialMediaScreen}
                >
                  Termos de busca para redes sociais
                </Button>
              </div>

              <React.Fragment>
                <br />
                <DialogContentText>Acesso</DialogContentText>
                <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                  <Button
                    style={{ padding: '30px 20px' }}
                    className={classes.configButton}
                    startIcon={<MUIIcon type="userManagement" fontSize={28} />}
                    onClick={openUserManagementScreen}
                  >
                    Gestão de Usuários
                  </Button>
                </div>

              </React.Fragment>
            </div>
          </DialogContent>
        </React.Fragment>
      )}
      {negativeMediaKeywordsScreen && <NegativeMediaKeywordsPlan handleGoBack={handleGoBack} />}
      {socialMediaWordsScreen && <SocialMediaWordsPlan handleGoBack={handleGoBack} />}
      {userManagementScreen && <UserManagement handleGoBack={handleGoBack} changeDialogSize={changeDialogSize} />}
    </React.Fragment>
  )
}

export default SettingsDialog
