/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import reportError from 'utils/errorReporter'
import { getUser } from 'services/users'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import _ from 'lodash'
import { retrieveUser } from 'redux/auth/actions'
import AppBar from '../AppBar'

export default function ({ component: Component, appBarMenuOpen = true, handleClickAppMenuOpen = {}, ...rest }) {
  const [loggedUser, setLoggedUser] = useState(null)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const dispatch = useDispatch()


  useEffect(() => {
    if (_.isEmpty(loggedUser)) {
      return
    }
    if (!loggedUser.active) {
      setDialogMessage('Usuário desabilitado. Contate um administrador de sua organização.')
      setOpenErrorDialog(true)
      return
    }

    dispatch(retrieveUser({ email: loggedUser.email, role: loggedUser.role }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser])

  useEffect(() => {
    const getLoggedUser = async () => {
      let res = null
      try {
        res = await getUser(apiKey, userId)
      } catch (err) {
        reportError(err)
        setDialogMessage('Não foi possível buscar as informações do usuário. Tente novamente mais tarde.')
        setOpenErrorDialog(true)
        return null
      }

      setLoggedUser(res?.data)
      return null
    }

    getLoggedUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, userId])

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div>
          <AppBar appBarMenuOpen={appBarMenuOpen} handleClickAppMenuOpen={handleClickAppMenuOpen} />
          <Component {...matchProps} />
          <Dialog open={openErrorDialog}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => window.location.reload()}>Tentar novamente</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    />
  )
}
