import React, { useState } from 'react'
import { Button, DialogContent, DialogContentText, TextField } from '@material-ui/core'
import 'date-fns'
import { Add } from '@material-ui/icons'
import ConfirmDialog from 'ui/components/ConfirmDialog'
import UserList from './UserList'
import CreateUser from './CreateUser'

const UserManagement = ({ changeDialogSize }) => {
  const [nameFilter, setNameFilter] = useState('')
  const [createUserMode, setCreateUserMode] = useState(false)

  const addNewUser = () => {
    setCreateUserMode(true)
    changeDialogSize('sm')

  }

  const disableCreateUserScreen = () => {
    setCreateUserMode(false)
    changeDialogSize('lg')
  }

  return (
    <React.Fragment>
      <DialogContent>

        <DialogContentText>{createUserMode ? 'Adicionar Usuário' : 'Gerenciamento de Usuários'}</DialogContentText>

        {!createUserMode && (
          <React.Fragment>
            <div style={{ display: 'flex', gap: 10, marginBottom: 5, alignItems: 'end' }}>
              <TextField
                fullWidth
                label="Pesquisar nome"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
              <div>
                <Button size='small' variant='outlined' startIcon={<Add />} onClick={addNewUser}>
                  Adicionar
                </Button>
              </div>
            </div>
            <UserList nameFilter={nameFilter} />
          </React.Fragment>
        )}
        {createUserMode && <CreateUser handleGoBack={disableCreateUserScreen} />}
      </DialogContent>

      <ConfirmDialog open={false} />
    </React.Fragment>
  )
}

export default UserManagement
