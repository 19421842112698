import * as React from 'react'
import { FormControl, FormHelperText, TextField, Tooltip } from '@material-ui/core'
import SortRoundedIcon from '@material-ui/icons/Sort'
import { Button } from 'ui/components'
import TextFieldDatePicker from 'ui/components/TextFieldDatePicker'
import { ThumbsUpDown, ThumbsUpDownOutlined } from '@material-ui/icons'
import MUIIcon from 'ui/components/Icon/MUIIcon'
import styles from '../styles'
import { groupModes } from '../itemsGrouper'

const FilteringControls = ({
  disabled,
  consultType,
  olderFirst,
  searchTerm,
  initialDateFilter,
  finalDateFilter,
  hideReviewed,
  groupMode,
  handleInitialDateFilterChange,
  handleFinalDateFilterChange,
  handleChangeSearchTerm,
  handleChangeOlderFirst,
  handleChangeHideReviewed,
  handleChangeGroupMode,
  clearNoticeFilters,
}) => {
  const classes = styles()

  const itemNames = {
    socialMedia: 'postagens',
    negativeMedia: 'notícias',
  }

  const showOrderingButton = () => (
    <Tooltip
      arrow
      placement="right"
      title={(
        <h3>
          {olderFirst
            ? `Mostrar ${itemNames[consultType]} mais recentes primeiro`
            : `Mostrar ${itemNames[consultType]} mais antigas primeiro`}
        </h3>
      )}
    >
      <div>
        <Button
          style={{ margin: '5px 0' }}
          color={olderFirst ? 'secondary' : 'primary'}
          disabled={disabled}
          onClick={() => handleChangeOlderFirst()}
          variant="text"
        >
          <SortRoundedIcon
            style={{
              fontSize: '3rem',
              transform: olderFirst ? 'rotateX(180deg)' : null,
            }}
          />
        </Button>
      </div>
    </Tooltip>
  )

  const showHideViewedButton = () => {
    const color = 'primary'
    return (
      <Tooltip
        arrow
        placement="right"
        title={(
          <h3>
            {hideReviewed
              ? `Mostrar ${itemNames[consultType]} já analisadas`
              : `Esconder ${itemNames[consultType]} já analisadas`}
          </h3>
        )}
      >
        <div>
          <Button
            style={{ margin: '5px 0' }}
            disabled={disabled}
            onClick={() => handleChangeHideReviewed()}
            variant="text"
          >
            {hideReviewed ? (
              <ThumbsUpDownOutlined
                style={{
                  color: color,
                  fontSize: '3rem',
                  transform: 'scale(0.8)',
                }}
              />
            ) : (
              <ThumbsUpDown
                style={{
                  color: color,
                  fontSize: '3rem',
                  transform: 'scale(0.8)',
                }}
              />
            )}
          </Button>
        </div>
      </Tooltip>
    )
  }

  const showGroupModeButton = () => {
    const color = 'primary'
    return (
      <Tooltip
        arrow
        placement="right"
        title={(
          <h3>
            {groupMode === groupModes.publishedDate
              ? `Agrupar ${itemNames[consultType]} por data de identificação`
              : `Agrupar ${itemNames[consultType]} por data de publicação`}
          </h3>
        )}
      >
        <div>
          <Button
            style={{ margin: '5px 0' }}
            disabled={disabled}
            onClick={() => handleChangeGroupMode()}
            variant="text"
          >
            {groupMode === groupModes.createdAt ? (
              <MUIIcon
                type='publishedDate'
                style={{
                  color: color,
                  fontSize: '3rem',
                  transform: 'scale(0.8)',
                }}
              />
            ) : (
              <MUIIcon
                type='createdAt'
                style={{
                  color: color,
                  fontSize: '3rem',
                  transform: 'scale(0.8)',
                }}
              />
            )}
          </Button>
        </div>
      </Tooltip>
    )
  }

  const showDateFilteringControls = () => (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      <FormControl>
        <FormHelperText>{`Filtrar ${itemNames[consultType]}:`}</FormHelperText>
        <TextField
          disabled={disabled}
          style={{ width: '10.5vw', paddingRight: 10 }}
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          placeholder="Palavra-chave"
          InputProps={{
            className: classes.textField,
          }}
        />
      </FormControl>

      <TextFieldDatePicker
        disabled={disabled}
        value={initialDateFilter}
        label="De:"
        maxDate={finalDateFilter || new Date()}
        onChange={handleInitialDateFilterChange}
        style={{ paddingRight: 10 }}
        InputProps={{
          className: classes.textField,
        }}
      />

      <TextFieldDatePicker
        disabled={disabled}
        value={finalDateFilter}
        label="Até:"
        maxDate={new Date()}
        minDate={initialDateFilter}
        onChange={handleFinalDateFilterChange}
        style={{ paddingRight: 5 }}
        InputProps={{
          className: classes.textField,
        }}
      />
      <Tooltip
        arrow
        placement="left"
        title={<h3>{`Limpar filtragem de ${itemNames[consultType]} por datas ou termos`}</h3>}
      >
        <div>
          <Button
            disabled={disabled || (initialDateFilter === '' && finalDateFilter === '' && searchTerm === '')}
            onClick={clearNoticeFilters}
            variant="text"
          >
            Limpar
          </Button>
        </div>
      </Tooltip>
    </div>
  )

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', gap: '0.1rem' }}>
        {showOrderingButton()}
        {showHideViewedButton()}
        {showGroupModeButton()}
      </div>
      {showDateFilteringControls()}
    </div>
  )
}

export default FilteringControls
