import { DialogTitle, IconButton } from '@material-ui/core'
import { ArrowBack, Close } from '@material-ui/icons'
import * as React from 'react'

const CloseableDialogTitle = ({ children, handleCloseDialog, handleGoBack }) => {
  return (
    <DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {handleGoBack && (
            <IconButton
              aria-label="close"
              onClick={handleGoBack}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <ArrowBack />
            </IconButton>
          )}

          <div>{children}</div>
        </div>

        {handleCloseDialog && (
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        )}
      </div>
    </DialogTitle>
  )
}
export default CloseableDialogTitle
