import React, { useState } from 'react'
import 'date-fns'
import { useSelector } from 'react-redux'
import reportError from 'utils/errorReporter'
import { toast } from 'react-toastify'
import { patchPeopleMonitoring } from 'services/monitoringApi'
import PeopleMonitoringForm from 'ui/components/PeopleMonitoringForm'
import { useHistory } from 'react-router'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import { Button, Dialog, DialogContent, FormControl, FormControlLabel, Switch } from '@material-ui/core'
import { peopleMonitoringFormTypes } from 'utils/constants/peopleMonitoring'
import MUIIcon from '../Icon/MUIIcon'
import CloseableDialogTitle from '../CloseableDialogTitle'
import PeopleMonitoringTooltip from '../PeopleMonitoringTooltip'

const UpdateMonitoringDialog = ({ dialogOpen, handleCloseDialog, monitoringData, onUpdateMonitoringData }) => {
  const history = useHistory()

  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [showSettingsForm, setShowSettingsForm] = useState(true)

  const callApiToInsertItems = async (options = {}) => {
    try {
      await patchPeopleMonitoring(apiKey, userId, monitoringData.request_id, {
        active: options.active,
        newInputs: options.inputs,
      })
    } catch (err) {
      reportError(err, 'Erro ao atualizar dados do monitoramento')
      return
    }

    toast.success(`Monitoramento atualizado com sucesso!`)

    history.push(`${ROUTE_CONSTANTS.PEOPLE_MONITORING}/${monitoringData.request_id}`)
    window.location.reload()
    handleCloseDialog()
  }

  const handleClickSearchInSocialMedia = () => {
    onUpdateMonitoringData({ searchInSocialMedia: !(monitoringData.search_in_social_media || false) })
  }

  const handleClickActive = () => {
    onUpdateMonitoringData({ active: !monitoringData.active })
  }

  const settingsForm = () => (
    <React.Fragment>
      <CloseableDialogTitle handleCloseDialog={handleCloseDialog}>Configurar o monitoramento</CloseableDialogTitle>

      <DialogContent>
        <div>
          <FormControl style={{ marginTop: 10 }} component="fieldset">
            <FormControlLabel
              value="end"
              control={<Switch checked={monitoringData.active} color="primary" onClick={handleClickActive} />}
              label="Monitoramento ativo?"
            />
          </FormControl>

          <span style={{ display: 'flex' }}>
            <FormControl disabled={!monitoringData.active} style={{ marginTop: 10 }} component="fieldset">
              <FormControlLabel
                value="end"
                control={(
                  <Switch
                    checked={monitoringData.search_in_social_media || false}
                    color="primary"
                    onClick={handleClickSearchInSocialMedia}
                  />
                )}
                label={(
                  <React.Fragment>
                    Pesquisar postagens em mídias sociais (terá efeito na próxima execução)
                    {' '}
                    <PeopleMonitoringTooltip />
                  </React.Fragment>
                )}
              />
            </FormControl>
          </span>
        </div>

        <br />

        <Button
          disabled={!monitoringData.active}
          color="primary"
          startIcon={<MUIIcon color={monitoringData.active ? null : '#ddd'} type="addPeople" />}
          onClick={() => setShowSettingsForm(false)}
        >
          Adicionar pessoas...
        </Button>
      </DialogContent>
    </React.Fragment>
  )

  const addPeopleForm = () => (
    <React.Fragment>
      <CloseableDialogTitle handleGoBack={() => setShowSettingsForm(true)}>
        Acrescentar pessoas ao monitoramento
      </CloseableDialogTitle>

      <PeopleMonitoringForm
        formType={peopleMonitoringFormTypes.update}
        onConfirmRequest={callApiToInsertItems}
        handleCloseDialog={() => setShowSettingsForm(true)}
        existingDocuments={monitoringData.inputs}
        hideSocialMediaSearchCheckBox={true}
      />
    </React.Fragment>
  )
  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        {showSettingsForm && settingsForm()}
        {!showSettingsForm && addPeopleForm()}
      </Dialog>
    </div>
  )
}

export default UpdateMonitoringDialog
