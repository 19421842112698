import React, { useState } from 'react'
import { Container, Dialog, Fab, makeStyles, Tab, Tabs } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'

import 'date-fns'
import AddConsultDialog from '../AddConsultDialog'
import AddMonitoringDialog from '../AddMonitoringDialog'

const AddNewButton = () => {

  const TABS = {
    consult: 0,
    monitoring: 1,
  }

  const [dialogOpen, setDialogOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS.consult)


  const handleClickOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue)
  }

  const useStyles = makeStyles((theme) => ({
    margin: {
      position: 'fixed',
      bottom: theme.spacing(5),
      right: theme.spacing(15),
      transform: 'scale(1.2)',
    },
  }))

  const classes = useStyles()
  const renderTabs = () => (
    <Container>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
        aria-label="disabled tabs example"
      >
        <Tab label="Nova Consulta" />
        <Tab label="Novo Monitoramento" />
      </Tabs>
    </Container>
  )

  const renderDialogContent = () => {
    switch (activeTab) {
      case TABS.consult:
        return <AddConsultDialog handleCloseDialog={handleCloseDialog} />
      case TABS.monitoring:
        return <AddMonitoringDialog handleCloseDialog={handleCloseDialog} />
      default:
        return null
    }
  }

  const renderDialog = () => {
    return (
      <div>
        <Dialog open={dialogOpen} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">

          {renderTabs()}

          {renderDialogContent()}
        </Dialog>
      </div>
    )
  }
  return (
    <React.Fragment>
      <Fab color="primary" aria-label="add" className={classes.margin} onClick={handleClickOpenDialog}>
        <AddIcon />
      </Fab>
      {renderDialog()}
    </React.Fragment>
  )
}

export default AddNewButton
