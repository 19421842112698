import * as React from 'react'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { formatDocument } from 'utils/masks'
import _ from 'lodash'
import { getPeople } from 'services/consultApi'
import reportError from 'utils/errorReporter'
import { useSelector } from 'react-redux'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterOutlined'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import LaunchIcon from '@material-ui/icons/Launch'
import { Pagination } from '@material-ui/lab'
import { ArrowBack, PersonAdd } from '@material-ui/icons'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import SidebarMenu, { MENU_PAGES } from '../SidebarMenu'
import styles from './styles'
import PeopleFilterControls from './PeopleFilterControls'
import PersonProfile from './PersonProfile'
import PersonAvatar from '../PersonAvatar'
import AddPersonDialog from './AddPersonDialog'

const PeopleDatabase = ({ history }) => {
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)
  const pageParams = useParams()
  const [peopleData, setPeopleData] = useState({ items: [], currentPage: 1, totalPages: 1, totalItems: 0 })
  const [consultFilters, setConsultFilters] = useState({ page: 1, name: null, document: null, personType: null })
  const [selectedPerson, setSelectedPerson] = useState({})
  const [loading, setLoading] = useState(true)
  const [addPersonDialogOpen, setAddPersonDialogOpen] = useState(false)

  useEffect(() => {
    const requestPeople = async () => {
      setLoading(true)
      let res
      try {
        const personId = pageParams.id
        res = await getPeople(apiKey, userId, _.isEmpty(personId) ? consultFilters : { personId: personId })
      } catch (err) {
        reportError(err)
        return null
      } finally {
        setLoading(false)
      }
      const xTotal = parseInt(res.headers['x-total'], 10)

      setPeopleData({
        totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
        totalItems: xTotal,
        currentPage: parseInt(res.headers['x-page'], 10),
        items: res.data.people,
      })

      if (pageParams.id) {
        if (xTotal === 0) {
          toast.error(`Pessoa com ID ${pageParams.id} não encontrada.`)
          history.push(ROUTE_CONSTANTS.PEOPLE_DATABASE)
        } else {
          setSelectedPerson(res.data.people[0])
        }
      }
      return null
    }
    requestPeople()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultFilters, pageParams.id])

  const personTypes = {
    cpf: { label: 'Pessoa Física', icon: <PersonIcon style={{ fontSize: '1.5rem' }} /> },
    cnpj: { label: 'Pessoa Jurídica', icon: <BusinessCenterIcon style={{ fontSize: '1.5rem' }} /> },
    unknow: { label: 'Desconhecido', icon: <HelpIcon style={{ fontSize: '1.5rem' }} /> },
  }

  const classes = styles()

  const handleChangePage = async (event, page) => setConsultFilters({ ...consultFilters, page })

  const updateConsultFilters = (newFilters) => setConsultFilters({ ...newFilters, page: 1 })

  const handleClickPerson = (person) => {
    setSelectedPerson(person)
    history.push(`${ROUTE_CONSTANTS.PEOPLE_DATABASE}/${person.id}`)
  }
  const updatePerson = (person) => {
    setSelectedPerson(person)
  }

  const handleGoBack = () => {
    setPeopleData({})
    setSelectedPerson({})
    history.push(ROUTE_CONSTANTS.PEOPLE_DATABASE)
  }

  const showTitle = () => (
    <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
      {!_.isEmpty(selectedPerson) && (
        <IconButton aria-label="close" onClick={handleGoBack}>
          <ArrowBack />
        </IconButton>
      )}
      <h2 style={{ padding: '15px 0' }}>Banco de Pessoas</h2>
    </Container>
  )
  const showPeopleList = () => (
    <React.Fragment>
      <Container>
        <PeopleFilterControls consultFilters={consultFilters} updateConsultFilters={updateConsultFilters} />

        <Grid container spacing={3} layout="row">
          {_.map(peopleData?.items, (person) => {
            const otherNames = _.isEmpty(person.nicknames) ? person.fantasy_names : person.nicknames
            const personName = person?.name
            const activeSocialMedia = person.social_medias.filter((s) => s.active)
            return (
              <Grid item key={person.id} xs={3} lg={3} sm={6}>
                <Card
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <CardContent style={{ padding: 15 }}>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <PersonAvatar name={personName} />
                      <div className={classes.personInfoDiv}>
                        <p>{person.name || '---'}</p>
                        <p>{formatDocument(person.document, person.person_type) || '---'}</p>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                          <span>Tipo:</span>
                          {personTypes[person.person_type].icon}
                          <span>{personTypes[person.person_type].label}</span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <Divider />
                    <br />
                    <div className={classes.personInfoDiv}>
                      {person.person_type === 'unknow' ? (
                        <p>---</p>
                      ) : (
                        <p>
                          {otherNames.length || 'Nenhum'}
                          {' '}
                          {person.person_type === 'cpf' &&
                            (otherNames.length <= 1 ? 'apelido registrado' : 'apelidos registrados')}
                          {person.person_type === 'cnpj' &&
                            (otherNames.length <= 1 ? 'nome fantasia registrado' : 'nomes fantasia registrados')}
                        </p>
                      )}

                      <br />

                      <p>
                        {activeSocialMedia.length || 'Nenhuma'}
                        {' '}
                        {(activeSocialMedia.length || 0) <= 1
                          ? 'mídia social registrada'
                          : 'mídias sociais registradas'}
                      </p>
                    </div>
                  </CardContent>
                  <Button color="primary" onClick={() => handleClickPerson(person)}>
                    <LaunchIcon fontSize="small" />
                  </Button>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Pagination count={peopleData.totalPages || 1} page={peopleData.currentPage || 1} onChange={handleChangePage} />
      </div>
    </React.Fragment>
  )

  const showLoading = () => (
    <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
      <CircularProgress fontSize="large" />
    </Container>
  )

  const showAddPersonButton = () => (
    <React.Fragment>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.addPersonButton}
        onClick={() => setAddPersonDialogOpen(true)}
      >
        <PersonAdd />
      </Fab>
      <AddPersonDialog open={addPersonDialogOpen} handleCloseDialog={() => setAddPersonDialogOpen(false)} />
    </React.Fragment>
  )
  return (

    <div style={{ display: 'flex' }}>
      <SidebarMenu currentPage={MENU_PAGES.peopleDatabase} />
      <Container
        style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', alignItems: 'center', marginTop: 5 }}
        maxWidth={false}
      >
        {showTitle()}
        {loading && showLoading()}
        {!loading && _.isEmpty(selectedPerson) && showPeopleList()}
        {!loading && !_.isEmpty(selectedPerson) && (
          <PersonProfile person={selectedPerson} updatePerson={updatePerson} />
        )}
        {_.isEmpty(selectedPerson) && showAddPersonButton()}
      </Container>

    </div>

  )
}

export default PeopleDatabase
