import React, { useEffect, useState } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import 'date-fns'

import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded'
import { formatDocument } from 'utils/masks'
import { getPeople } from 'services/consultApi'
import { useSelector } from 'react-redux'
import reportError from 'utils/errorReporter'
import { extractOnlyNumbers } from 'utils/functions'
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import _ from 'lodash'
import PersonSocialMedia from '../../PeopleDatabase/PersonProfile/PersonSocialMedia'
import TipBox from '../../TipBox'
import PersonAvatar from '../../PersonAvatar'

const ConsultSocialNetworkDialogContent = ({
  consultTerm,
  consultType,
  handleChangeSocialNetworkInputs,
  handleGoBack,
  handleConfirmDialog,
}) => {
  const [person, setPerson] = useState({})
  const [loadedPerson, setLoadedPerson] = useState(false)
  const [socialMediaFields, setSocialMediaFields] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  useEffect(() => {
    const formatSocialMediaApiInputs = () => {
      const personSocialMedia = socialMediaFields || []

      return _.map(personSocialMedia, (value, key) => ({ link: value, type: key }))
    }

    const inputs = formatSocialMediaApiInputs()
    handleChangeSocialNetworkInputs(inputs)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaFields])

  useEffect(() => {
    const createSocialMediaTextFields = (personData) => {
      const activeSocialMedia = {}
      let personSocialMedia = personData?.social_medias || []

      personSocialMedia = personSocialMedia.filter((sm) => sm.active)
      personSocialMedia.forEach((sm) => {
        activeSocialMedia[sm.type] = sm.link
      })
      return activeSocialMedia
    }

    const searchPerson = async () => {
      let res

      try {
        let query = {}
        if (consultType !== 'name') {
          query = { document: extractOnlyNumbers(consultTerm) }
        }
        else {
          query = { name: consultTerm, personType: 'unknow' }
        }
        res = await getPeople(apiKey, userId, query)
      } catch (err) {
        reportError(err)
        setLoadedPerson(false)
        return
      }
      const people = res?.data?.people
      if (!_.isEmpty(people)) {
        const personFound = people[0]
        const socialMedia = createSocialMediaTextFields(personFound)

        setPerson(personFound)
        setSocialMediaFields(socialMedia)
        setLoadedPerson(true)
      } else {
        setLoadedPerson(false)
      }
    }

    searchPerson()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultTerm])

  const handleClickGoBack = () => {
    setPerson({})
    setLoadedPerson(false)
    handleGoBack()
  }

  const loadedPersonTipBox = () => (
    <TipBox icon={<WbIncandescentOutlinedIcon />}>
      Dados complementados pelo
      {' '}
      <strong>Banco de Pessoas</strong>
      . Acesse o
      {' '}
      <Link target="_blank" rel="noopener noreferrer" to={`${ROUTE_CONSTANTS.PEOPLE_DATABASE}/${person.id}`}>
        perfil dessa pessoa
      </Link>
      {' '}
      para editar as informações que serão sugeridas ao criar as próximas consultas e nos monitoramentos.
    </TipBox>
  )

  const newPersonTipBox = () => (
    <TipBox icon={<WbIncandescentOutlinedIcon />}>
      Os dados dessa pessoa serão armazenados em um perfil no
      {' '}
      <strong>Banco de Pessoas</strong>
      {' '}
      e poderão ser utilizados
      em consultas e monitoramentos futuros.
    </TipBox>
  )

  const name = person?.name || (consultType === 'name' ? consultTerm : '')

  const isSocialMediaFieldsEmpty = () => _.every(socialMediaFields, (sm) => _.isEmpty(sm))

  return (
    <React.Fragment>
      <DialogTitle>
        {loadedPerson ? 'Confirme' : 'Informe'}
        {' '}
        os links das redes sociais e websites
      </DialogTitle>

      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          <div style={{ backgroundColor: '#ededed', borderRadius: 8, padding: '5px 9px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              {loadedPerson ? <PersonAvatar name={person.name} /> : <AccountCircleRoundedIcon fontSize="large" />}
              <div style={{ fontSize: 14 }}>
                <p>
                  <strong>Consultando:</strong>
                </p>
                {name && (
                <p>
                  Nome:
                  {' '}
                  {name}
                  {' '}
                </p>
)}
                {consultType === 'cpf' && (
                <p>
                  CPF:
                  {' '}
                  {formatDocument(person?.document || consultTerm, consultType)}
                  {' '}
                </p>
)}
                {consultType === 'cnpj' && (
                <p>
                  CNPJ:
                  {' '}
                  {formatDocument(person?.document || consultTerm, consultType)}
                  {' '}
                </p>
)}
              </div>
            </div>
          </div>

          <PersonSocialMedia
            socialMediaFields={socialMediaFields}
            changeAllowSubmit={(noError) => setHasErrors(!noError)}
            updateSocialMediaFields={(link, type) => {
              setSocialMediaFields({ ...socialMediaFields, ...{ [type]: link } })
            }}
          />

          {loadedPerson ? loadedPersonTipBox() : newPersonTipBox()}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickGoBack} color="primary">
          Voltar
        </Button>
        <Button onClick={handleConfirmDialog} disabled={hasErrors || isSocialMediaFieldsEmpty()} color="primary">
          Consultar
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export default ConsultSocialNetworkDialogContent
