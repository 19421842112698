import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import 'date-fns'
import reportError from 'utils/errorReporter'
import { useSelector } from 'react-redux'
import { getUsers, patchUser } from 'services/users'
import { Table } from 'ui/components'
import MUIIcon from 'ui/components/Icon/MUIIcon'
import ConfirmDialog from 'ui/components/ConfirmDialog'
import { roleNames } from 'utils/constants/user'
import { Pagination } from '@material-ui/lab'

const UserList = ({ nameFilter }) => {
  const userId = useSelector((state) => state.auth.sub)
  const role = useSelector((state) => state.auth.role)
  const userEmail = useSelector((state) => state.auth.email)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [users, setUsers] = useState([])
  const [pageData, setPageData] = useState({})
  const [openConfirmPromoteDialog, setOpenConfirmPromoteDialog] = useState(false)
  const [userToPromote, setUserToPromote] = useState(null)

  const requestUsers = async () => {
    let res = null
    try {
      res = await getUsers(apiKey, userId, nameFilter)
    } catch (err) {
      reportError(err)
      return null
    }
    const xTotal = parseInt(res.headers['x-total'], 10)
    setPageData({
      totalPages: Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)),
      currentPage: parseInt(res.headers['x-page'], 10),
    })

    setUsers(res.data.users)
    return null
  }

  const onUpdateUser = async (user, params) => {
    try {
      await patchUser(apiKey, userId, user.email, params)
    } catch (err) {
      reportError(err)
      return null
    }
    await requestUsers()
    return null
  }

  useEffect(() => {
    requestUsers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, userId, nameFilter])

  const closeConfirmPromoteDialog = () => {
    setUserToPromote(null)
    setOpenConfirmPromoteDialog(false)
  }

  const onClickPromoteUser = (user) => {
    setUserToPromote(user)
    setOpenConfirmPromoteDialog(true)
  }

  const onPromoteUser = () => {
    onUpdateUser(userToPromote, { role: 'admin' })
    closeConfirmPromoteDialog()
  }

  const TableButton = ({ text, iconType, mainColor, onClick, disabled }) => {
    return (
      <Button
        startIcon={<MUIIcon type={iconType} color={disabled ? '#bbb' : mainColor} />}
        onClick={onClick}
        disabled={disabled}
        size="small"
        style={{ fontSize: '1.1rem', color: disabled ? '#bbb' : mainColor }}
      >
        {text}
      </Button>
    )
  }

  const renderRoleButton = (user, canBePromoted, canBeReduced) => {
    return (
      <React.Fragment>
        {user.role === 'admin' ? (
          <TableButton
            disabled={!canBeReduced}
            onClick={() => onUpdateUser(user, { role: 'default' })}
            iconType="reduceUser"
            text="Tornar padrão"
            mainColor="blue"
          />
        ) : null}
        {user.role === 'default' ? (
          <TableButton
            disabled={!canBePromoted}
            onClick={() => onClickPromoteUser(user)}
            iconType="promoteUser"
            text="Tornar administrador"
            mainColor="purple"
          />
        ) : null}
      </React.Fragment>
    )
  }

  const renderActiveButton = (user, canBeDisabled) => {
    if (user.role === 'root') {
      return null
    }
    return user.active ? (
      <TableButton
        disabled={!canBeDisabled}
        iconType="disableUser"
        text="Desativar usuário"
        mainColor="red"
        onClick={() => onUpdateUser(user, { active: false })}
      />
    ) : (
      <TableButton
        disabled={!canBeDisabled}
        onClick={() => onUpdateUser(user, { active: true })}
        iconType="restoreUser"
        text="Reativar usuário"
        mainColor="green"
      />
    )
  }

  return (
    <React.Fragment>
      <div>
        <Table style={{ width: '100%', maxHeight: 500 }}>
          <thead>
            <tr>
              <th style={{ width: '18vw' }}>Nome</th>
              <th style={{ width: '18vw' }}>Email</th>
              <th style={{ width: '10vw' }}>Permissão</th>
              <th style={{ columnSpan: 2 }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const isActive = user.active
              const isSelf = user.email === userEmail
              const isAdmin = ['root', 'admin'].includes(user.role)
              const loggedUserIsRoot = role === 'root'
              const canBeDisabled = (!isSelf && loggedUserIsRoot) || !isAdmin
              const canBePromoted = isActive && !isSelf && !isAdmin
              const canBeReduced = isActive && !isSelf && loggedUserIsRoot && isAdmin

              return (
                <tr style={{ height: 50 }} key={user.email}>
                  <td style={{ color: !isActive ? '#ccc' : null }}>
                    <React.Fragment>
                      {user.name}
                      {isSelf && ' (você) '}
                    </React.Fragment>
                  </td>
                  <td style={{ color: !isActive ? '#ccc' : null }}>{user.email}</td>
                  <td style={{ color: !isActive ? '#ccc' : null }}>{roleNames[user.role]}</td>

                  <td
                    style={{
                      display: 'flex',
                      gap: '1rem',
                      alignItems: 'center',
                      paddingTop: 12,
                      justifyContent: 'center',
                    }}
                  >
                    {renderRoleButton(user, canBePromoted, canBeReduced)}
                    {renderActiveButton(user, canBeDisabled)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
          <Pagination
            count={pageData.totalPages}
            page={pageData.currentPage}
            onChange={(_event, number) => setPageData({ ...pageData, currentPage: number })}
          />
        </div>
      </div>

      <br />

      <ConfirmDialog
        title="Tornar administrador?"
        description={(
          <span>
            Atenção! Se tornar este usuário administrador, somente alguém com permissão de
            {' '}
            <strong>Super Usuário</strong>
            {' '}
            poderá reverter ou tomar outra ação sobre ele.
          </span>
)}
        open={openConfirmPromoteDialog}
        onAccept={onPromoteUser}
        onCancel={closeConfirmPromoteDialog}
      />
    </React.Fragment>
  )
}

export default UserList
