import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  controlsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  doneButtonIconUnMarked: {
    fontSize: '1.7rem',
    marginLeft: '0.5rem',
    paddingButton: '0.5rem',
  },
  doneButtonIconMarked: {
    fontSize: '1.7rem',
    marginLeft: '0.5rem',
    paddingButton: '0.5rem',
    color: theme.palette.primary.main,
  },
  textField: {
    fontSize: '1.3rem',
  },
  noticeLink: {
    color: '#0000EE',
    '&:hover': {
      color: '#000000',
      textDecoration: 'underline #000000',
    },
  },

  noticeLinkMarked: {
    color: '#000000',
    textDecoration: 'line-through #000000',
    '&:hover': {
      color: '#0000EE',
    },
  },

  tabRoot: {
    fontSize: '1.3rem',
    minHeight: 40,
    height: 40,
  },
  tabBadge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: 2,
      right: -8,
    },
  },
}))

export default styles
