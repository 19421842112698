import * as React from 'react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Chip, Fade, Link, List, ListItem, ListItemText } from '@material-ui/core'
import { THEMES_TRANSLATION } from 'utils/constants/consultConstants'
import { Facebook, Instagram, LinkedIn, Public, Twitter } from '@material-ui/icons'
import OccurrenceTimestampChip from 'ui/components/OccurrenceTimestampChip'
import showStatusMessage from '../messages'
import styles from '../styles'
import { groupItems, groupModes, itemTypes } from '../itemsGrouper'
import SocialMediaPostReviewButton from '../SocialMediaPostReviewButton'
import GroupTitle from '../GroupTitle'

const SocialMediaPostsList = ({
  postsData,
  searchTerm,
  isLoading,
  hideReviewed,
  groupMode,
  reviewButtonsVisibility,
  onUpdatePost,
  toggleReviewButtonVisible,
  sortOrder,
}) => {
  const [postGroups, setPostGroups] = useState([])

  const classes = styles()

  const socialMedias = {
    facebook: { label: 'Facebook', icon: <Facebook /> },
    linkedin: { label: 'Linkedin', icon: <LinkedIn /> },
    twitter: { label: 'Twitter', icon: <Twitter /> },
    instagram: { label: 'Instagram', icon: <Instagram /> },
    website: { label: 'Website', icon: <Public /> },
  }

  /**  */
  useEffect(() => {
    const markReviewedGroups = (groups) => {
      Object.keys(groups).forEach((key, _index) => {
        const group = groups[key]
        group.allViewed = group.items.every((notice) => !_.isEmpty(notice.snippet?.review))
      })
    }

    const filterNotices = () => {
      if (_.isEmpty(searchTerm) || _.isEmpty(postsData.items)) {
        return postsData?.items || []
      }

      const searchTermLowered = searchTerm.toLowerCase()

      return postsData.items.flatMap((n) => {
        if (
          n.title?.toLowerCase().includes(searchTermLowered) ||
          n.snippet?.description?.toLowerCase().includes(searchTermLowered) ||
          THEMES_TRANSLATION[n.snippet?.theme]?.toLowerCase().includes(searchTermLowered)
        ) {
          return n
        }
        return []
      })
    }

    const groups = groupItems(groupMode, filterNotices(), itemTypes.socialMedia, sortOrder)

    markReviewedGroups(groups)
    setPostGroups(groups)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsData, searchTerm, groupMode])

  const statusMessage = showStatusMessage({
    consultType: 'socialMedia',
    data: postsData,
    isLoading: isLoading,
    allItemsReviewed: hideReviewed && !_.isEmpty(postGroups) && _.every(postGroups, (g) => g.allViewed),
  })
  return (
    statusMessage ||
    (!_.isEmpty(postGroups) &&
      Object.entries(postGroups).map(([postDate, postGroup]) => {
        const allGroupViewed = hideReviewed && postGroup.allViewed
        return (
          !allGroupViewed && (
            <List key={`${postDate}`}>
              <div>
                <ListItem>
                  <ListItemText primary={<GroupTitle itemDate={postDate} groupMode={groupMode} />} />
                </ListItem>

                <br />
              </div>

              {_.map(postGroup.items, (p, index) => {
                const reviewed = !_.isEmpty(p.snippet?.review)
                if (hideReviewed && reviewed) {
                  return null
                }
                return (
                  <Fade key={p.link} in={!isLoading} style={{ transitionDelay: !isLoading ? '150ms' : '0ms' }}>
                    <ListItem key={p.link}>
                      <div
                        style={{
                          marginBottom: index === postGroup.items.length - 1 ? 15 : 0,
                        }}
                      >
                        <ListItemText
                          primary={(
                            <div
                              onMouseEnter={() => toggleReviewButtonVisible(p.link, true)}
                              onMouseLeave={() => toggleReviewButtonVisible(p.link, false)}
                            >
                              <Link
                                underline="always"
                                className={reviewed ? classes.noticeLinkMarked : classes.noticeLink}
                                target="blank"
                                href={p.link}
                              >
                                <span>{p.title}</span>
                              </Link>
                              <SocialMediaPostReviewButton
                                post={p}
                                reviewButtonsVisibility={reviewButtonsVisibility}
                                onUpdatePost={onUpdatePost}
                              />
                            </div>
                          )}
                          secondary={(
                            <span
                              onMouseEnter={() => toggleReviewButtonVisible(p.link, true)}
                              onMouseLeave={() => toggleReviewButtonVisible(p.link, false)}
                            >
                              {p.snippet?.description}
                            </span>
                          )}
                        />

                        <Chip
                          size="small"
                          avatar={socialMedias[p.social_media?.type]?.icon}
                          label={socialMedias[p.social_media?.type]?.label}
                        />

                        {groupMode === groupModes.publishedDate && p?.snippet?.created_at && (
                          <OccurrenceTimestampChip
                            occurenceType={groupModes.createdAt}
                            occurrenceDate={p.snippet.created_at}
                            postType="postagem"
                          />
                        )}
                        {groupMode === groupModes.createdAt && p?.post_date && (
                          <OccurrenceTimestampChip
                            occurenceType={groupModes.publishedDate}
                            occurrenceDate={p.post_date}
                            postType="postagem"
                          />
                        )}
                      </div>
                    </ListItem>
                  </Fade>
                )
              })}
            </List>
          )
        )
      }))
  )
}

export default SocialMediaPostsList
