import { Collapse, Dialog, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React, { useState } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PageviewIcon from '@material-ui/icons/Pageview'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ROUTE_CONSTANTS } from 'utils/constants/routeConstants'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import SettingsDialog from '../SettingsDialog'
import MUIIcon from '../Icon/MUIIcon'

export const MENU_PAGES = {
  consults: 'consults',
  monitorings: 'monitorings',
  peopleMonitorings: 'peopleMonitorings',
  keywordMonitorings: 'keywordMonitorings',
  peopleDatabase: 'peopleDatabase',
  settings: 'settings',
}
const MENU_PAGES_PATHS = {
  consults: ROUTE_CONSTANTS.HOME,
  monitorings: ROUTE_CONSTANTS.PEOPLE_MONITORING,
  peopleMonitorings: ROUTE_CONSTANTS.PEOPLE_MONITORING,
  keywordMonitorings: ROUTE_CONSTANTS.PEOPLE_MONITORING,
  peopleDatabase: ROUTE_CONSTANTS.PEOPLE_DATABASE,
  settings: ROUTE_CONSTANTS.SETTINGS,
}

const SidebarMenu = ({ currentPage }) => {
  const appBarMenuOpen = useSelector((state) => state.auth.appBarMenuOpen)

  const history = useHistory()
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false)
  const [settingsDialogSize, setSettingsDialogSize] = useState('lg')


  const userIsAdmin = useSelector((state) => state.auth.is_admin)

  const handleClickPageMenu = (page) => {
    history.push(MENU_PAGES_PATHS[page])
  }

  const handleClickOpenSettings = () => {
    setOpenSettingsDialog(true)
  }

  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false)
  }

  const useStyles = makeStyles((theme) => ({
    listItemText: {
      fontSize: '1.25rem',
      width: 'max-content',
    },
    listItemIconSvg: {
      fontSize: '1.7rem',
    },
    listItemIcon: {
      minWidth: 35,
    },
    listItemIconSelected: {
      minWidth: 35,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    listItemTextSelected: {
      fontSize: '1.25rem',
      width: 'max-content',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  }))

  const classes = useStyles()

  const selectIconClass = (page) => {
    if (page === MENU_PAGES.monitorings) {
      return currentPage === MENU_PAGES.peopleMonitorings || currentPage === MENU_PAGES.keywordMonitorings
        ? classes.listItemIconSelected
        : classes.listItemIcon
    }
    return page === currentPage ? classes.listItemIconSelected : classes.listItemIcon
  }

  const selectTextClass = (page) => {
    if (page === MENU_PAGES.monitorings) {
      return currentPage === MENU_PAGES.peopleMonitorings || currentPage === MENU_PAGES.keywordMonitorings
        ? { primary: classes.listItemTextSelected }
        : { primary: classes.listItemText }
    }
    return page === currentPage ? { primary: classes.listItemTextSelected } : { primary: classes.listItemText }
  }

  return !appBarMenuOpen ? null : (
    <div style={{ height: '100vh', display: 'flex', boxShadow: '1px 1px 1px #ccc' }}>
      <List style={{ backgroundColor: '#efefef', width: 'fit-content', height: '100%' }}>
        <ListItem button onClick={() => handleClickPageMenu(MENU_PAGES.consults)}>
          <ListItemIcon className={selectIconClass(MENU_PAGES.consults)}>
            <PageviewIcon className={classes.listItemIconSvg} />
          </ListItemIcon>
          <ListItemText color="primary" classes={selectTextClass(MENU_PAGES.consults)} primary="Consultas" />
        </ListItem>

        <Divider />

        <ListItem onClick={() => handleClickPageMenu(MENU_PAGES.peopleMonitorings)}>
          <Divider />
          <ListItemIcon className={selectIconClass(MENU_PAGES.monitorings)}>
            <VisibilityIcon className={classes.listItemIconSvg} />
          </ListItemIcon>
          <ListItemText primary="Monitoramentos" classes={selectTextClass(MENU_PAGES.monitorings)} />
        </ListItem>
        <Collapse in={true}>
          <Divider style={{ paddingLeft: 30 }} />
          <List component="div" disablePadding>
            <ListItem
              button
              style={{ paddingLeft: 30 }}
              onClick={() => handleClickPageMenu(MENU_PAGES.peopleMonitorings)}
            >
              <ListItemIcon className={selectIconClass(MENU_PAGES.peopleMonitorings)}>
                <MUIIcon type="peopleMonitoring" className={classes.listItemIconSvg} />
              </ListItemIcon>
              <ListItemText classes={selectTextClass(MENU_PAGES.peopleMonitorings)} primary="Pessoas" />
            </ListItem>

            <Divider style={{ marginLeft: 25 }} />

            <ListItem
              button
              disabled
              style={{ paddingLeft: 30 }}
              onClick={() => handleClickPageMenu(MENU_PAGES.keywordMonitorings)}
            >
              <ListItemIcon className={selectIconClass(MENU_PAGES.keywordMonitorings)}>
                <MUIIcon type="keywordsMonitoring" className={classes.listItemIconSvg} />
              </ListItemIcon>
              <ListItemText classes={selectTextClass(MENU_PAGES.keywordMonitorings)} primary="Palavras-chave" />
            </ListItem>
          </List>
        </Collapse>

        <Divider />

        <ListItem button onClick={() => handleClickPageMenu(MENU_PAGES.peopleDatabase)}>
          <ListItemIcon className={selectIconClass(MENU_PAGES.peopleDatabase)}>
            <MUIIcon type="peopleDatabase" className={classes.listItemIconSvg} />
          </ListItemIcon>
          <ListItemText
            color="primary"
            primary="Banco de Pessoas"
            classes={selectTextClass(MENU_PAGES.peopleDatabase)}
          />
        </ListItem>

        <Divider />

        {userIsAdmin && (
          <ListItem button onClick={handleClickOpenSettings}>
            <ListItemIcon className={selectIconClass(MENU_PAGES.settings)}>
              <MUIIcon type="settings" className={classes.listItemIconSvg} />
            </ListItemIcon>
            <ListItemText color="primary" primary="Configurações" classes={selectTextClass(MENU_PAGES.settings)} />
          </ListItem>
        )}
      </List>

      <Dialog fullWidth maxWidth={settingsDialogSize} open={openSettingsDialog}>
        <SettingsDialog handleCloseDialog={handleCloseSettingsDialog} changeDialogSize={(size) => setSettingsDialogSize(size)} />
      </Dialog>
    </div>
  )
}

SidebarMenu.propTypes = {
  currentPage: PropTypes.oneOf(Object.values(MENU_PAGES)).isRequired,
}

export default SidebarMenu
