import {
  FAIL_LOGIN,
  LOGIN,
  LOGOUT,
  SUCCESS_LOGIN,
  SUCCESS_REGISTER,
  RECOVER_PASSWORD,
  CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  ACCESS_APP_BAR_MENU,
  RETRIEVE_USER,

} from './actions'

const initialState = {
  access_token: null,
  api_key: null,
  api_key_id: null,
  email_verified: null,
  enabled: false,
  expires_in: 0,
  given_name: null,
  groups: [],
  name: null,
  phone_number: null,
  phone_number_verified: null,
  plans: [],
  refresh_token: null,
  status: null,
  sub: null,
  username: null,
  load: false,
  fail: false,
  changePasswordSession: null,
  appBarMenuOpen: false,
  email: null,
  role: null,
  is_admin: null,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_USER:
      return {
        ...state,
        email: action.payload.email,
        role: action.payload.role,
        is_admin: ['admin', 'root'].includes(action.payload.role),
      }
    case LOGIN:
      return {
        ...state,
        load: true,
        fail: false,
      }

    case SUCCESS_LOGIN:
      return {
        ...state,
        ...action.payload,
        load: false,
        fail: false,
      }

    case FAIL_LOGIN:
      return {
        ...state,
        load: false,
        fail: true,
      }

    case LOGOUT:
      return {
        ...state,
        ...initialState,
      }

    case SUCCESS_REGISTER:
      return {
        ...state,
        destination: action.payload.destination,
        sub: action.payload.sub,
      }

    case RECOVER_PASSWORD:
      return {
        ...state,
        destination: action.payload,
      }

    case CHANGE_PASSWORD:
      return {
        ...state,
        ...action.payload,
      }
    case FAIL_CHANGE_PASSWORD:
      return {
        ...initialState,
      }
    case ACCESS_APP_BAR_MENU:
        return {
          ...state,
          appBarMenuOpen: !state.appBarMenuOpen,
        }
    default:
      return state
  }
}

export default authReducer
