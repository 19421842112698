import moment from "moment"

export const WEEKDAYS = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
export const MONTHS = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const BR_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const BR_DATE_FORMAT = 'DD-MM-YYYY'
export const BR_DATE_FORMAT_SLASHED = 'DD/MM/YYYY'

export const getDate = (date = new Date()) => {
  date.setHours(0, 0, 0, 0)
  return date
}

export const parseDate = (dateStr, format = null) => {
  if (!dateStr) {
    return null
  }
  const m = moment(dateStr, format, true)
  return m.isValid() ? m.toDate() : null
}

export const dateToStr = (date, format = 'YYYY-MM-DD') => {
  return date ? moment(date, true).format(format) : null
}
