import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import 'date-fns'
import _ from 'lodash'
import { getPlan, postConfigurePlan } from 'services/consultApi'
import { toast } from 'react-toastify'
import reportError from 'utils/errorReporter'
import { useSelector } from 'react-redux'
import { defaultPlanKeywords, planCategoryNames } from 'utils/constants/plan'
import ChipInput from 'ui/components/ChipInput'
import KeywordsTable from '../KeywordsTable'
import KeyswordDisplayModeButton from '../KeywordsDisplayModeButton'

const NegativeMediaKeywordsPlan = ({ handleGoBack }) => {
  const undefinedCategory = 'Escolha a categoria...'
  const userId = useSelector((state) => state.auth.sub)
  const apiKey = useSelector((state) => state.auth.api_key)

  const [useDefaultPlan, setUseDefaultPlan] = useState(true)

  const [keywordsData, setKeywordsData] = useState(defaultPlanKeywords)

  const [newTerm, setNewTerm] = useState('')

  const [tableMode, setTableMode] = useState(true)

  const [categorySelector, setCategorySelector] = useState(undefinedCategory)

  const handleCheckDefaultPlan = () => {
    setUseDefaultPlan(!useDefaultPlan)
  }

  useEffect(() => {
    const requestSocialMediaPlan = async () => {
      let res = null
      try {
        res = await getPlan(apiKey, userId)
      } catch (err) {
        reportError(err)
        return null
      }

      if (_.isEmpty(res.data?.keywords)) {
        setUseDefaultPlan(true)
        return null
      }

      setUseDefaultPlan(res.data.use_default_plan)
      setKeywordsData(res.data.keywords)
      return null
    }
    requestSocialMediaPlan()
  }, [apiKey, userId])

  const postCreatePlan = async () => {
    try {
      await postConfigurePlan(apiKey, userId, keywordsData, useDefaultPlan)
    } catch (err) {
      reportError(err, 'Erro ao configurar plano de mídias sociais')
      return false
    }

    toast.success('Configurações salvas')
    return true
  }

  const handleClickSave = async () => {
    if (!useDefaultPlan && Object.values(keywordsData).every((tf) => _.isEmpty(tf))) {
      toast.error('Adicione pelo menos uma palavra-chave')
      return
    }
    if (await postCreatePlan()) {
      handleGoBack()
    }
  }

  const handleChangeTextField = (categoryName, values) => {
    const newData = _.cloneDeep(keywordsData)
    newData[categoryName] = values

    setKeywordsData(newData)
  }
  const formatKeywordTerms = () => {
    const terms = []
    _.each(keywordsData, (words, category) => {
      _.each(words, (w) => terms.push({ value: w, key: `${category}-${w}`, category: planCategoryNames[category] }))
    })
    return terms
  }

  const removeTerm = (item) => {
    const category = _.findKey(planCategoryNames, (value) => value === item?.category)
    if (!category) {
      return
    }
    const newTerms = _.filter(keywordsData[category], (tf) => tf.toLowerCase() !== item.value?.toLowerCase())

    setKeywordsData({ ...keywordsData, [category]: newTerms })

  }

  const addNewTerm = () => {
    const category = _.findKey(planCategoryNames, (value) => value === categorySelector)
    if (!category) {
      return
    }
    if (!newTerm || _.some(keywordsData[category], (tf) => tf.toLowerCase() === newTerm.toLowerCase())) {
      return
    }

    const newTerms = [...keywordsData[category]]

    newTerms.unshift(newTerm)

    setKeywordsData({ ...keywordsData, [category]: newTerms })

    setNewTerm('')
  }

  const handleChangeCategory = (event) => {
    setCategorySelector(event.target.value)
  }
  const showCategorySelectMenuItem = (title) => (
    <MenuItem key={title} value={title}>
      {title}
    </MenuItem>
  )
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>Termos para busca em páginas de notícia</DialogContentText>
        <FormControl style={{ marginTop: 5 }} component="fieldset">
          <FormControlLabel
            value="end"
            control={(
              <Checkbox
                value={useDefaultPlan || false}
                checked={useDefaultPlan || false}
                color="primary"
                onClick={handleCheckDefaultPlan}
              />
            )}
            label="Usar a configuração de palavras-chave padrão"
          />
        </FormControl>

        <KeyswordDisplayModeButton tableMode={tableMode} onClick={() => setTableMode(!tableMode)} />

        {!tableMode &&
          _.map(planCategoryNames, (cnValue, cnKey) => (
            <div key={cnValue} style={{ marginTop: 5 }}>
              <ChipInput
                fullWidth
                disabled={useDefaultPlan}
                label={cnValue}
                value={keywordsData[cnKey]}
                onChange={(e, values) => handleChangeTextField(cnKey, values)}
              />
            </div>
          ))}

        {tableMode && (
          <span>
            <div style={{ display: 'flex', gap: 20,  alignItems: 'baseline' }}>
              <TextField
                disabled={useDefaultPlan}
                fullWidth
                label="Digite um termo desabonador"
                value={newTerm}
                InputProps={{ style: { fontSize: '1.2rem' } }}
                style={{ fontSize: '1.2rem' }}
                onChange={(e) => setNewTerm(e.target.value)}
              />
              <Select
                value={categorySelector}
                label='Categoria'
                onChange={handleChangeCategory}
                style={{ fontSize: '1.2rem' }}
              >
                {showCategorySelectMenuItem(undefinedCategory)}
                {_.map(planCategoryNames, c => showCategorySelectMenuItem(c))}
              </Select>

              <Button disabled={useDefaultPlan || categorySelector === undefinedCategory} color="primary" onClick={addNewTerm}>
                Adicionar
              </Button>
            </div>

            <KeywordsTable
              disabled={useDefaultPlan}
              filter={newTerm}
              categoryFilter={categorySelector !== undefinedCategory ? categorySelector : null}
              terms={formatKeywordTerms(keywordsData)}
              onRemove={removeTerm}
            />
          </span>
        )}
        <br />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <div style={{ marginLeft: 10 }}>
          <Button disabled={useDefaultPlan} color="primary" onClick={() => setKeywordsData(defaultPlanKeywords)}>
            Redefinir Termos
          </Button>
        </div>

        <div style={{ marginRight: 10 }}>
          <Button onClick={handleClickSave} color="primary">
            Salvar
          </Button>
        </div>
      </DialogActions>
    </React.Fragment>
  )
}

export default NegativeMediaKeywordsPlan
